<div class="carousel owl-carousel">
  <div class="item">
    <div
      class="banner-search"
      style="background-image: url(./assets/img/servicios/banner-2.jpg)"
    >
      <h2 class="text-banner style-2 text-white">
        <span>Ya conoces nuestros servicios</span>
        <span>especializados de limpieza</span>
        <a
          href="/servicios/limpieza"
          class="d-inline-flex justify-content-center align-items-center text-white btn-blue text-bold text-color-white text-center py-3 px-3 h5 mt-3"
          role="button"
          aria-expanded="false"
          aria-controls="categories"
        >
          Solicitar servicios
        </a>
      </h2>
    </div>
  </div>
  <div class="item">
    <div class="banner-search">
      <h2 class="text-white style-2 text-banner">
        <span> Te ofrecemos una solución completa </span>
        <span> con nuestro catálogo de servicios </span>
        <a
          href="{{
            isLoggedIn
              ? '/usuario/perfil?tab=servicios&action=register'
              : '/auth/register?action=servicios'
          }}"
          class="d-inline-flex justify-content-center align-items-center text-white btn-yellow text-bold text-color-white text-center py-3 px-3 h5 mt-3"
          role="button"
          aria-expanded="false"
          aria-controls="categories"
        >
          Comienza a publicar tu servicio
        </a>
      </h2>
    </div>
  </div>
</div>

<div class="container-search mt-3">
  <div class="d-flex justify-content-center">
    <h2 class="text-bold populares">
      Encuentra servicios en diferentes categorías como:
    </h2>
  </div>
  <div class="container" *ngIf="services">
    <div class="carouselservices owl-carousel">
      <div class="item" *ngFor="let p of services">
        <a href="/servicios/{{ p.slug }}" class="text-blue">
          <div class="box">
            <img
              src="{{
                p.image
                  ? p.image
                  : 'https://cdn.vivienda.com/images/icons/reg-serv.png'
              }}"
              alt="{{ p.name }}"
              title="{{ p.name }}"
              class="rounded-circle aspect-ratio-1"
            />
            <h6
              class="mt-2 p-1 text-truncate text-capitalize text-center"
              title="{{ p.name }}"
            >
              {{ p.name }}
            </h6>
          </div>
        </a>
      </div>
    </div>
    <!-- <div class="justify-content-center align-items-center" *ngIf="services && services.next_page_url">
          <a (click)="loadMore()"
            class=" d-flex justify-content-center align-items-center text-white  btn-yellow text-bold  text-color-white text-center py-3 px-5"
            role="button" aria-expanded="false" aria-controls="categories">
            Ver Más
            <i class="ml-1 fa fa-chevron-down"></i>
          </a>
        </div> -->
  </div>

    <div class="d-flex justify-content-center mt-3">
        <h2 class="text-black titulo-proyecto">
            Resultados de acuerdo a tu búsqueda
        </h2>
    </div>

    <app-service-index class="ocultar-title"></app-service-index>

  <!-- <div class="container">
    <div class="row mb-5 solutions">
      <div class="col-sm-1 mr-5 p-0"></div>
      <div
        class="col-12 col-md-5 d-flex justify-content-center flex-column p-0"
      >
        <h3 class="text-bold title-benefits title-2">Brindamos soluciones</h3>
        <p class="text-benefits text-thin">
          Todos los servicios que te brindamos es con el fin de brindarte un
          apoyo con un amplio catálogo con expertos en el tema.
        </p>
      </div>
      <div class="col-12 col-md-4 p-0">
        <img
          src="https://cdn.vivienda.com/images/services/beneficios.jpg"
          alt="Beneficios"
          class="img-fluid"
          height="350"
        />
      </div>
    </div>
  </div> -->

  <!-- <div class="do_service text-white position-relative">
    <div class="offer-service">
      <h2 class="text-mega-bold">¿Quieres prestar</h2>
      <h4 class="text-thin mt-n2">Tus servicios?</h4>
      <p class="mt-2 mb-4">
        En vivienda.com estamos en búsqueda de nuevos servicios. Haz parte de
        nuestro servicios y consigue nuevos clientes fácil, rapido y gratis
      </p>
      <a
        href="{{
          isLoggedIn
            ? '/usuario/perfil?tab=servicios&action=register'
            : '/auth/register?action=servicios'
        }}"
        class="d-inline-flex justify-content-center align-items-center text-white btn-yellow text-bold text-color-white text-center py-3 px-3"
        role="button"
        aria-expanded="false"
        aria-controls="categories"
      >
        Comienza ahora
      </a>
    </div>
  </div> -->

  <div class="do_service text-white position-relative">
    <div class="offer-service">
      <h4 class="text-thin text-center mt-n2">¿Necesitas una cotización a la medida? </h4>
      <a
        href="{{
          isLoggedIn
            ? '/usuario/perfil?tab=servicios&action=register'
            : '/auth/register?action=servicios'
        }}"
        class="d-inline-flex justify-content-center align-items-center text-white btn-red btn-rounded text-bold text-color-white text-center py-3 px-3"
        role="button"
        aria-expanded="false"
        aria-controls="categories"
      >
        Comienza ahora
      </a>
    </div>
  </div>

  <div class="container mt-3">
    <h2 class="text-bold mb-2 populares text-center">
      Conoce los servicios más contratados:
    </h2>

    <div class="carouselranked owl-carousel carousel-autoheight">
      <div
        class="card shadow-none rounded-3 overflow-hidden"
        *ngFor="let p of servicesRanked.data"
      >
        <a href="/servicios/{{ p.slug }}" class="text-dark">
          <img
            src="{{
              p.image
                ? p.image
                : 'https://cdn.vivienda.com/images/icons/reg-serv.png'
            }}"
            alt="{{ p.name }}"
            title="{{ p.name }}"
            class="card-img-top aspect-ratio-2"
          />
        </a>
        <div class="card-body p-2">
          <div class="row justify-content-between align-items-center">
            <div class="col-auto">
              <div class="text-blue">
                {{ p.quantity }}
              </div>
            </div>
            <div class="col-auto">
              <div class="d-flex">
                <ng-container *ngFor="let serv of p.subservices; index as i">
                  <div
                    *ngIf="i < 2"
                    class="btn btn-sm btn-blue btn-rounded ml-2"
                  >
                    {{ serv.name }}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <h5
            class="text-truncate text-capitalize text-red my-1"
            title="{{ p.name }}"
          >
            {{ p.name }}
          </h5>
          <p class="text-truncate-3 description-serv mb-0 lh-1">
            {{ p.description || "Servicios para inmuebles en vivienda.com." }}
          </p>

          <ng-container *ngFor="let prof of p.profiles; index as i">
            <a href="/perfil/{{ prof.slug }}" class="text-dark">
              <div *ngIf="i < 1" class="row align-items-center">
                <div class="col-2 pr-0">
                  <img
                    src="{{
                      prof.image ||
                        'https://cdn.vivienda.com/images/icons/profile.png'
                    }}"
                    alt=""
                    class="img-fluid rounded-circle aspect-ratio-1"
                  />
                </div>
                <div class="col-6 font-weight-bold small lh-1">
                  <div class="capitalize-first">
                    {{ prof.name }}
                  </div>
                </div>
                <div class="col-4 text-right small">Ver perfil</div>
              </div>
            </a>
          </ng-container>
          <a class="btn btn-red w-100 btn-rounded mt-2" href="/servicios/{{ p.slug }}">
            Ver más
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-4  pb-2">
    <h2 class="text-bold populares mt-2 text-center">
      Descubre nuestras alianzas:
    </h2>

    <div class="container-aliados">
      <div class="row">
        <div class="col-6 col-lg-2" *ngFor="aliado of aliados; index as i">
          <div class="aliado card d-flex align-items-center justify-content-center aspect-ratio-1 shadow rounded-3">
            <img src="./assets/img/servicios/servicios-aliados-logo-{{i}}.png" alt="" class="img-fluid">
          </div>

        </div>
      </div>
    </div>

  </div>

  <div class="container mt-4">
    <h2 class="text-bold mt-3 populares text-center">
      Conoce los servicios que ofrecen nuestras empresas aliadas:
    </h2>

    <div class="carouselranked owl-carousel carousel-autoheight">
      <div
        class="card shadow-none rounded-3 overflow-hidden"
        *ngFor="let p of servicesRanked.data"
      >
        <a href="/servicios/{{ p.slug }}" class="text-dark">
          <img
            src="{{
              p.image
                ? p.image
                : 'https://cdn.vivienda.com/images/icons/reg-serv.png'
            }}"
            alt="{{ p.name }}"
            title="{{ p.name }}"
            class="card-img-top aspect-ratio-2"
          />
        </a>
        <div class="card-body p-2">
          <div class="row justify-content-between align-items-center">
            <div class="col-auto">
              <div class="text-blue">
                {{ p.quantity }}
              </div>
            </div>
            <div class="col-auto">
              <div class="d-flex">
                <ng-container *ngFor="let serv of p.subservices; index as i">
                  <div
                    *ngIf="i < 2"
                    class="btn btn-sm btn-blue btn-rounded ml-2"
                  >
                    {{ serv.name }}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <h5
            class="text-truncate text-capitalize text-red my-1"
            title="{{ p.name }}"
          >
            {{ p.name }}
          </h5>
          <p class="text-truncate-3 description-serv mb-0">
            {{ p.description || "Servicios para inmuebles en vivienda.com." }}
          </p>

          <ng-container *ngFor="let prof of p.profiles; index as i">
            <a href="/perfil/{{ prof.slug }}" class="text-dark">
              <div *ngIf="i < 1" class="row align-items-center">
                <div class="col-2 pr-0">
                  <img
                    src="{{
                      prof.image ||
                        'https://cdn.vivienda.com/images/icons/profile.png'
                    }}"
                    alt=""
                    class="img-fluid rounded-circle aspect-ratio-1"
                  />
                </div>
                <div class="col-6 font-weight-bold small lh-1">
                  <div class="capitalize-first">
                    {{ prof.name }}
                  </div>
                </div>
                <div class="col-4 text-right small">Ver perfil</div>
              </div>
            </a>
          </ng-container>
          <a class="btn btn-red w-100 btn-rounded mt-2" href="/servicios/{{ p.slug }}">
            Ver más
          </a>
        </div>
      </div>
    </div>
  </div>


  <div class="container mt-4 pt-2">
    <h2 class="text-bold populares text-center">
      ¡Ellos son nuestros aliados del mes!
    </h2>

    <div class="row">
      <div class="col-lg-4" *ngFor="let p of servicesRanked.data; index as j">
        <ng-container *ngIf="j < 3">
          <ng-container *ngFor="let prof of p.profiles; index as i">
            <div class="card shadow p-2" *ngIf="i < 1">
            <a href="/perfil/{{ prof.slug }}" class="text-dark"  title="{{prof.name}}">
              <div class="row align-items-center">
                <div class="col-3 pr-0">
                  <img
                    src="{{
                      prof.image ||
                        'https://cdn.vivienda.com/images/icons/profile.png'
                    }}"
                    alt=""
                    class="img-fluid rounded-circle aspect-ratio-1 w-100"
                  />
                </div>
                <div class="col-9 small lh-1">
                  <div class="d-flex justify-content-between font-weight-bold">
                    <div class="capitalize-first text-truncate">
                      {{ prof.name }}
                    </div>
                    <div class="col-4 text-right small">Ver perfil</div>
                  </div>

                  <div class="rank d-flex text-blue my-2">
                    <i class="fas fa-star"></i>
                    <div class="ml-2">
                      5.0
                    </div>
                  </div>

                  <div class="text-truncate-2 mh-2">
                    Servicios de lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </div>
                </div>
              </div>
            </a>
          </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="container mt-4 pt-2">
    <h2 class="text-bold mt-3 populares text-center">
      ¡Entérate de todas nuestras noticias!
    </h2>

    <div class="font-italic text-center">
      Under construction...
    </div>
  </div>



  
</div>
