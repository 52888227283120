import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { RecaptchaComponent } from 'ng-recaptcha';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HomeService } from 'src/app/services/home/home.service';
import { AuthStateService } from 'src/app/services/auth/auth-state.service';
import { TokenService } from 'src/app/services/auth/token.service';
import * as Cookies from 'js-cookie';
import { isPlatformBrowser } from '@angular/common';
import { ProfileService } from 'src/app/services/user/profile.service';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';

declare const $: any;

@Component({
  selector: 'app-services-design',
  templateUrl: './services-design.component.html',
  styleUrls: ['./services-design.component.css']
})
export class ServicesDesignComponent implements OnInit {
  registerForm: FormGroup;
  name;
  surname;
  email;
  cellphone;
  password;
  terms;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Colombia];
  phoneForm = new FormGroup({
    phone: new FormControl(document.querySelector('#cellphone'), [
      Validators.required,
    ]),
  });
  captcha;
  browser = false;
  @ViewChild('captchaRef') public captchaRef: RecaptchaComponent;

  action: string = null;
  redirectTo: string = null;

  constructor(
    public fb: FormBuilder,
    protected authService: AuthService,
    protected authState: AuthStateService,
    protected tokenService: TokenService,
    private homeService: HomeService,
    protected profileService: ProfileService,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.registerForm = this.fb.group({
      name: [''],
      surname: [''],
      email: [''],
      cellphone: [''],
      password: [''],
      terms: [''],
    });
    this.browser = isPlatformBrowser(platformId);
    this.action = new URLSearchParams(window.location.search).get('action') || null;
  }

  ngOnInit(): void {
    if (this.browser) {
      try {
        var userParams = JSON.parse(localStorage.getItem('userParams'));
        if (
          userParams &&
          userParams.name &&
          userParams.surname &&
          userParams.email &&
          userParams.cellphone
        ) {
          this.name = userParams.name;
          this.surname = userParams.surname;
          this.email = userParams.email;
          this.cellphone = userParams.cellphone;
          this.terms = true;
        }

      } catch (e) {}
      localStorage.removeItem('userParams');
    }
    $('a.ancla').on('click', function(e) {
        e.preventDefault();
        var offset = 70;
        var target = this.hash;
        if ($(this).data('offset') != undefined) offset = $(this).data('offset');
        $('html, body').stop().animate({
            'scrollTop': $(target).offset().top - offset
        }, 100, 'swing', function() {
            // window.location.hash = target;
        });
    });
  }
  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
  }

  register(f) {
    if (f.form.status == 'VALID' && this.captcha !== null) {
      Swal.fire({
        title: 'Espera un momento...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onBeforeOpen: () => Swal.showLoading(),
      });
      f.value['g-recaptcha-response'] = this.captcha;
      this.authService.register(f.value).subscribe(
        (response: any) => {
          if (response.access_token != '' || !('error' in response)) {
            this.tokenService.handleData(response.access_token);
          }

          const user = response.user;
          let currency = user.country
            ? user.country.currencies
              ? user.country.currencies[0].iso
              : 'COP'
            : 'COP';
          localStorage.setItem('currency', currency);
          localStorage.setItem('user', JSON.stringify(user));
          this.authState.setAuthState(true);
          const verified = response.user.email_verified_at == null;
          Cookies.set('verified', !verified);
          var text = 'A continuación completa información adicional sobre tu perfil';
          Swal.fire({
            title: '¡Registro exitoso!',
            text: text,
            icon: 'success',
          }).then(() => {
              window.location.href = '/usuario/registro-servicio';
          });
        },
        (error) => {
          const emailTaken = error.error.data?.email;
          var parserError = this.homeService.parseErrorResponse(error);
          if (emailTaken != undefined && ['The email has already been taken.', 'Este email ya se encuentra registrado'].includes(emailTaken[0])) {
            Swal.fire({
              title: 'Ya existe una cuenta con el correo digitado',
              text: '¿Quieres iniciar sesión?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              confirmButtonText: 'Iniciar sesión',
              reverseButtons: true
            }).then((response) => {
              if (response.isConfirmed) {
                sessionStorage.setItem('email', f.value['email']);
                if (this.action != null ) {
                  window.location.href =  `/auth/login?action=${this.action}`;
                } else {
                  window.location.href =  '/auth/login';
                }
              }
              this.captchaRef.reset();
            });
          } else {
            Swal.fire({
              title: error.error.message,
              html: parserError.message,
              icon: parserError.icon,
            }).then(() => {
              this.captchaRef.reset();
            });
          }
        }
      );
    }
  }

}
