<div class="container pt-4">
  <div class="row">
    <div class="col-12">
      <h3>TÉRMINOS Y CONDICIONES VIVIENDA.COM</h3>

      <p class="px-3 text-justify">A continuación, se reflejan los Términos y Condiciones Generales de los
        servicios ofrecidos por VIVIENDA.COM S.A.S. en el dominio web
        www.vivienda.com (en adelante el sitio web).</p>

      <h3>1. PROPIEDAD DE ESTE SITIO</h3>
      <p class="px-3 text-justify">El sitio web es propiedad de VIVIENDA.COM S.A.S., sociedad legalmente
        constituida en Colombia con NIT. 901.491.532-0.</p>

      <h3>2. USUARIO Y POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES.</h3>
      <p class="px-3 text-justify">El acceso y/o uso de este sitio web le atribuye la condición de Usuario
        desde dicho acceso y/o uso, condición que acepta. VIVIENDA.COM S.A.S.,
        cuenta con una Política de tratamiento y Protección de Datos Personales
        las cuales hacen parte integral de estos Términos y Condiciones de Uso. En
        cumplimiento de lo establecido en la Ley 1581 de 2012 (Ley de Protección
        de Datos Personales), los datos que el Usuario ingrese en el portal, serán
        parte de una Base de Datos cuyo responsable y encargado será VIVIENDA.COM
        S.A.S., y que serán usados de acuerdo a las autorizaciones dadas. Todos
        los Usuarios deberán autorizar expresamente a VIVIENDA.COM S.A.S., con la
        aceptación de estos Términos y Condiciones, para que les sean remitidos
        correos electrónicos con información comercial y promocional relacionada
        con los productos ofrecidos a través del Sitio web, así como las demás que
        se mencionan en la Política de Tratamiento de Datos Personales. En
        cualquier momento, los Usuarios podrán solicitar a VIVIENDA.COM S.A.S.,
        que sus correos electrónicos dejen de ser tenidos en cuenta para efectos
        de recibir la mencionada información comercial.</p>

      <h3>3. PROPIEDAD INTELECTUAL E INDUSTRIAL:</h3>
      <p class="px-3 text-justify">VIVIENDA.COM S.A.S., es titular de todo todos los derechos de propiedad
        intelectual e industrial de www.vivienda.com así como de los elementos
        contenidos en el sitio web (de carácter enunciativo, imágenes, sonidos,
        audios, vídeos, software o textos; marcas o logotipos, combinaciones de
        colores, estructuras y diseños, selección de materiales usados, programas
        de ordenador necesarios para su funcionamiento, acceso y uso, etc.), o
        bien de sus licenciantes. Y por lo tanto el sitio web se encuentra
        protegido por las normas referente a los derechos de autor. Quedan
        expresamente prohibidas la reproducción, la distribución y la comunicación
        pública, incluida su modalidad de puesta a disposición y/o explotación, de
        la totalidad o parte de los contenidos en la página web, con fines
        comerciales, en cualquier soporte y por cualquier medio técnico, sin la
        autorización expresa de VIVIENDA.COM S.A.S El uso no autorizado de los
        materiales que se encuentren en el Sitio Web puede representar una
        infracción de las leyes de derechos de autor, de marcas registradas y de
        leyes de otro tipo. En caso que un Usuario baje los materiales del Sitio
        Web para uso personal o no comercial, éste ha de retener todos los avisos
        de derechos de autor, marcas registradas o de tipo similar que se
        encuentren en los materiales originales o en las copias de los materiales.
        No se permite modificar ni reproducir los Contenidos del Sitio Web, ni
        mostrarlos en público, usarlos o distribuirlos para fines públicos o
        comerciales. Cuando Usted inserta o publica cualquier información o
        contenido en la Plataforma, Usted declara y garantiza al portal que posee
        todos los derechos, incluyendo los derechos de propiedad intelectual,
        sobre toda la información y contenido, y que está autorizado para insertar
        o publicar dicha información y contenido, y otorga a la plataforma una
        licencia de uso no exclusiva, sublicenciable, gratuita, transferible,
        irrevocable, a nivel mundial, libre de regalías, por el término máximo de
        protección en cada país del mundo, para realizar la reproducción,
        comunicación pública, puesta a disposición, edición, modificación,
        traducción, almacenamiento digital, almacenamiento en forma electrónica e
        inclusión en bases de datos. Así mismo, VIVIENDA.COM S.A.S está autorizado
        para utilizar la información por Usted suministrada para efectos
        estadísticos y de mercadeo. Cualquier uso no autorizado constituirá una
        violación a los presentes Términos Generales, y a las normas vigentes
        nacionales e internacionales sobre Propiedad Intelectual e Industrial,
        generando a favor de VIVIENDA.COM S.A.S o del titular de los derechos de
        propiedad intelectual o industrial el derecho a solicitar la indemnización
        de los perjuicios causados. En todo caso, Usted deberá mantener indemne a
        VIVIENDA.COM S.A.S por cualquier infracción o perjuicio causado como
        consecuencia de la violación de la Propiedad Intelectual o Industrial de
        otro Usuario o de un tercero.</p>
      <p class="px-3 text-justify">protección en cada país del mundo, para realizar la reproducción,
        comunicación pública, puesta a disposición, edición, modificación,
        traducción, almacenamiento digital, almacenamiento en forma electrónica e
        inclusión en bases de datos. Así mismo, VIVIENDA.COM S.A.S está autorizado
        para utilizar la información por Usted suministrada para efectos
        estadísticos y de mercadeo. Cualquier uso no autorizado constituirá una
        violación a los presentes Términos Generales, y a las normas vigentes
        nacionales e internacionales sobre Propiedad Intelectual e Industrial,
        generando a favor de VIVIENDA.COM S.A.S o del titular de los derechos de
        propiedad intelectual o industrial el derecho a solicitar la indemnización
        de los perjuicios causados. En todo caso, Usted deberá mantener indemne a
        VIVIENDA.COM S.A.S por cualquier infracción o perjuicio causado como
        consecuencia de la violación de la Propiedad Intelectual o Industrial de
        otro Usuario o de un tercero.
        protección en cada país del mundo, para realizar la reproducción,
        comunicación pública, puesta a disposición, edición, modificación,
        traducción, almacenamiento digital, almacenamiento en forma electrónica e
        inclusión en bases de datos. Así mismo, VIVIENDA.COM S.A.S está autorizado
        para utilizar la información por Usted suministrada para efectos
        estadísticos y de mercadeo. Cualquier uso no autorizado constituirá una
        violación a los presentes Términos Generales, y a las normas vigentes
        nacionales e internacionales sobre Propiedad Intelectual e Industrial,
        generando a favor de VIVIENDA.COM S.A.S o del titular de los derechos de
        propiedad intelectual o industrial el derecho a solicitar la indemnización
        de los perjuicios causados. En todo caso, Usted deberá mantener indemne a
        VIVIENDA.COM S.A.S por cualquier infracción o perjuicio causado como
        consecuencia de la violación de la Propiedad Intelectual o Industrial de
        otro Usuario o de un tercero.</p>

      <h3>4. ACEPTACIÓN DE ESTOS TÉRMINOS Y CONDICIONES DE USO</h3>

      <p class="px-3 text-justify">El Usuario debe leer siempre estos Términos y Condiciones de Uso antes de
        utilizar este Sitio Web‎, el uso que haga del Sitio Web supondrá que ha
        leído y acepta los presentes Términos y Condiciones de Uso. Si no está de
        acuerdo con estos Términos y Condiciones de Uso no utilice este Sitio Web.</p>

      <h3>5. LINKS HACIA OTROS SITIOS</h3>
      <p class="px-3 text-justify">El Sitio Web cuenta con links hacia otros Sitios Web de Terceros, los
        cuales son provistos para que el Usuario pueda ampliar la información
        sobre determinado tema. En la medida que estos Sitios Web de Terceros no
        se encuentran bajo el control de VIVIENDA.COM S.A.S, no nos hacemos
        responsables de su contenido. Es responsabilidad del Usuario aplicar su
        juicio para evaluar la interacción con estos Sitios Web de Terceros.</p>

      <h3>6. OBJETO DE LA PLATAFORMA WEB</h3>
      <p class="px-3 text-justify">
        El portal www.vivienda.com ha sido creado para que Usted tenga la
        posibilidad de buscar y publicar inmuebles, de conocer servicios
        relacionados con una transacción inmobiliaria y de interactuar con
        diferentes Usuarios, alrededor del sector inmobiliario; VIVIENDA.COM S.A.S
        podrá presentarse como portal web, aplicación móvil u otro tipo de
        aplicación para la prestación de los servicios (todas en adelante la
        “Plataforma”) La Plataforma pretende brindar al Usuario un espacio digital
        de información de servicios inmobiliarios y relacionados, el cual sirva de
        acercamiento entre un Usuario Registrado Oferente que ofrezca bienes
        inmuebles para arriendo o venta, ya sea en calidad de propietario o de
        intermediario o corredor, (en adelante el “Usuario Registrado Oferente”)
        mediante la publicación de un aviso en la Plataforma y un Usuario y/o
        Usuario Registrado, que busque comprar o tomar en arriendo bienes
        inmuebles (en adelante el “Usuario comprador o arrendatario”). El Usuario
        Registrado Oferente es una tercera persona ajena a la plataforma.
        VIVIENDA.COM S.A.S no es propietaria ni agente inmobiliaria, ni vende,
        arrienda, alquila, revende, subarrienda, realquila, gestiona y/o controla
        ninguna propiedad de los bienes inmuebles que aparecen en la Plataforma.</p>
      <h3>7. LIMITACIÓN DE RESPONSABILIDAD</h3>
      <p class="px-3 text-justify">El Usuario acepta y reconoce que VIVIENDA.COM S.A.S. no se hace
        responsable por los riesgos a los que se encuentra expuesta su
        infraestructura tecnológica y el Sitio Web. Esta exención de
        responsabilidad se extiende entre otras materias, sin limitarse a ellas, a
        los riesgos tecnológicos derivados de errores lógicos en los aplicativos,
        paradojas derivadas de su integración, huecos, gusanos o virus, sabotajes
        en los soportes lógicos, bases de datos, y otros delitos informáticos.
        Como Usuario al hacer uso del sitio web, acepta de manera irrevocable y
        exonera a VIVIENDA.COM S.A.S de toda responsabilidad pecuniaria derivada
        de estos hechos. Adicionalmente con el uso de la plataforma, Usted
        reconoce que VIVIENDA.COM S.A.S solo proporciona el servicio de facilitar
        un espacio de comercio para que un Usuario Registrado Oferente y un
        Usuario comprador o arrendatario se contacten y puedan adelantar negocios
        jurídicos, por lo que las negociaciones que surjan entre estos Usuarios
        solo tendrán efectos entre los mismos y no afectarán ni derivarán en
        responsabilidad alguna por parte de VIVIENDA.COM S.A.S. Usted reconoce y
        acepta que VIVIENDA.COM S.A.S no tiene ningún control ni la obligación de
        controlar la conducta o acciones de los Usuarios dentro o fuera de la
        Plataforma, o sus publicaciones o anuncios, por lo que asume ninguna
        responsabilidad por la conducta, acciones, publicaciones o anuncios de los
        Usuarios. No obstante, VIVIENDA.COM S.A.S se reserva la facultad de
        investigar las conductas que sean contrarias a los Términos y condiciones,
        y a tomar las acciones necesarias. Por razones técnicas, VIVIENDA.COM
        S.A.S no garantiza la permanente disponibilidad, acceso y funcionamiento
        al 100% de la Plataforma, por lo que Usted acepta y reconoce que esta
        plataforma podría presentar algunas fallas y Usted exime de cualquier
        responsabilidad a VIVIENDA.COM S.A.S por cualquier falla en el
        funcionamiento, acceso o disponibilidad de la Plataforma. Así mismo,
        VIVIENDA.COM S.A.S tendrá la plena libertad y autoridad para modificar,
        adaptar o cambiar el contenido y funcionalidades, o inhabilitar la
        Plataforma, en cualquier momento, sin previo aviso.</p>
      <h3>8. USOS PERMITIDOS Y PROHIBIDOS DE LA PLATAFORMA</h3>
      <p class="px-3 text-justify">La plataforma solo podrá ser utilizado para propósitos que se adecuen
        dentro del marco de las leyes de la República de Colombia, así como de los
        instrumentos internacionales que protejan derechos de propiedad
        intelectual e industrial. El usuario / visitante será responsable de forma
        exclusiva y principal, por los textos y/o imágenes que este publique en la
        plataforma, al igual que por las consecuencias del orden legal por estas.
        VIVIENDA.COM S.A.S prohíbe el uso de la plataforma en cualquiera de las
        siguientes formas: • Incluir en el sitio web cualquier información falsa,
        errónea, inexacta o que de cualquier otra manera no corresponda con la
        realidad, induciendo en error a los visitantes sobre el producto a ofrecer
        o vender a través de este medio. • Utilizar el sitio web para difundir u
        ofrecer cualquier franquicia, esquema de pirámide, membresía a clubes o
        grupos, representación, mandato o corretaje en venta de bienes,
        oportunidades de negocio que requiera pagos anticipados o pagos periódicos
        en cualquier tiempo o solicitando la vinculación de terceras personas a
        esquemas piramidales de negocio o la captación masiva de dineros del
        público sin la autorización de la entidad competente. • Utilizar el sitio
        web para ejecutar actividades que signifiquen la comisión de conductas
        relacionadas con los delitos de lavado de activos o financiación del
        terrorismo, o aquellos conexos con estos. • Borrar, editar, transformar de
        cualquier forma o comercializar cualquier material publicado en el sitio
        web por cualquiera otra persona o entidad sin la debida autorización de la
        entidad competente. • Usar cualquier elemento, diseño, software o rutina
        para interferir o intentar interferir con el funcionamiento adecuado de
        este sitio web o que tenga como propósito la modificación del sitio web. •
        Intentar descifrar, compilar o desensamblar cualquier software o sistema
        que sea utilizado para el funcionamiento del sitio web. • Incluir en el
        sitio web material que esté protegido por las leyes sobre derechos de
        autor o cualquier otro derecho de propiedad intelectual o industrial, a
        menos que el usuario / visitante sea titular de tales derechos y/o se
        encuentre facultado para comunicar públicamente los derechos incorporados
        en la publicación. VIVIENDA.COM S.A.S presume de buena fe que el usuario /
        visitante tiene las facultades necesarias para publicar los textos o
        imágenes. Cualquier persona que acredite mejor derecho o pueda sustentar
        la falta de capacidad usuario / visitante para la comunicación pública,
        podrá comunicarse en cualquier tiempo con VIVIENDA.COM S.A.S a través de
        los canales de comunicación dispuestos para que este lleve a cabo las
        actuaciones pertinentes. • Incluir en el sitio web material que revele
        secretos industriales o comerciales salvo que sea el legítimo propietario
        de los mismos o haya obtenido autorización para el efecto. • Incluir
        material que pueda considerarse razonablemente como obsceno, difamatorio,
        abusivo, amenazante u ofensivo para cualquier persona natural o jurídica.
        • Incluir en el sitio web imágenes o información considerada como
        pornográfica, que incluyan material sexual explícito o que sea considerada
        como pornografía infantil en los términos del Decreto 1524 de 2002 o
        cualquier otra disposición legal o reglamentaria que regule la materia. •
        Incluir en el sitio web publicidad o anuncios publicitarios en cualquier
        modalidad, cadenas de cartas sin la previa y expresa autorización de
        VIVIENDA.COM S.A.S. • Introducir en los servidores o cualquier otro
        sistema donde se encuentre alojada la plataforma malware o programas de
        computador con potencialidad para afectar software, sistemas o
        herramientas.
  </p>
      <h3>9. REGISTRO DE USUARIOS</h3>
      <p class="px-3 text-justify">Cada persona natural o jurídica que se encuentre interesada bien sea en
        adquirir u ofrecer para la venta, arriendo, permuta o cualquier otro
        negocio traslaticio en todo o en parte del derecho de dominio deberá
        registrarse de forma gratuita en la plataforma llenando los campos del
        formulario exclusivamente con información verdadera y de su propiedad. El
        nombre de usuario o su contraseña es de uso personal e intransferible.
        Cada visitante será responsable de forma exclusiva por la confidencialidad
        de su nombre de usuario y contraseña. En ese mismo sentido, el visitante
        será responsable de forma directa, exclusiva y excluyente por la
        destinación que le dé a su usuario. El visitante notificará a VIVIENDA.COM
        S.A.S de forma inmediata cuando conozca que su nombre de usuario y/o
        contraseña han sido vulnerados o terceros a ajenos se encuentran haciendo
        uso de ella sin su consentimiento. VIVIENDA.COM S.A.S presume de buena fe
        que los datos ingresados por el usuario en su registro al portal web
        corresponde a los de su propia identidad o a la de un tercero con la
        debida representación. La verificación previa y automática que realiza el
        titular del dominio se ceñirá estrictamente al suministro de la totalidad
        de la información útil, pertinente y necesaria para que los demás usuarios
        y/o visitantes tengan razonable confiabilidad sobre las transacciones que
        pretendan utilizar. Será deber de los usuarios, de acuerdo a las reglas de
        la lógica y/o de la experiencia, evaluar razonadamente las ofertas y
        oferentes a fin de formarse un consentimiento serio sobre la transacción
        que pretende llevar a cabo. 10. MENORES DE EDAD Para el uso de la Página
        Web el usuario deber ser mayor de edad. VIVIENDA.COM no permite que
        menores de edad utilicen los servicios. En caso de que esto ocurra los
        padres o representantes legales del menor serán los responsables sobre el
        acceso de los menores a la Página Web. Al aceptar lo Términos y
        Condiciones estará aceptando que es mayor de edad. 11. DERECHO DE
        SUSPENSIÓN DE CUENTA DEL USUARIO VIVIENDA.COM puede anular temporalmente o
        permanente el acceso a los usuarios registrados sin ninguna
        responsabilidad por incumplir con los Términos y Condiciones o cualquier
        reglamento de VIVIENDA.COM.
  </p>
      <h3>
        12. PRODUCTOS OFRECIDOS (Esto es los tipos de anuncios y otras
        condiciones de VIVIENDA.COM)
      </h3>

      <p class="px-3 text-justify">ara los clientes particulares (personas naturales diferentes de agentes
        inmobiliarios, inmobiliarios y constructores) aplican las siguientes
        condiciones de producto: Los únicos productos que ofrece VIVIENDA.COM para
        el público general, entiéndase personas naturales con avisos gratuitos
        aceptados dentro del portal, son: - Publicación de inmuebles (describir
        producto) - Servicios para el hogar - Servicios populares como:
        Carpintería, arquitectónico, marketing, cerrajería, plomería y servicio
        herrero, maestro contratista de obra civil. - Servicios adicionales como :
        Limpieza, jardinería, pintor, electricista.</p>
      <h3>13. TERMINACIÓN DE USO</h3>
      <p class="px-3 text-justify">VIVIENDA.COM S.A.S se reserva el derecho unilateral de restringir el
        acceso al sitio web a los Usuarios en caso de detectar cualquier uso no
        permitido del Sitio Web y tomar las acciones legales correspondientes.
  </p>
      <h3>14. MODIFICACIONES</h3>
      <p class="px-3 text-justify">VIVIENDA.COM S.A.S se reserva el derecho unilateral a realizar los cambios
        que considere oportunos a los presentes Términos y Condiciones en
        cualquier momento y sin previo aviso haciendo público en el Sitio Web los
        términos modificados. Los Términos y Condiciones Generales aplicables a
        determinada transacción o compra son aquellos vigentes y aceptados por el
        Usuario al momento de realizar la respectiva compra. Cualquier cambio en
        nuestra política de protección de datos se publicará en el sitio web.
        Podrán ser modificados también de la misma manera el diseño, la
        presentación o configuración, los requisitos de registro o utilización del
        Sitio Web, sin que ello genere derecho a reclamo o indemnización alguna a
        favor del Usuario. Es responsabilidad del Usuario revisar periódicamente
        los Términos y Condiciones de Uso. El uso continuo que haga del Sitio Web
        luego de haberse realizado cambios en estos Términos y Condiciones de Uso
        supondrá que acepta esos cambios.</p>

      <h3>
        15. LEGISLACIÓN APLICABLE Y JURISDICCIÓN COMPETENTE LAS COMPRAVENTAS
        REALIZADAS EN EL SITIO
      </h3>
      <p class="px-3 text-justify">Las compraventas realizadas en el sitio web se someten a la legislación
        colombiana. En el supuesto de que surja cualquier conflicto o discrepancia
        en la interpretación o aplicación de las presentes condiciones
        contractuales, los Juzgados y Tribunales que, en su caso, conocerán del
        asunto, serán los que disponga la normativa legal aplicable en materia de
        jurisdicción competente, en la que se atiende, tratándose de consumidores,
        al lugar del cumplimiento de la obligación o al del domicilio de la parte
        compradora.</p>

      <h3>16. VALIDEZ</h3>
      <p class="px-3 text-justify">Las partes expresamente declaran que en el caso en el que alguna de las
        disposiciones de los términos y condiciones de uso sea declarada nula o
        inválida por la jurisdicción competente, las demás cláusulas y
        obligaciones del presente contrato, conservarán su validez.</p>

      <h3>17. ENCABEZADOS</h3>
      <p class="px-3 text-justify">Los encabezados utilizados en cada una de las cláusulas de este Contrato,
        son exclusivamente para referencia por lo que no se consideran para
        efectos de interpretación o cumplimiento del mismo
  </p>
      <h3>18. DERECHOS DE LOS CONSUMIDORES</h3>
      <p class="px-3 text-justify">El Usuario gozará de todos los derechos y obligaciones establecidos en la
        legislación Colombiana sobre Protección al Consumidor, por tal razón,
        podrán radicar sus peticiones, quejas, reclamos y sugerencias (PQR) a
        través de las diferentes herramientas y mecanismos de comunicación
        establecidos por la página. Igualmente, el usuario también podrá
        presentarlas de manera presencial en nuestros establecimientos de
        comercio. Para cualquier sugerencia, reclamo, comentario o propuesta
        relacionado con el Sitio o con el presente documentos sobre Términos y
        Condiciones de uso, con derechos al consumidor o privacidad de datos
        personales escríbanos por correo electrónico contacto@vivienda.com.</p>
    </div>
  </div>
</div>
