import { Component, Input, OnInit } from '@angular/core';
import { ApiConnectionService } from 'src/app/services/api-connection.service';
import { HomeService } from 'src/app/services/home/home.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-user-admin-info',
  templateUrl: './info.component.html',
  styleUrls: [
    '../../user-admin/user-admin.component.css', './info.component.css'
  ]
})
export class InfoComponent implements OnInit {
  @Input() user: any = {};
  @Input() profile: any;
  @Input() isProfile = false;
  type_documents = environment.type_documents;
  @Input() social = environment.social;
  image: any = null;
  @Input() slug = null;
  @Input() action = null;

  browser = false;
  activeRequest = null;

    // Cropper
    file: any = '';
    croppedEdit: any = '';
    imageChangedEvent: any = [];
    croppedImage: any = '';
    croppedImages: any = [];
    transform: ImageTransform = {};
    canvasRotation = 0;
    scale = 1;
    rotation = 0;


  constructor(
    protected apiService: ApiConnectionService,
    protected homeService: HomeService
  ) { }

  ngOnInit(): void {
  }

  update() {
    Swal.fire({
      title: 'Estamos guardando los datos...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      onBeforeOpen: () => Swal.showLoading()
    });
    if (this.isProfile)
      this.updateProfile();
    else
      this.updateUser();
  }

  updateUser() {
    let data = this.user;
    data['social'] = this.social;
    this.apiService.putData('user/updateUser', data, {auth: true})
      .subscribe(
        response => {
          Swal.fire({
            title: response.message,
            icon: 'success'
          }).then(() => {
            localStorage.removeItem('user');
            localStorage.setItem('user', JSON.stringify(response.data));
          });
        },
        error => {
          const result = this.homeService.parseErrorResponse(error, this.browser);
          if (error.status === 400) {
            Swal.fire({
              title: error.error.message,
              html: result.message,
              icon: result.icon
            });
          }
          return;
        }
      )
  }

  updateProfile() {
    let data = {
      name: this.profile.name,
      identification: this.profile.identification,
      phone: this.profile.phone,
      description: this.profile.description,
      cellphones: [],
      websites: [],
      social: this.social,
      locations: this.profile.locations
    };
    this.profile.cellphones.forEach(v => {
      if (v.value && v.value.trim() !== '')
        data.cellphones.push(v.value.trim());
    });
    this.profile.websites.forEach(v => {
      if (v.value && v.value.trim() !== '')
        data.websites.push(v.value.trim());
    });
    this.apiService.putData('user/profile/' + this.slug, data, {auth: true})
      .subscribe(
        response => {
          Swal.fire({
            title: response.message,
            icon: 'success'
          }).then(() => {
            if (this.action == 'reload')
              window.location.reload();
            if (this.slug != response.data.slug)
              window.location.href = '/usuario/perfil/' + response.data.slug;
          });
        },
        error => {
          const result = this.homeService.parseErrorResponse(error, this.browser);
          if (error.status === 400) {
            Swal.fire({
              title: error.error.message,
              html: result.message,
              icon: result.icon
            });
          }
          return;
        }
      )
  }

  async uploadImage(event: any, encapsulation = 'image') {
    if (event.target.files[0].size < 2000000) {
      Swal.fire({
        title: 'Actualizando imagen, aguarda un momento',
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => Swal.showLoading()
      });
      const content = await this.readFile(event);
      const data = {
        image: this.image,
        encapsulation: encapsulation
      };
      
      this.apiService.postData('user/uploadImage'+ (this.isProfile ? '/' + this.slug : ''), data, {auth: true})
        .subscribe(
          response => {
            Swal.fire({
              title: response.message,
              icon: 'success'
            }).then(() => {
              if (!this.isProfile) {
                const user = JSON.parse(localStorage.getItem('user'));
                user.image = response.path;
                localStorage.removeItem('user');
                localStorage.setItem('user', JSON.stringify(user));
              }
              window.location.reload()
            });
          },
          error => console.log(error)
        )
    } else {
      Swal.fire({
        title: "La imagen debe pesar maximo 2MB"
      })
    }
  }

  readFile(event: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        this.image = reader.result;
        resolve(reader.result);
      };
      reader.onerror = reject;
    })
  }

  hasMoreLocations() {
    if (this.profile.locations.length <= 1)
      return false;
    let length = 0;
    this.profile.locations.forEach(v => {
      if (!v.deleted_at)
        length += 1;
    });
    return length == this.profile.locations.length;
  }

  cropperReady() {
    return true;
  }

  imageCropped(event: ImageCroppedEvent) { 
      this.croppedImages = event.base64;

      console.log(this.croppedImages);
      
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  cancelCropper() {
    this.croppedImage = '';
    this.imageChangedEvent = [];
  }

  useCropper(event = []): void {
    Swal.fire({
      title: 'Actualizando imagen, aguarda un momento',
      allowOutsideClick: false,
      allowEscapeKey: false,
      onBeforeOpen: () => Swal.showLoading()
    });
    
    const data = {
      image: this.croppedImages,
      encapsulation: 'banner'
    };
    this.apiService.postData('user/uploadImage'+ (this.isProfile ? '/' + this.slug : ''), data, {auth: true})
      .subscribe(
        response => {
          Swal.fire({
            title: response.message,
            icon: 'success'
          }).then(() => {
            if (!this.isProfile) {
              const user = JSON.parse(localStorage.getItem('user'));
              user.image = response.path;
              localStorage.removeItem('user');
              localStorage.setItem('user', JSON.stringify(user));
            }
            window.location.reload()
          });
        },
        error => console.log(error)
      )

    this.croppedImages = [];
    this.croppedEdit = '';
  }
  

  
}
