import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(
    protected meta: Meta,
    protected title: Title
  ) {
    this.title.setTitle(`Términos y condiciones | Vivienda.com`);
    this.meta.addTags([
      { name: 'description', content: 'Conoce los Términos y Condiciones Especiales de uso del portal Vivienda.com. Aquí los lineamientos que aplican por el simple uso o acceso a cualquiera de las páginas que integran el portal vivienda.com, para los usuarios.' },
      { property: 'og:title', content: this.title.getTitle() },
      { property: 'og:description', content: 'Conoce los Términos y Condiciones Especiales de uso del portal Vivienda.com. Aquí los lineamientos que aplican por el simple uso o acceso a cualquiera de las páginas que integran el portal vivienda.com, para los usuarios.' },
      { property: 'og:url', content: 'https://vivienda.com/terminos-y-condiciones' },
      { property: 'og:image', content: 'https://cdn.vivienda.com/images/logo.png' }
    ])
  }

  ngOnInit(): void {
  }

}
