import { MapsAPILoader } from '@agm/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiConnectionService } from 'src/app/services/api-connection.service';
declare const $: any;

@Component({
  selector: 'app-service-index',
  templateUrl: './service-index.component.html',
  styleUrls: ['./service-index.component.css']
})
export class ServiceIndexComponent implements OnInit {
  services = [];
  results = {
    data: [],
    next_page_url: null
  };
  keyword = '';
  city = null;
  service = '';
  subservice = '';
  lat: any = null;
  lng: any = null;

  activeRequest = null;
  browser = false;

  constructor(
    protected mapsAPILoader: MapsAPILoader,
    protected ngZone: NgZone,
    protected apiService: ApiConnectionService,
    protected http: HttpClient,
    protected activateRoute: ActivatedRoute,
    protected meta: Meta,
    protected title: Title,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.browser = isPlatformBrowser(platformId);
    this.activateRoute.params.subscribe(v => {
      this.service = v.service;
      this.subservice = v.subservice;
    });
    this.title.setTitle(`Servicios para tu inmueble | Vivienda.com`);
    this.meta.addTags([
      { name: 'title', content: this.title.getTitle() },
      { name: 'description', content: 'Encuentra plomeros, arquitectos, herreros, cerrajeros y más serviciospara tu inmueble. Vivienda.com el sitio web para encontrar servicios inmobiliarios totalmente gratis.' },
      { name: 'og:title', content: this.title.getTitle() },
      { name: 'og:description', content: 'Encuentra plomeros, arquitectos, herreros, cerrajeros y más serviciospara tu inmueble. Vivienda.com el sitio web para encontrar servicios inmobiliarios totalmente gratis.' },
      { name: 'og:url', content: 'https://vivienda.com/servicios/buscador' }
    ]);
  }

  ngOnInit(): void {
    if (this.browser) {
      var urlParams = new URLSearchParams(window.location.search);
      this.lat = urlParams.get('lat') ? parseFloat(urlParams.get('lat')) : null;
      this.lng = urlParams.get('lng') ? parseFloat(urlParams.get('lng')) : null;
      this.city = urlParams.get('city');
      this.setGoogleAutocomplete();
      const s = async () => {
        return await this.getServicesList();
      }
      s();
    }
  }

  getServicesList() {
    return new Promise(resolve => {
      this.apiService.getData('services?type=static', {auth: false})
      .subscribe(
        response => {
          this.services = response;
          this.services.forEach((v, i) => {
            this.services[i].checked = false;
          });
          this.storageServices();
          resolve(
            this.proccessServices()
          );
        },
        error => console.log(error)
      )
    });
  }

  proccessServices() {
    if (this.service && this.service.trim() !== '') {
      var services = this.service.split('_');
      services.forEach(v => {
        var index = this.services.findIndex(value => value.slug == v);
        if (index > -1) {
          this.services[index].checked = true;
          this.services[index].subservices.forEach((value, i) => {
            this.services[index].subservices[i].checked = true;
          });
        }
      });
    } else {
      this.services.forEach((v, i) => {
        this.services[i].checked = true;
        v.subservices.forEach((value, index) => {
          this.services[i].subservices[index].checked = true;
        });
      });
    }
    this.getServices();
  }

  storageServices() {
    sessionStorage.removeItem('services');
    sessionStorage.setItem('services', JSON.stringify(this.services));
  }

  setGoogleAutocomplete() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete($("#location-service-filter")[0], {types: ['(cities)'], componentRestrictions: {country: 'co'}});
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {return;}
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.city = place.name;
        });
      });
    });
  }

  getServices() {
    if (this.activeRequest) {
      this.activeRequest.unsubscribe();
      this.activeRequest = null;
    }
    this.activeRequest = this.apiService.postData('busqueda/services', this.getBody(), {auth: false})
      .subscribe(
        response => {
          this.results = response;
          console.log(this.results);

        },
        error => {
          console.log(error);
        }
      )
  }

  selecAll() {
    setTimeout(() => {
      this.services.forEach((v, i) => {
        this.services[i].checked = $("#all-services").is(':checked');
        v.subservices.forEach((value, j) => {
          this.services[i].subservices[j].checked = $("#all-services").is(':checked');
        })
      });
      this.getServices();
    }, 100);
  }

  getBody() {
    var check = null;
    let body = {}
    if (this.browser) {
      check = JSON.parse(sessionStorage.getItem('service'));
    }
    if (check !== null) {
      var services = check.services;
      var subservices = check.subservices;
      this.services.forEach((v, i) => {
        if (v.checked)
          services.push(v.slug);
        v.subservices.forEach(value => {
          if (value.checked)
            subservices.push(value.slug);
        });
      });

      body['services'] = services;
      /*if (subservices !== undefined && subservices.length > 0)
        body['subservices'] = subservices;*/
      if (check.keyword && check.keyword.trim() !== '')
        body['keyword'] = check.keyword;
      if (check.city && check.city.trim() !== '')
        body['city'] = check.city;
      sessionStorage.removeItem('service');
    } else {
      let services = [];
      let subservices = [];
      this.services.forEach((v, i) => {
        if (v.checked)
          services.push(v.slug);
        /*v.subservices.forEach(value => {
          if (value.checked)
            subservices.push(value.slug);
        });*/
      });

      body['services'] = services;
      /*if (subservices.length > 0)
        body['subservices'] = subservices;*/
      if (this.keyword && this.keyword.trim() !== '')
        body['keyword'] = this.keyword;
      if (this.city && this.city.trim() !== '')
        body['city'] = this.city;
    }

    if (this.lat && this.lng) {
      body['location'] = {
        lat: this.lat,
        lng: this.lng
      }
    }
    return body;
  }

  allCheckeds() {
    let length = 0;
    this.services.forEach(v => {if (v.checked) length +=1; })
    return length == this.services.length;
  }

  loadMore() {
    this.http.post(this.results.next_page_url, this.getBody())
      .subscribe(
        (response: any) => {
          response.data.forEach(v => {
            this.results.data.push(v);
          });
          this.results.next_page_url = response.next_page_url;
        }
      )
  }

  getFormatImage(image, size, type = 'image', forceFormat = null) {
    if (type == 'image') {
      var file = 'https://cdn.vivienda.com/images/services/no-disponible.jpg';
      if (typeof image.thumbs !== 'undefined' && image.thumbs.length > 0) {
        const index = image.thumbs.findIndex(v => v.size == size);
        if (index > -1)
          file = image.thumbs[index].image;
      } else {
        var extension = image.path.substr(-3);
        if (!this.isAvailableImageFormat(extension)) {
          extension = image.path.substr(-4);
          if (!this.isAvailableImageFormat(extension)) {
            return file;
          }
        }
        if (!forceFormat)
          file = image.path.replace('.' + extension, '') + '-' + size + '.' + extension;
        else
          file = image.path.replace('.' + extension, '') + '-' + size + '.' + forceFormat;
      }

      return file;
    } else if (type == 'video')
      return this.getMimeVideo(image);

    return image.path;
  }

  isAvailableImageFormat(format): boolean {
    const formats = ['jpeg', 'jpg', 'png', 'jpe', 'jfif'];
    return formats.includes(format);
  }

  getMimeVideo(video) {
    try {
      var host = new URL(video.path);
      switch (host.hostname) {
        case 'www.youtube.com':
          let base = host.pathname.split('/');
          return 'https://i.ytimg.com/vi/' + base[2] + '/mqdefault.jpg';
          break;
      }
    } catch (e) {
      return 'https://i.ytimg.com/';
    }
  }

  isAllChecked(subservices) {
    var counter = 0;
    subservices.forEach(v => {
      if (v.checked)
        counter++;
    });
    return counter == subservices.length;
  }

  selectSubcategories(index) {
    this.services[index].checked = !this.services[index].checked;
    this.services[index].subservices.forEach((v, i) => {
      this.services[index].subservices[i].checked = this.services[index].checked;
    });
    this.getServices();
  }
}
