import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/services/auth/token.service';
declare const gtag: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  session;
  constructor(
    private tokenService: TokenService
  ) {
    this.session = this.tokenService.isLoggedIn();
  }

  ngOnInit(): void { }

  scrollTop(): void {
    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };
    scrollToTop();
  }

  clickEvent(event = '', category = '', label = '', value = 'true'): void {
    gtag('event', event, {
      'event_category': category,
      'event_label': label,
      'value': value
    });
  }
}
