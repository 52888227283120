import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiConnectionService } from 'src/app/services/api-connection.service';
import Swal from 'sweetalert2/dist/sweetalert2';
import { AgmInfoWindow, AgmMap, MapsAPILoader } from '@agm/core';
import { RecaptchaComponent } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { HostListener } from '@angular/core';
import { TokenService } from 'src/app/services/auth/token.service';
import { GALLERY_CONF, GALLERY_IMAGE, NgxImageGalleryComponent } from 'ngx-image-gallery';
declare const $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  mobile:any;
  slug = null;
  more = false;
  moreserv = false;
  profile: any = {
    reviews: {
      ratings: []
    }
  };
  lat = 4.6799359;
  lng = -74.1603453;
  distance = 10;
  radius = 10000;
  private geoCoder;
  ratings = {
    calification : 1
  };
  map;
  inmueblesarrow = false;
  serviciosarrow = false;
  ventasarrow = false;
  resenasarrow = false;
  ubicacionarrow = false;
  currentIW: AgmInfoWindow = null;
  previousIW: AgmInfoWindow = null;
  timeOut: any;
  location = [
    { lat: 0, lng: 0 },
    { lat: 0, lng: 0 }
  ];
  model = {
    name: '',
    surname: '',
    email: '',
    cellphone: '',
    message: '',
  }

  rate = 0;
  captcha = null;
  @ViewChild('captchaRef') public captchaRef: RecaptchaComponent;
  @ViewChild(NgxImageGalleryComponent) ngxImageGallery: NgxImageGalleryComponent;
  social = environment.social;

  items: any;
  browser = false;
  session = false;

  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showImageTitle: true,
    reactToMouseWheel: true,
    showExtUrlControl: false,
    reactToRightClick: true,
    imagePointer: true
  };

  images: GALLERY_IMAGE[] = [];

  imageGroup: any = {
    images: []
  };

  @HostListener("window:scroll", ['$event'])
  doSomethingOnWindowScroll($event: Event){
    /*
    if (document.body.scrollTop > 65 || document.documentElement.scrollTop > 65) {
      document.getElementById('details').classList.add('down');
      document.getElementById('details').classList.remove('d-fix');
      document.getElementById('details').classList.remove('d-none');

      $(".form-sticky").addClass('forms-sticky-scroll');
    } else {
      document.getElementById('details').classList.remove('down');
      document.getElementById('details').classList.add('d-none');

      $(".form-sticky").removeClass('forms-sticky-scroll');
    }
    */
  }

  constructor(
    protected mapsAPILoader: MapsAPILoader,
    protected ngZone: NgZone,
    private route: ActivatedRoute,
    protected apiService: ApiConnectionService,
    protected tokenService: TokenService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.browser = isPlatformBrowser(platformId);
    this.route.params.subscribe(v => this.slug = v.slug);
    this.session = this.tokenService.isLoggedIn();
  }

  ngOnInit(): void {
    if (window.screen.width === 768) {
      this.mobile = true;
    }
    this.inmueblesarrow=true;
    this.serviciosarrow=true;
    this.ventasarrow=false;
    this.resenasarrow=false;
    this.apiService.getData('profile/' + this.slug, {auth:false})
      .subscribe(
        response => {
          this.profile = response;
          this.getItems(this.profile.items);
          if (this.profile.canEdit) {
            this.profile.social.forEach(v => {
              const index = this.social.findIndex(value => value.name.toLowerCase() == v.name.toLowerCase());
              if (index > -1)
                this.social[index] = v;
            });
          }
        },
        error => {
          if (this.browser) {window.location.href = '/'};
        }
      );
  }

  onMapReady(map: AgmMap) {
    this.map = map;
    this.map.setOptions({
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: true,
      minZoom: 3,
      maxZoom: 18,
      styles: [{
          featureType: "administrative",
          stylers: [{ visibility: "off" }]
        }, {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ visibility: "off" }]
        }, {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            { visibility: "on" }
          ]
        }, {
          featureType: "transit",
          stylers: [{ visibility: "off" }]
        }, {
          featureType: "poi.attraction",
          stylers: [{ visibility: "off" }]
        }, {
          featureType: "poi.business",
          stylers: [{ visibility: "off" }]
        }, {
          featureType: "poi.government",
          stylers: [{ visibility: "off" }]
        }, {
          featureType: "poi.medical",
          stylers: [{ visibility: "off" }]
        }, {
          featureType: "poi.park",
          elementType: "labels",
          stylers: [{ visibility: "off" }]
        }, {
          featureType: "poi.place_of_worship",
          stylers: [{ visibility: "off" }]
        }, {
          featureType: "poi.school",
          stylers: [{ visibility: "off" }]
        }, {
          featureType: "poi.sports_complex",
          stylers: [{ visibility: "off" }]
        }, {
          featureType: "landscape.man_made",
          stylers: [
            { visibility: "on" },
            { color: "#fce8f0" }
          ]
        }, {
          featureType: "landscape.natural",
          elementType: "labels",
          stylers: [{ visibility: "off" }]
        }, {
          featureType: "landscape.natural",
          elementType: "geometry",
          stylers: [
            { color: "#fce8f0" },
            { visibility: "on" }
          ]
        }, {
          featureType: "water",
          elementType: "labels",
          stylers: [{ visibility: "off" }]
        }
      ]
    });
  }

  getFormatImage(path, size = "16x9-600x340", p: any = {}) {
    let extension = path.substr(-3);
    if (!this.isAvailableImageFormat(extension)) {
      extension = path.substr(-4);
      if (!this.isAvailableImageFormat(extension)) {
        if (p.images && p.images.length > 1)
          return this.getFormatImage(p.images[1].cropped, size);
        return 'https://cdn.vivienda.com/images/properties/no-disponible.jpg';
      }
    }

    return path.replace('.' + extension, '') + '-' + size + '.' + extension;
  }

  isAvailableImageFormat(format): boolean {
    const formats = ['jpeg', 'jpg', 'png', 'jpe', 'jfif'];
    return formats.includes(format);
  }

  onSubmit(f): void {
    if (f.form.status == 'VALID') {
      Swal.fire({
        title: 'Espera un momento...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onBeforeOpen: () => Swal.showLoading()
      });
      let data = this.model;
      data['profile'] = this.slug;
      this.apiService.postData('contact/profile', data, {auth: false})
        .subscribe(
          response => {
            Swal.fire(response.message, response.data, 'success')
            .then(() => {
              if (!this.session) {
                Swal.fire({
                  title: 'Ahora te invitamos a registrarte',
                  showCancelButton: true,
                  confirmButtonText: 'Registrarme',
                  cancelButtonText: 'Cancelar',
                  reverseButtons: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    localStorage.setItem('userParams', JSON.stringify({
                      name: f.value['name'],
                      surname: f.value['surname'],
                      cellphone: f.value['cellphone'],
                      email: f.value['email']
                    }));
                    window.location.href = '/auth/register?from=' + window.location.href + '&action=contactForm&section=profile';
                  } else
                    window.location.reload();
                });
              } else
                window.location.reload();
            });
          },
          error => {
            Swal.fire({
              title: 'Algo salió mal, inténtalo más tarde',
              icon: 'danger'
            });
          }
        )
    } else {
      var html = '<p class="pb-1">Te falta completar unos espacios para poder continuar con la solicitud de contacto:</p>';
      html += '<ul class="list-group text-left" style="padding-left: 15%;">';
      let array = [];
      for (var i in f.form.controls)
        if (f.form.controls[i].errors)
          html += '<li class="list-group-item text-danger" style="border:none!important;"><i class="fas fa-times"></i> El campo ' + this.translateWord(i) + ' es requerido</li>';
      html += '</ul>';
      Swal.fire({
        title: 'Algo salió mal...',
        html: html,
        imageUrl: 'https://cdn.vivienda.com/images/icons/imagen-error.png',
        confirmButtonColor: '#f7bf50',
        confirmButtonText: 'Aceptar'
      });
    }
  }
  markerClick(infoWindow) {
    if (this.previousIW) {
      this.currentIW = infoWindow;
      this.previousIW.close();
    }
    this.previousIW = infoWindow;
  }
  boundsChange(event) {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = null;
    }
    this.timeOut = setTimeout(() => {
      this.location = [
        { lat: event.Va.j, lng: event.Qa.i },
        { lat: event.Va.i, lng: event.Qa.j },
      ];
      this.timeOut = null;
    }, 500);
  }
  translateWord(word) {
    const words = {
      name: 'Nombre',
      surname: 'Apellido',
      email: 'Correo',
      cellphone: 'Celular',
      message: 'Mensaje'
    }
    return words[word] ? words[word] : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
  }

  selectRate(number) {
    this.rate = 0;
    this.removeRateOver();
    this.rate = number;
    for (var i = 0; i <= number; i++) {
      $('.rate-' + i + ' > i').removeClass('far');
      $('.rate-' + i + ' > i').addClass('fas');
    }
  }

  rateOver(position) {
    if (!this.rate) {
      for (var i = 0; i <= position; i++) {
        $('.rate-' + (i + 1) + ' > i').removeClass('far');
        $('.rate-' + (i + 1) + ' > i').addClass('fas');
      }
    }
  }

  removeRateOver() {
    if (!this.rate) {
      for (var i = 0; i <= 5; i++) {
        $('.rate-' + (i + 1) + ' > i').removeClass('fas');
        $('.rate-' + (i + 1) + ' > i').addClass('far');
      }
    }
  }

  resetModal() {
    this.rate = 0;
    this.captcha = null;
    this.captchaRef.reset();
    $("#rate-comment").val('');
    this.removeRateOver();
  }

  saveRate(f) {
    if (this.captcha && this.captcha !== '' && this.rate) {
      Swal.fire({
        title: 'Estamos guardando la reseña, espera un momento...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => Swal.showLoading()
      });
      const body = {
        score: this.rate,
        message: f.value['rate-comment'],
        'g-recaptcha-response': this.captcha
      };
      this.apiService.postData('profile/' + this.slug + '/rate', body, {auth: false})
        .subscribe(
          response => {
            Swal.fire({
              title: response.message,
              icon: 'success'
            }).then(() => {
              $("#rate-modal").modal('hide');
              this.resetModal();
            })
          },
          error => {
            if (error.status == 401) {
              Swal.fire({
                title: 'Para continuar por favor inicia sesión',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Iniciar sesión',
                reverseButtons: true
              }).then(result => {
                if (result.value)
                  window.location.href = '/auth/login?redirectTo=' + window.location.pathname;
              });
            } else if (error.status == 409) {
              Swal.fire({
                title: error.error.message,
                icon: 'warning'
              }).then(() => {
                $("#rate-modal").modal('hide');
                this.resetModal();
              });
            } else {
              Swal.fire({
                title: error.error.message,
                icon: 'warning'
              }).then(() => {
                this.captchaRef.reset();
              });
            }
          }
        )
    }
  }

  getItems(items) {
    var array: any;
    if (this.profile.isCompany) {
      array = {};
      array = {
        'published': items.published,
        'closed': items.closed
      };
    } else {
      array = [];
      items.forEach(v => {
        v.locations.forEach(value => {
          array.push({
            slug: v.slug,
            name: v.name,
            description: v.description,
            image: v.image,
            city: value.city,
            latitude: value.latitude,
            longitude: value.longitude
          });
        });
      });
    }
    this.items = array;
  }

  loadDescription() {
    if ($("#load-description").text().trim() == 'Ver más') {
      $("#description-container").text(this.profile.description);
      $("#load-description").html(' Ver menos <i class="fas fa-chevron-right ml-2"></i>');
    } else {
      $("#description-container").text(this.profile.description.slice(0, 120) + '...');
      $("#load-description").html(' Ver más <i class="fas fa-chevron-right ml-2"></i>');
    }
  }

  openModalEdit() {
    if (this.profile.canEdit) {
      $("#edit-profile-modal").modal({
        backdrop: 'static',
        keyboard: false
      });
      setTimeout(() => {
        this.setGoogleSeeker();
      }, 1000);
    }
  }

  setGoogleSeeker() {
    const autocomplete = new google.maps.places.Autocomplete($("#address")[0], {componentRestrictions: {country: 'co'}});
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {return;}
        place.adr_address.split(',').forEach(v => {
          $("#address-components").append(v);
        });
        if (this.profile.locations.findIndex(v => v.latitude == place.geometry.location.lat()) < 0) {
          this.profile.locations.push({
            address: $('.street-address').html() || place.formatted_address,
            city: $('.locality').html() || $('.region').html(),
            country: $('.country').html(),
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
            postalCode: $('.postal-code').html()
          });
        }
        $("#address-components").html('');
        $("#address").val('');
      });
    });
  }

  includes(value, content) {
    return typeof value == 'object' ? value.value.includes(content) : value.includes(content);
  }

  openGalleryViewer(image: any = {}): void {
    this.imageGroup = image;
    $(".carousel-cell-container").toggleClass('d-none');
  }

  openImageViewer(image: any = {}, index: number = 0): void {
    this.images = [];
    this.setTimageViewer(image.images, image.name)
      .then(() => {
        setTimeout(() => this.ngxImageGallery.open(index), 100);        
      });
  }

  setTimageViewer(images: any = [], name: string): any {
    return new Promise((resolve) => {
      const length = images.length;
      images.forEach((v: any, key: number, array: any) => {
        this.images.push({
          url: v.image,
          thumbnailUrl: v.thumb,
          title: name
        });
        key++;
        if (key == length) {
          resolve(true);
        }
      });
    });
  }
}
