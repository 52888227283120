import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/services/seo/seo.service';

@Component({
  selector: 'app-canonical',
  templateUrl: './canonical.component.html'
})
export class CanonicalComponent implements OnInit {
  pageTitle = '';
  constructor(private seoService: SEOService) { }
  ngOnInit() {
    this.createLinkForCanonicalURL();
  }
  setPageTitle(title: string) {
    this.seoService.setPageTitle(title);
  }   
  getPageTitle() {
    this.pageTitle = this.seoService.getPageTitle();
  } 
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 
} 