<div class="color-gray">
    <div class="container col-12 info-section">
        <div class="container pl-0">
            <p class="mb-0 pl-0 text-paragrap">
                <a href="/">Vivienda.com</a> >
                <a href="/servicios/{{ service }}">Servicios</a> >
                <a href="/perfil/{{ slug }}">{{ profile.name }}</a> >
                <b class="text-yellow">{{ services.name }}</b>
            </p>
        </div>
    </div>

    <div class="container pl-0 pr-md-2 pr-0 details-container">
        <div class="detail d-block pl-0" id="details">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5 col-12 m-auto pt-2 pt-lg-3 pt-lg-0">
                        <div class="row">
                            <div class="col-lg-3 ml-lg-4 col-12 pr-0 pt-2 pt-lg-0 img-person">
                                <a href="/perfil/{{ slug }}">
                                    <img src="{{ profile.image ? profile.image : 'https://cdn.vivienda.com/images/icons/persona.png' }}" onerror="this.src  = 'https://cdn.vivienda.com/images/icons/persona.png'" class="h-100 mx-auto" alt="{{ profile.name }} | Vivienda.com" style="border: 2px solid #e2e2e2;" (click)="openGallery(index)">
                                </a>
                            </div>
                            <div class="col-lg-8 col-12 mb-lg-2 pl-lg-4 mt-lg-0  pl-sm-3 pr-0">
                                <div class="row">
                                    <div class="col-12 pl-0 text-profile">
                                        <h5 class="mb-0 title cursor-pointer" style="color: #4c4b4b">
                                            <a href="/perfil/{{ slug }}" style="color: #4c4b4b">{{ profile.name }}</a>
                                        </h5>
                                        <span>{{ profile.description ? profile.description.slice(0, 45).trim() : '' }}...</span>
                                    </div>
                                    <div class="col-12 d-flex pl-1 calification">
                                        <div class="row px-2 mb-0">
                                            <div class="col-12 d-flex flex-row px-1 mb-1" style="font-size: 16px;">
                                                {{ profile.reviews && profile.reviews.qualification ? profile.reviews.qualification : '0' }}
                                                <div class="ml-1" *ngFor="let num of [1, 2, 3, 4, 5]">
                                                    <i *ngIf="profile.reviews && num <= profile.reviews.qualification; else nostar" class="fa fa-star text-yellow fa-sm mt-n1 cursor-pointer" onclick="$('#rate-modal-profile').modal({backdrop: 'static', keyboard: false})" (click)="selectRate(num)"></i>
                                                    <ng-template #nostar>
                                                        <i class="far fa-star text-yellow fa-sm mt-n1 cursor-pointer" onclick="$('#rate-modal-profile').modal({backdrop: 'static', keyboard: false})" (click)="selectRate(num)"></i>
                                                    </ng-template>
                                                </div>
                                                <span class="text-gray1 text-regular ml-1" style="font-size:1rem"> | {{ profile.reviews && profile.reviews.count ? profile.reviews.count : '0' }} reseñas</span>
                                            </div>
                                            <div class="col-12 d-flex flex-row pl-0 mb-1">
                                                <h6 class="d-flex flex-row px-1 mb-1 text-gray1 text-regular" *ngIf="profile.cities">
                                                    <span *ngFor="let c of profile.cities.split(', '); index as i" [class]="i == 0 ? 'd-block' : 'd-none d-md-block'">{{ c }}{{ (i + 1) < services.profile.cities.split(', ').length ? ',' : ''}}</span>
                                                </h6>
                                                <h6 class="d-flex flex-row px-1 mb-1 text-gray1 text-regular">
                                                    <span>
                                                        | <img src="https://cdn.vivienda.com/images/icons/empleado.png" width="18px"> {{ profile.employees }} Empleados
                                                    </span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3"></div>
                    <div class="col-lg-4">
                        <div class="row">
                            <div class="col-md-12 my-2 mx-0 pr-0 pl-0">
                                <div class="row justify-content-center align-items-center" *ngIf="services.contacts">
                                    <div class="col-4 px-0">
                                        <h6 class="mb-0">
                                            <i class="fa fa-phone" aria-hidden="true"></i> {{ services.contacts[0]?.phones[0]?.phone }}
                                        </h6>
                                    </div>
                                    <div class="col-1 p-0">
                                        <a href="https://wa.me/57{{ services.contacts[0]?.phones[0]?.phone }}">
                                            <img src="https://cdn.vivienda.com/images/icons/wp.png" class="whatsapp-img">
                                        </a>
                                    </div>
                                    <div class="col-6">
                                        <a  class="d-block side-btn text-white bg-yellow text-center text-bold py-2 px-2 llamar" style="height: fit-content; font-size: 14px; width: 100%;" href="tel:57{{ services.contacts[0]?.phones[0]?.phone || 'Sin contacto' }}">
                                            <img src="https://cdn.vivienda.com/images/icons/call-white.png" class="phone-img"> Llamar
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex icons-desktop pb-3" *ngIf="profile.social">
                                <span class="mx-auto" *ngFor="let s of profile.social">
                                    <a href="{{ s.value }}">
                                        <img src="{{ s.image }}" alt="{{ s.name }} {{ profile.name }} | Vivienda.com" style="max-width: 15px;">
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-3">
        <div class="row">
            <div class="col-lg-7 col-12 px-3">
                <div class="row px-2 mt-1 card py-3 py-md-0 float-images-scroll">
                    <div class="col-12 px-0 pt-2">
                        <div class="carousel slide">
                            <div class="position-relative banner-gallery" id="imageContainer">
                                <img src="{{ services.images && services.images.length > 0 && getFirstImageFormat() && finalResponse ? getFormatImage(getFirstImageFormat(), '16x9') : services.images.length == 0 && finalResponse ? 'https://cdn.vivienda.com/images/services/no-disponible.jpg' : './assets/img/loading-img.gif' }}" onerror="this.src = 'https://cdn.vivienda.com/images/services/no-disponible.jpg'" (error)="errorImage(getFirstImageFormat(), '16x9', 'image-banner-web')" id="image-banner-web" class="imgshown active pointer" [class]="checkVideo() ? 'd-none' : ''" (click)="openGallery(index)">
                            </div>
                            <div class="position-relative banner-gallery" *ngIf="checkVideo()" id="videoContainer" style="z-index: 0;">
                                <iframe width="100%" height="500px" id="video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <a class="carousel-control-prev" onclick="return false;" role="button" data-slide="prev" (click)="changeImage('previous')" *ngIf="services.images && services.images.length > 1">
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" onclick="return false;" role="button" data-slide="next" (click)="changeImage('next')" *ngIf="services.images && services.images.length > 1">
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>

                    <!--<div class="container-fluid px-0 mb-lg-3 mt-2">
                        <div class="carousel carousel-gallery slide d-md-block w-100" id="images-slide">
                            <div class="carousel-inner row sub-banner w-100 mx-auto">
                                <div class="carousel-item carousel-gallery" [class]="i == 0 ? 'active' : ''"  *ngFor="let group of imageGroup; index as i">
                                    <div class="row justify-content-center px-2">
                                        <div class="col-md-4 col-4 px-2" *ngFor="let image of group; index as j">
                                            <div class="imgcar pointer position-relative" [class]="image.type == 'video' ? 'container-youtube' : ''" (click)="replaceImage(image, i, j)">
                                                <img src="{{ getFormatImage(image, '16x9-180x100', image.type) }}" onerror="this.src = 'https://cdn.vivienda.com/images/services/no-disponible-mini.jpg'" class="img-fluid w-100" (error)="errorImage(image, '16x9-180x100', 'image-web-' + i + '-' + j + '-format')">
                                                <div class="middle" *ngIf="image.type == 'video'">
                                                    <i class="fab fa-youtube text-yt"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>-->

                    <div class="container-fluid px-2 mb-lg-3 mt-2" *ngIf="browser && imageGroup.length > 1">
                        <carousel style="height: 150px;">
                            <div class="carousel-cell" *ngFor="let image of imageGroup; index as i" >
                                <img src="{{ image.thumb }}" class="carousel-cell-image" (click)="replaceImage(image, i)">
                            </div>
                        </carousel>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-12 px-0 px-md-2">
                <div class="col-12 my-1 p-3 card">
                    <h2 class="text-bold text-color-black mb-0">{{ services.name }}</h2>
                    <p class="text-gray text-thin desc-p-w mb-1" id="service-description">
                        {{ services.description ? services.description.slice(0, 200) : '' }}{{ services.description ? services.description.length >= 199 ? '...' : '' : '' }}
                        <a (click)="descriptionContent()" style="cursor: pointer;width: 150px;" *ngIf="services.description && services.description.length >= 199">
                            Ver más <i class="fa fa-chevron-right ml-2"></i>
                        </a>
                    </p>
                    <hr>
                    <h5 class="text-bold text-color-black">Subcategorías del servicio</h5>
                    <span class="ml-2" *ngFor="let s of services.subservices">
                        <i class="fas fa-check"></i> {{ s.name }}
                    </span>
                    <hr>
                    <h5 class="text-bold text-color-black">Nos encuentras en</h5>
                    <span class="ml-2" *ngFor="let ns of services.locations">
                        <i class="fas fa-hand-point-right"></i> {{ ns.city }} <br>
                    </span>
                    <hr>
                    <h5 class="text-bold text-color-black">Califica este servicio</h5>
                    <div class="col-12 d-flex flex-row px-1 mb-1" style="font-size: 16px;">
                        {{ services.reviews ? services.reviews.qualification : '0' }}
                        <div class="ml-1" *ngFor="let num of [1, 2, 3, 4, 5]">
                            <i *ngIf="services.reviews && num <= services.reviews.qualification; else mostrarService" onclick="$('#rate-modal').modal({backdrop: 'static', keyboard: false})" class="fa fa-star text-yellow fa-sm mt-n1 cursor-pointer"></i>
                            <ng-template #mostrarService>
                                <i class="far fa-star text-yellow fa-sm cursor-pointer" onclick="$('#rate-modal').modal({backdrop: 'static', keyboard: false})"></i>
                            </ng-template>
                        </div>
                        <span class="text-gray1 text-regular ml-1" style="font-size: 1rem;"> | {{ services.reviews ? services.reviews.count : '0' }} reseñas</span>
                    </div>
                    <hr>
                    <h5 class="text-bold text-color-black">Comentarios sobre este servicio</h5>
                    <div class="col-12 d-flex flex-row px-1 mb-1">
                        <div class="container px-0">
                            <div class="col-12 pt-2 card px-1" *ngIf="!services.reviews || services.reviews.count == 0">
                                <div class="row px-3 py-2 d-flex align-items-center">
                                    <div class="col-12">
                                        <h4 class="text-bold text-color-black text-center">Aún no hay reseñas de este servicio. Sé el primero en escribir una</h4>
                                        <button data-toggle="modal" data-target="#rate-modal" class="califcation mx-auto d-block text-white bg-yellow text-center text-bold py-2 px-4 ml-2 border-0" style="height: fit-content;border-radius: 10px;" *ngIf="!services.canEdit">
                                            <i class="fa fa-star mr-2"></i>Califícame
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 pt-2 px-1" *ngIf="services.reviews && services.reviews.count > 0">
                                <div class="row px-2 py-2 d-flex align-items-center mt-2 card" *ngFor="let res of services.reviews.ratings;index as i">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="img-cont d-flex-flex-row justify-content-center align-items-center review-services" style="border-radius: 50%;overflow: hidden;border: 1px solid rgba(247, 191, 80, 1);">
                                                    <img src="{{ res.user.image ? res.user.image : 'https://cdn.vivienda.com/images/icons/persona.png' }}" onerror="this.src = 'https://cdn.vivienda.com/images/icons/persona.png'" class="w-100">
                                                </div>
                                            </div>
                                            <div class="col-8">
                                                <p class="text-regular mb-0 text-gray">{{ res.user.name }} {{ res.user.surname }}</p>
                                                <p class="text-regular mb-2 text-gray">{{ res.created_at | date:'EEE MMM d,yyyy' }}</p>

                                                <div class="mb-1 d-flex flex-row">
                                                    <div class="mr-1" *ngFor="let num of [1, 2, 3, 4, 5]">
                                                        <i *ngIf="num <= res.score; else nostar" class="fa fa-star text-yellow mt-n1"></i>
                                                        <ng-template #nostar><i class="far fa-star text-yellow mt-n1"></i> </ng-template>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 ">
                                        <p class="text-thin mb-2 text-gray mb-0 p-review p-review-{{i}}">{{ res.commentary ? res.commentary : 'No hay comentario' }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 card px-md-2 px-2 mt-md-3 mt-2">
                    <form #contactForm="ngForm" (ngSubmit)="contact(contactForm)" class="text-center py-2" autocomplete="off" style="box-shadow: none;">
                        <div class="d-flex flex-row w-100 px-3 py-2">
                            <div class="col-8">
                                <h6 class="text-bold text-color-black mb-0 text-left" style="line-height: 1;">Ponte en contacto con</h6>
                                <h6 class="text-semibold text-color-black mb-0 text-left">{{ profile.name }} - {{ services.name }}</h6>
                            </div>
                            <div class="col-4">
                                <div style="width: 40px;height: 40px;border-radius: 50%;border: 2px solid #e2e2e2;overflow: hidden;background-color: #fff;" class="d-flex justify-content-center ml-auto align-items-center">
                                    <img src="{{ profile.image ? profile.image : 'https://cdn.vivienda.com/images/icons/persona.png' }}" onerror="this.src  = 'https://cdn.vivienda.com/images/icons/persona.png'" class="h-100" alt="{{ profile.name }} | Vivienda.com">
                                </div>
                            </div>
                        </div>
                        <div class="pb-0 px-2 pt-0">
                            <input type="text" name="name" id="name" placeholder="Nombre(s)" class="px-3 w-100" ngModel autocomplete="new-name" required>
                            <input type="text" name="surname" id="surname" placeholder="Apellido(s)" class="px-3 w-100" ngModel autocomplete="new-surname" required>
                            <input type="email" name="email" id="email" placeholder="Correo electrónico" class="px-3 w-100" ngModel autocomplete="new-email" required>
                            <input type="text" name="cellphone" id="cellphone" placeholder="Teléfono" class="px-3 w-100" ngModel inputmode="numeric" required>
                            <textarea name="message" id="message" cols="30" rows="2" placeholder="Escríbele un mensaje al agente de tu solicitud" required></textarea>
                            <div class="d-flex flex-row align-items-start">
                                <input type="checkbox" name="terminos-perfil" id="terminos-perfil">
                                <label for="terminos-perfil" style="width: 20px; height: 15px;display: block;" class="m-0 mt-1"></label>
                                <p style="font-size: .5rem;" class="text-color-black text-medium mb-0 ml-2 text-left">Acepto los términos y condiciones, política de privacidad y el tratamiento de mis datos personales</p>
                            </div>
                            <div class="form-group mb-1 text-center">
                                <div class="g-recaptcha recaptcha-1 my-0">
                                    <re-captcha #captchaRef="reCaptcha" siteKey="6LdkKNwUAAAAABGyZqGidUfLfPWk73AuG0u_ylMa"
                                    class="g-recaptcha" (resolved)="resolved($event)" required></re-captcha>
                                </div>
                            </div>
                            <button type="submit" class="text-bold text-white bg-yellow mx-auto py-2 mt-1" style="width: 100px;border-radius:10px;border:0">Enviar</button>
                        </div>
                    </form>
                    <hr>
                    <div class="col-12 mb-1 text-center">
                        <a href="https://wa.me/{{ whatsapp[0] }}?text=Hola quisiera más información sobre el servicio" target="_blank" class="d-inline mx-2" *ngIf="whatsapp.length == 1" title="Contactar al propietario mediante WhatsApp">
                            <img src="https://cdn.vivienda.com/images/icons/just-phone.png" width="30px">
                        </a>
                        <a class="d-inline ml-3 mr-2 pointer" data-toggle="collapse" href="#WhatsAppContacts" role="button"
                            aria-expanded="false" aria-controls="WhatsAppContacts" *ngIf="whatsapp.length > 1">
                            <img src="https://cdn.vivienda.com/images/icons/just-phone.png" width="30px">
                        </a>
                        <a class="d-inline mx-2 pointer" role="button" title="Compartir" id="dropdownSharedProperty" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="https://cdn.vivienda.com/images/icons/share.png">
                        </a>
                        <div class="dropdown-menu mt-1" style="min-width: 1rem;" aria-labelledby="dropdownSharedProperty">
                            <a class="dropdown-item" title="Compartir en Facebook" target="_blank" href="https://www.facebook.com/share.php?u={{ uri }}">
                                <i class="fab fa-facebook"></i>
                            </a>
                            <a class="dropdown-item" title="Compartir en Twitter" target="_blank" href="https://twitter.com/share?url={{ uri }}&via=vivienda.com">
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a class="dropdown-item" title="Compartir en WhatsApp" target="_blank" href="https://wa.me/?text=Hola, encontré este servicio en la página vivienda.com {{ uri }}">
                                <i class="fab fa-whatsapp"></i>
                            </a>
                            <a class="dropdown-item" title="Compartir el enlace" (click)="copyLink()">
                                <i class="far fa-copy"></i>
                            </a>
                        </div>
                        <a class="d-inline mx-2 pointer" title="Agregar a favoritos" (click)="savedService('saved')">
                            <img src="{{ relations.saved ? 'https://cdn.vivienda.com/images/icons/fav-selected.png' : 'https://cdn.vivienda.com/images/icons/fav.png' }}">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ngx-image-gallery [images]="images" [conf]="conf" *ngIf="browser"></ngx-image-gallery>
    
    <div class="container mt-3" id="similar-services">
        <div class="row">
            <div class="col-md-12 text-center pt-lg-2">
                <h2><b>Servicios</b> Similares</h2>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-4 col-12" *ngFor="let s of related;">
                        <div class="card-similars card">
                            <div class="row">
                                <div class="col-lg-12">
                                    <a href="{{ s.url }}">
                                        <img src="{{ s.images.length > 0 && getFirstImageFormatT(s.images) ? getFormatImage(getFirstImageFormatT(s.images), '16x9') : 'https://cdn.vivienda.com/images/services/no-disponible.jpg' }}" onerror="this.src = 'https://cdn.vivienda.com/images/services/no-disponible.jpg'" class="w-100 image_service">
                                    </a>
                                </div>
                                <div class="col-12 pl-5 pr-5 pt-3">
                                    <div class="row">
                                        <div class="col-12">
                                            <h5 class="text-semibold">{{ s.name }}</h5>
                                        </div>
                                        <div class="col-12">
                                            <p class="mb-lg-3 text-gray text-thin desc-p mb-1">{{ s.description }}</p>
                                        </div>
                                        <div class="col-12 text-center mb-lg-4 mb-lg-3 mb-2">
                                            <a href="{{ s.url }}" class="btn-yellow-similars btn mt-0">
                                                <p class="text-semibold mb-0">Ver detalle</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="other-service__galery row">
                </div>
            </div>
            <div class="col-12">
                <div class="row d-flex justify-content-center">
                    <div class="col-12 col-lg-5 text-center mx-auto mb-2">
                        <div class="row">
                            <div class="col-lg-6 col-12 p-0">
                                <a href="/servicios/buscador" class="btn-show-more btn mt-2">
                                    Volver a Servicios
                                </a>
                            </div>
                            <div class="col-lg-6 col-12 mt-2">
                                <a href="/servicios/{{ services.slug }}" class="btn-show-more btn">
                                    Ver más
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-json-ld [json]="schema"></ngx-json-ld>

<!-- Modals -->
<!-- Save service -->
<div class="modal fade" id="lists" tabindex="-1" role="dialog" aria-labelledby="lists" aria-hidden="true" *ngIf="session">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <i class="fa fa-times fa-lg text-yellow"></i>
                        </button>
                    </div>
                </div>
                <h4 class="text-bold text-color-black text-center">Escoge la lista a la que vas asociar el servicio</h4>
                <div class="container lista" *ngIf="lists.length > 0">
                    <div class="row" *ngFor="let l of lists; index as i">
                        <div class="col-1 card-body text-center py-0">
                            <input type="radio" name="list" id="list{{ i }}" [(ngModel)]="listSelected" [value]="l.token" (click)="list.name = ''; list.description = ''" class="input-list circular">
                            <label for="list{{ i }}"></label>
                        </div>
                        <div class="col-11 card-body px-0 py-0">
                            <p class="text-bold text-color-black mb-0">{{ l.name }}</p>
                        </div>
                    </div>
                </div>
                <div class="row text-center justify-content-center">
                    <h4 class="text-bold text-color-black text-center">Crea una nueva lista</h4>
                    <div class="col-12">
                        <div class="form-group">
                            <input type="text" placeholder="Nombre" name="listName" id="listName" [(ngModel)]="list.name" class="w-75 px-4" (click)="listSelected = null">
                        </div>
                        <div class="form-group">
                            <textarea class="w-75 px-4 modal-textarea" placeholder="Descripción" [(ngModel)]="list.description" (click)="listSelected = null" cols="9" style="border-radius: 10px;"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <a onclick="return false;" class="bg-yellow text-white py-2 mx-auto text-bold px-3 cursor-pointer" style="width: auto!important;" (click)="savedService()">Guardar</a>
            </div>
        </div>
    </div>
</div>
<!-- Rating Service -->
<div class="modal fade" id="rate-modal" data-backdrop="static" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog" style="margin-top: 5px;">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="resetModal()">
                        <i class="fa fa-times fa-lg text-yellow"></i>
                        </button>
                    </div>
                </div>
                <h3 class="text-center text-color-black text-black">Escribe una reseña</h3>
                <p class="text-center text-medium text-gray text-left mb-1">¡Cuéntanos! ¿Qué te parece el servicio {{ services.name }} de {{ services.profile ? services.profile.name : '' }}?
                </p>
                <form class="row" #formRate="ngForm">
                    <div class="col-12 d-flex flex-row mb-0 justify-content-center" style="font-size: 1.4rem;">
                        <button type="button" (click)="selectRate(i)" class="mx-1 bg-transparent border-0 d-block text-yellow rate-link rate-modal rate-{{ i }}" (mouseover)="rateOver(i - 1)" (mouseout)="removeRateOver()" *ngFor="let i of [1, 2, 3, 4, 5]">
                        <i class="far fa-star"></i>
                        </button>
                    </div>
                    <div class="col-12 d-flex flex-column">
                        <textarea class="mt-3 w-75 mx-auto text-gray text-thin px-3 py-2" ngModel name="rate-comment"
                            id="rate-comment" placeholder="Escribe tu reseña" rows="4"></textarea>
                        <div class="g-recaptcha recaptcha-1 mt-3 mb-0 mx-auto">
                            <re-captcha #captchaRefReview="reCaptcha" siteKey="6LdkKNwUAAAAABGyZqGidUfLfPWk73AuG0u_ylMa"
                            class="g-recaptcha" (resolved)="resolved($event)" required></re-captcha>
                        </div>
                        <button type="button" class="text-bold text-white bg-yellow mx-auto py-2 mt-2"
                            style="width: 100px; border-radius: 10px; border: 0;"
                            (click)="saveRate(formRate)">Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Rating profile -->
<div class="modal fade" id="rate-modal-profile" data-backdrop="static" tabindex="-1" aria-hidden="true" *ngIf="services.profile">
    <div class="modal-dialog" style="margin-top: 5px;">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetModal()">
                            <i class="fa fa-times fa-lg text-yellow"></i>
                        </button>
                    </div>
                </div>
                <h3 class="text-center text-color-black text-black">Escribe una reseña</h3>
                <p class="text-center text-medium text-gray text-left mb-1">¿Qué probabilidad hay de que recomiendes a {{ services.profile.name }}?</p>
                <form class="row" #formRateProfile="ngForm">
                    <div class="col-12 d-flex flex-row mb-0 justify-content-center" style="font-size: 1.4rem;">
                        <button type="button" (click)="selectRate(i + 1, 'profile')" class="mx-1 bg-transparent border-0 d-block text-yellow rate-link rate-modal rate-{{ i + 1 }}profile" (mouseover)="rateOver(i, 'profile')" (mouseout)="removeRateOver('profile')" *ngFor="let i of [0, 1, 2, 3, 4]">
                            <i class="far fa-star"></i>
                        </button>
                    </div>
                    <div class="col-12 d-flex flex-column">
                        <textarea class="mt-3 w-75 mx-auto text-gray text-thin px-3 py-2" ngModel name="rate-comment-profile" id="rate-comment-profile" placeholder="Escribe tu reseña" rows="4"></textarea>
                        <div class="g-recaptcha recaptcha-1 mt-3 mb-0 mx-auto">
                            <re-captcha #captchaRef="reCaptcha" siteKey="6LdkKNwUAAAAABGyZqGidUfLfPWk73AuG0u_ylMa" class="g-recaptcha" (resolved)="resolved($event)" required></re-captcha>
                        </div>
                        <button type="button" class="text-bold text-white bg-yellow mx-auto py-2 mt-2" style="width: 100px; border-radius: 10px; border: 0;" (click)="saveRate(formRateProfile)">Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
