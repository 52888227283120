import { Injectable } from '@angular/core';
import * as Cookies from 'js-cookie';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor() { }

  getProfileItems(): any {
    var user = localStorage.getItem('user');
    return user ? JSON.parse(user) : {};
  }

  completeAnalyticsAction(action: string): string {
    const user = this.getProfileItems();
    return action + ' | ' + Cookies.get('customer') + ' - ' + user.name + ' ' + user.surname;
  }
}
