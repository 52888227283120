import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as Cookies from 'js-cookie';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Vivienda.com';
  browser = false;

  constructor(
    @Inject(PLATFORM_ID) platformId: String,
    public router: Router
  ) {
    this.browser = isPlatformBrowser(platformId);
    if (this.browser) {
      if (!Cookies.get('customer')) {
        this.setCookie('customer', this.uuidv4(), 3650, '/');
      }
      this.setCurrency();
    }
  }

  setCookie(cname, cvalue, exdays, path = '/', domain = '') {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    domain = domain == '' ? '.' + window.location.hostname : domain;
    document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=" + domain + ";path=" + path ;
  }
  setCurrency(){
    let currency = localStorage.getItem('currency');
    if(!currency || currency === 'undefined') localStorage.setItem('currency','COP');
  }

  setCurrentPosition(): void {
  let lat,lng,zoom,loc;
  if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          lat = position.coords.latitude;
          lng = position.coords.longitude;
          zoom = 13;
          loc = { lat: lat, lng: lng, zoom: zoom };
          localStorage.setItem('recent_locations', JSON.stringify(loc));
          var date = new Date();
          this.setCookie('current_position',JSON.stringify(loc),1);
        },
      );
    }
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
