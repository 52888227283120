import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './general-components/home/home.component';
import { ContactComponent } from './general-components/contact/contact.component';
import { AboutComponent } from './general-components/about/about.component';
import { EditorComponent } from './general-components/editor/editor.component';
import { SearchComponent } from './general-components/search/search.component';
import { FindComponent } from './general-components/find/find.component';
import { ServicesSearchComponent } from './general-components/services-search/services-search.component';
import { ServiceIndexComponent } from './general-components/service-index/service-index.component';
import { ServiceComponent } from './general-components/service/service.component';
import { ProfileComponent } from './modules/user/components/profile/profile.component';
import { ServicesDesignComponent } from './general-components/services-design/services-design.component';
import { PolicyComponent } from './general-components/policy/policy.component';
import { TermsComponent } from './general-components/terms/terms.component';
import { SuggestsComponent } from './general-components/suggests/suggests.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'editor/:id/:property', component: EditorComponent },
  { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(response => response.AuthModule) },
  { path: 'propiedad', loadChildren: () => import('./modules/properties/property.module').then(response => response.PropertyModule) },
  { path: 'contactanos', component: ContactComponent },
  { path: 'sugerencias', component: SuggestsComponent },
  { path: 'usuario', loadChildren: () => import('./modules/user/user.module').then(response => response.UserModule)},
  { path: 'aliados', loadChildren: () => import('./modules/allies/allies.module').then(response => response.AlliesModule)},
  { path: 'constructoras', loadChildren: () => import('./modules/construction-companies/construction-companies.module').then(response => response.ConstructionCompaniesModule)},
  { path: 'busqueda', component: SearchComponent },
  { path: 'mapa', component: FindComponent },
  { path: 'servicios', component: ServicesSearchComponent },
  { path: 'servicios/buscador', component: ServiceIndexComponent },
  { path: 'servicios/:service', component: ServiceIndexComponent },
  { path: 'servicios/:service/:subservice', component: ServiceIndexComponent },
  { path: 'servicio/:profile/:service', component: ServiceComponent },
  { path: 'perfil/:slug', component: ProfileComponent },
  { path: 'Servicios', component: ServicesDesignComponent },
  { path: 'terminos-y-condiciones', component: TermsComponent },
  { path: 'politica-de-tratamiento-de-datos-personales', component: PolicyComponent },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
