import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2';
import { ApiConnectionService } from 'src/app/services/api-connection.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenService } from 'src/app/services/auth/token.service';
import {
  GALLERY_CONF,
  GALLERY_IMAGE,
  NgxImageGalleryComponent,
} from 'ngx-image-gallery';
import { RecaptchaComponent } from 'ng-recaptcha';
import { isPlatformBrowser } from '@angular/common';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';

declare const $: any;
declare const gtag: any;

@Component({
  selector: '#DesignModal',
  templateUrl: './modal-propertie.component.html',
  styleUrls: ['./modal-propertie.component.css'],
})
export class ModalPropertyComponent implements OnInit {
  @Input() backURI: string;
  @Input() browser: boolean = false;
  @Output() sendList: any = new EventEmitter();
  @Output() activeSearch: any = new EventEmitter();
  // gallery configuration
  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showImageTitle: false,
    reactToMouseWheel: true,
    showExtUrlControl: false,
    reactToRightClick: true,
    imagePointer: true,
  };
  images: GALLERY_IMAGE[] = [];
  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();
  minDate = new Date();
  property: any = {
    contacts: [],
    images: [],
    options: [],
    nearby_sites: [],
    type_property: {},
  };
  slug: any = '';
  value = '';
  type_offer = '';
  estado = '';
  estrato = '';
  antiquity = '';
  floors = '';
  description = '';
  address: any = '';
  location: any = '';
  private geoCoder;
  searchControl: FormControl;
  locationConfirmed = false;
  head: any = '';
  totalArea = null;
  lat: any = -4.0587;
  lng: any = 7.3244;
  lat2: any = -4.0587;
  lng2: any = 7.3244;
  zoom = 16;
  zone = '';
  city;
  schema: any = {};
  showMap = false;
  imageGroup = [];
  rooms = 1;
  report = '';

  textareaDefault = 'Estoy interesado en esta propiedad';

  whatsapp = {
    value: '',
    accept: false,
    quantity: 0,
  };

  uri: any;
  captcha: any;

  total_area = '';
  total_private = '';

  contacts = [];

  nearby_sites = [];
  characteristics = {
    interiors: [],
    exterior: [],
    common_zones: [],
  };
  characteristicsIndex = [
    { name: 'Interiores', index: 'interiors', identifier: 'interiores' },
    { name: 'Exteriores', index: 'exterior', identifier: 'exteriores' },
    { name: 'Zonas comunes', index: 'common_zones', identifier: 'comunes' },
  ];
  pCharacteristics = {
    interiors: [],
    exterior: [],
    common_zones: [],
  };

  actualImage = {
    position: '',
    index: '',
    image: {},
  };

  relations = {
    favorites: false,
    saved: false,
  };

  chselected: any = {
    ch: '',
    value: '',
    name: '',
    counter: '',
    multiple: [{ name: '' }],
  };
  graciasResponse = false;
  index = 0;
  position = 0;
  session = false;
  @ViewChild('imagesGallery')
  ngxImageGallery: NgxImageGalleryComponent;
  @Input() lists = [];
  @Input() list = {
    name: '',
    description: '',
  };
  @Input() listSelected = null;
  typeSaved = null;

  @ViewChild('captchaRef') public captchaRef: RecaptchaComponent;
  showCaptcha = false;

  related = [];
  imagePosition = 0;
  modal: boolean = true;

  cellphone: string = null;
  mainEmail: string = null;

  toExport: boolean = false;

  blueprints: GALLERY_IMAGE[] = [];
  @ViewChild('blueprintsGallery')
  blueprintsGallery: NgxImageGalleryComponent;

  constructor(
    protected http: ApiConnectionService,
    private title: Title,
    private meta: Meta,
    private router: Router,
    protected authService: AuthService,
    protected tokenService: TokenService,
    private _sanitizer: DomSanitizer,
    protected route: ActivatedRoute,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.session = this.tokenService.isLoggedIn();
    this.browser = isPlatformBrowser(platformId);
    route.params.subscribe((params) => {
      this.slug = params['slug'];
    });
  }
  
  ngOnInit(): void {
    if (!this.property.amount && this.slug) {
      this.modal = false;
      this.getProperty(this.slug);
    }

    $('body').one('mousemove', function (event) {
      $('#collapseContacto').addClass('transition');
    });
  }

  getProperty(slug) {
    this.slug = slug;
    const params = window.location.search;
    this.http.getData('property/details/' + slug + params, {}).subscribe(
      (response) => {
        this.images = [];
        this.property = response.data;
        console.log(this.property);
        
        if (this.property.images.length > 0) {
          for (const image of this.property.images) {
            if (image.type == 'image') {
              this.images.push({
                url: image.cropped ? image.cropped : image.path,
                thumbnailUrl: image.thumbs[0]?.image,
              });
            }
          }
        }
        if (this.property.blueprints.length > 0) {
          for (const image of this.property.blueprints) {
              this.blueprints.push({
                url: image.image,
                thumbnailUrl: image.image,
              });
          }
        }
        this.relations = response.relations;
        this.related = response.related;
        this.loadParams();
        this.setTotalAttributes(response);
        this.head =
          this.property.type_property.type_property +
          ' en ' +
          this.property.type_offer.charAt(0).toUpperCase() +
          this.property.type_offer.slice(1);
        this.head +=
          ' - ' +
          this.property.zone +
          (this.property.city ? ', ' + this.property.city : '');
        this.title.setTitle(this.head + ' | ' + this.title.getTitle());
        this.meta.addTag({ name: 'description', content: this.property.description });
        this.meta.addTag({ name: 'image', content: this.property?.images[0].path || '/assets/img/logo-new.png' });

        // Meta for Google
        this.meta.addTag({ itemprop: 'name', content: this.head + ' | ' + this.title.getTitle() });
        this.meta.addTag({ itemprop: 'description', content: this.property.description });
        this.meta.addTag({ itemprop: 'image', content: this.property?.images[0].path || '/assets/img/logo-new.png' });

        // Meta for Facebook
        this.meta.addTag({ property: 'og:title', content: this.head + ' | ' + this.title.getTitle() });
        this.meta.addTag({ property: 'og:url', content: window.location.href });
        this.meta.addTag({ property: 'og:description', content: this.property.description });
        this.meta.addTag({ property: 'og:image', content: this.property?.images[0].path || '/assets/img/logo-new.png' });

        this.schema = this.buildSchema();

        if (this.browser) {
          Swal.close();
          this.loadDescriptionLength();
          setTimeout(() => {
            $('img').attr(
              'onerror',
              "this.src = 'https://cdn.vivienda.com/images/properties/no-disponible.jpg'"
            );
            $('.analytics-event-tracing').on('click', function () {
              const element = $(this);
              gtag('event', element.data('event'), {
                event_category: element.data('category'),
                event_label: element.data('label'),
              });
            });
          }, 1000);
        }
      },
      (error) => {
        if (error.status == 404) {
          Swal.fire({
            title: 'Este inmueble no se encuentra disponible o no existe',
            text: 'Por favor inténtalo de nuevo',
          }).then(() => {
            window.location.href = '/busqueda';
          });
        } else {
          this.http.parseDefaultHttpResponse(error);
        }
      }
    );
  }

  loadParams() {
    this.lat2 = this.lat = parseFloat(this.property.latitude);
    this.lng2 = this.lng = parseFloat(this.property.longitude);
    this.loadCharacteristics();
    if (this.browser) {
      setTimeout(() => {
        $('#formDetails').remove();
      }, 500);
    }
  }

  setTotalAttributes(response) {
    response.data.areas.forEach((value, index) => {
      if (value.description == 'Área total') {
        this.total_area = this.totalArea = value.area;
      } else if (value.description == 'Área privada') {
        this.total_private = value.area;
      }
    });

    response.data.contacts.forEach((value, index) => {
      value.content.phones.forEach((v, i) => {
        if (v.accept.whatsapp && this.whatsapp.value == '') {
          this.whatsapp.value = v.phone;
          this.whatsapp.accept = true;
          this.whatsapp.quantity += 1;
        }

        if (v?.phone && v.phone?.trim() != '') {
          this.cellphone = v.phone;
        }
      });

      if(value.principal && value.content.email) {
        this.mainEmail = value.content.email;
      }
    });
  }
  buildDescription(): string {
    let description = this.head + ' ';
    this.property.options.forEach((v, i) => {
      v.options.forEach((value, index) => {
        switch (value.config.type) {
          case 'counter':
          case 'simple':
            if (value.config.value && value.config.value !== '') {
              description +=
                (value.config.type == 'simple'
                  ? value.name
                  : value.config.value + ' ' + value.name) + ', ';
            }
            break;
          case 'multiple-list':
            let content = '';
            value.config.items.forEach((val) => {
              if (val.content) {
                content += val.name + ', ';
              }
            });
            if (content !== '') {
              content = content.slice(0, -2);
              description += value.name + ': ' + content + '. ';
            }
            break;
        }
      });
    });
    if (description.slice(description.length - 2) == ', ')
      description = description.slice(0, -2) + ' ';

    description += 'Estrato ' + this.property.estrato;
    description +=
      ' Ubicado en ' +
      this.property.zone +
      (this.property.city ? ', ' + this.property.city : '');
    return description;
  }
  openGallery(index: number = 0) {
    if (this.property.images[0].type == 'video') index--;
    this.ngxImageGallery.open(index);
  }
  openBlueprints(index: number = 0) {
    this.blueprintsGallery.open(index);
  }
  buildSchema(): object {
    let schema = {};
    switch (this.property?.type_property?.slug) {
      case 'finca':
        schema = {
          '@context': 'http://schema.org',
          '@type': 'Land',
          name: this.head,
          description: this.property.description,
          address: {
            '@type': 'PostalAddress',
            streetAddress: this.property.address,
            addressLocality: this.property.zone,
            addressRegion: this.property.city,
          },
          landArea: this.property.areas[0]?.area,
        };
        break;
      case 'casa':
        schema = {
          '@context': 'http://schema.org',
          '@type': 'SingleFamilyResidence',
          name: this.head,
          description: this.property.description,
          address: {
            '@type': 'PostalAddress',
            streetAddress: this.property.address,
            addressLocality: this.property.zone,
            addressRegion: this.property.city,
          },
          floorSize: this.property.areas[0]?.area,
        };
        break;
      case 'edificio':
        schema = {
          '@context': 'http://schema.org',
          '@type': 'ApartmentComplex',
          name: this.head,
          description: this.property.description,
          address: {
            '@type': 'PostalAddress',
            streetAddress: this.property.address,
            addressLocality: this.property.zone,
            addressRegion: this.property.city,
          },
          floorSize: this.property.areas[0]?.area,
        };
        break;
      case 'bodega':
        schema = {
          '@context': 'http://schema.org',
          '@type': 'Warehouse',
          name: this.head,
          description: this.property.description,
          address: {
            '@type': 'PostalAddress',
            streetAddress: this.property.address,
            addressLocality: this.property.zone,
            addressRegion: this.property.city,
          },
          floorSize: this.property.areas[0]?.area,
        };
        break;
      case 'lote':
        schema = {
          '@context': 'http://schema.org',
          '@type': 'Land',
          name: this.head,
          description: this.property.description,
          address: {
            '@type': 'PostalAddress',
            streetAddress: this.property.address,
            addressLocality: this.property.zone,
            addressRegion: this.property.city,
          },
          landArea: this.property.areas[0]?.area,
        };
        break;
      case 'apartamento':
        schema = {
          '@context': 'http://schema.org',
          '@type': 'Apartment',
          name: this.head,
          description: this.property.description,
          address: {
            '@type': 'PostalAddress',
            streetAddress: this.property.address,
            addressLocality: this.property.zone,
            addressRegion: this.property.city,
          },
          floorSize: this.property.areas[0]?.area,
        };
        break;
      case 'local':
        schema = {
          '@context': 'http://schema.org',
          '@type': 'Store',
          name: this.head,
          description: this.property.description,
          address: {
            '@type': 'PostalAddress',
            streetAddress: this.property.address,
            addressLocality: this.property.zone,
            addressRegion: this.property.city,
          },
          floorSize: this.property.areas[0]?.area,
        };
        break;
      case 'consultorio':
        schema = {
          '@context': 'http://schema.org',
          '@type': 'MedicalClinic',
          name: this.head,
          description: this.property.description,
          address: {
            '@type': 'PostalAddress',
            streetAddress: this.property.address,
            addressLocality: this.property.zone,
            addressRegion: this.property.city,
          },
          floorSize: this.property.areas[0]?.area,
        };
        break;
      case 'oficina':
        schema = {
          '@context': 'http://schema.org',
          '@type': 'OfficeBuilding',
          name: this.head,
          description: this.property.description,
          address: {
            '@type': 'PostalAddress',
            streetAddress: this.property.address,
            addressLocality: this.property.zone,
            addressRegion: this.property.city,
          },
          floorSize: this.property.areas[0]?.area,
        };
        break;
        case 'parqueadero':
          schema = {
            '@context': 'http://schema.org',
            '@type': 'ParkingFacility',
            name: this.head,
            description: this.property.description,
            address: {
              '@type': 'PostalAddress',
              streetAddress: this.property.address,
              addressLocality: this.property.zone,
              addressRegion: this.property.city,
            },
            floorSize: this.property.areas[0]?.area,
          };
          break;
    }
    return schema;
  }
  loadDescriptionLength() {
    setTimeout(() => {
      if ($('.desc-p').text().length >= 180) {
        $('.desc-p').text(
          $('.desc-p').text().substr(0, 520).replace(/\s+$/, '') + '...'
        );
        $('#desc-b').removeClass('d-none');
        $('#desc-b').addClass('d-block');
      }
    }, 200);
  }
  loadCharacteristics() {
    this.pCharacteristics = {
      interiors: [],
      exterior: [],
      common_zones: [],
    };
    this.property.options.forEach((v, i) => {
      v.options.forEach((value, index) => {
        switch (value.config.type) {
          case 'counter':
            if (
              value.config.value != undefined &&
              value.config.value != null &&
              value.config.value.trim() !== ''
            ) {
              this.pCharacteristics[v.type_option].push({
                type: 'counter',
                name: value.name,
                image: value.image,
                quantity: value.config.value,
              });
            }
            break;
          case 'multiple-list':
            let items = [];
            value.config.items.forEach((val) => {
              if (
                val.content != undefined &&
                val.content != null &&
                val.content
              ) {
                items.push(val);
              }
            });
            if (items.length > 0) {
              this.pCharacteristics[v.type_option].push({
                type: 'multiple-list',
                name: value.name,
                slug: value.slug,
                image: value.image,
                items: items,
              });
            }
            break;
          case 'simple':
            if (
              value.config.value != undefined &&
              value.config.value != null &&
              value.config.value
            ) {
              this.pCharacteristics[v.type_option].push({
                type: 'simple',
                name: value.name,
                image: value.image,
              });
            }
            break;
        }
      });
    });
  }
  closeModal() {
    window.history.pushState({}, 'Busqueda | Vivienda.com', this.backURI);
    this.images = [];
    this.property = {
      contacts: [],
      images: [],
      options: [],
      nearby_sites: [],
      type_property: {},
    };
    this.cellphone = '';
    this.mainEmail = '';
    this.activeSearch.emit(false);
    $('#DesignModal').modal('hide');
  }
  verMasContent() {
    let desc = document.getElementById('descriptionContent');
    if (desc.classList.contains('less')) {
      desc.classList.add('more');
      desc.classList.remove('less');
      desc.parentElement.classList.add('more');
      desc.parentElement.classList.remove('less');
    } else {
      desc.parentElement.classList.remove('more');
      desc.parentElement.classList.add('less');
      desc.classList.add('less');
      desc.classList.remove('more');
    }
  }
  buildGoogleMapsImageUri(): string {
    return (
      'https://maps.googleapis.com/maps/api/staticmap?center="' +
      this.lat +
      ',' +
      this.lng +
      '"&maptype=hybrid&zoom=16&size=635x270&markers=color:red|' +
      this.lat +
      ',' +
      this.lng +
      '&key=AIzaSyDwGE_CeEzo4IjbZD_YoQdNBqN1jKachWo'
    );
  }
  openGoogleMapsUri(): void {
    let code = (Math.random() + 1).toString(36).substring(7);
    sessionStorage.setItem(code, this.property.slug);
    let url = '/mapa?lat=' + this.lat + '&lng=' + this.lng + '&code=' + code;

    location.href = url;
  }
  collapseChar(id) {
    let col = document.getElementById(id);
    let chars = ['interiors', 'exterior', 'common_zones'];
    $('#' + id).collapse('show');
  }
  savedProperty(type = '') {
    Swal.fire({
      title: 'Espera un momento...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      onBeforeOpen: () => Swal.showLoading(),
    });

    if (type !== '') {
      this.typeSaved = type;
      this.http
        .postData(
          'property/saved',
          {
            property: this.property.slug,
            type: type,
            attach: this.relations[type],
          },
          { auth: true }
        )
        .subscribe(
          (response) => {
            Swal.fire({
              title: response.message,
              icon: 'success',
            }).then(() => {
              this.relations[type] = !this.relations[type];
            });
          },
          (error) => {
            Swal.close();
            if (error.status == 401) {
              Swal.fire({
                title:
                  'Para poder realizar esta acción por favor inicia sesión',
              }).then(() => {
                this.authService.logOut(false);
                let redirecTo =
                  '/auth/login?redirectTo=/propiedad/' + this.property.slug;
                console.log(redirecTo);

                window.location.href = redirecTo;
              });
            } else if (error.status == 400) {
              this.sendList.emit(error.error.data);
              $('#lists').modal('show');
              this.lists = error.error.data;
              if (this.lists.length <= 0)
                this.list.name = 'Lista N° ' + (this.lists.length + 1);
            }
          }
        );
    } else {
      let list = null;
      if (this.lists.length > 0) {
        if (!this.listSelected && this.list.name.trim() == '') {
          console.log(this.list);

          Swal.fire({
            title:
              'Por favor selecciona una lista o llena el formulario para crear una nueva',
            icon: 'warning',
          });
          return;
        }
        list = this.listSelected ? this.listSelected : this.list;
      } else {
        if (this.list.name.trim() == '') {
          Swal.fire({
            title: 'Por favor asigna un nombre a tu lista',
            icon: 'warning',
          });
          return;
        }
        list = this.list;
      }

      this.http
        .postData(
          'property/saved',
          {
            list: list,
            property: this.property.slug,
            type: this.typeSaved,
            format: 'fromlist',
          },
          { auth: true }
        )
        .subscribe(
          (response) => {
            Swal.fire({
              title: response.message,
              icon: 'success',
            }).then(() => {
              this.relations[this.typeSaved] = !this.relations[this.typeSaved];
              this.list = {
                name: '',
                description: '',
              };
              this.typeSaved = null;
              this.lists = [];
              this.listSelected = null;
              $('#lists').modal('hide');
            });
          },
          (error) => {
            Swal.fire({
              title: 'En estos momentos no podemos atender tu solicitud',
              icon: 'error',
            });
          }
        );
    }
  }

  reportProperty() {
    $('#report').modal('show');
  }

  copyLink() {
    var text = window.location.href;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: '¡Copiado!',
      showConfirmButton: false,
      timer: 500,
    });
  }

  generateUri(): string {
    return location.href;
  }

  checkVideo(url) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  galleryImageChanged(index) {
    let gallery = document.getElementById('imagesGallery');
    let container = gallery.getElementsByClassName('image');
    console.dir(container);
    container[index].classList.remove('after');
    container[index].classList.remove('before');
    if (index == 0 || (index > 0 && index < container.length - 1)) {
      container[index + 1].innerHTML =
        "<img  src='" +
        this.images[index + 1].url +
        "' style='cursor: pointer; border-radius: 3px;'>";
      container[index + 1].classList.add('after');
      container[index + 1].classList.remove('before');
    }
    if (index == container.length - 1 || (index > 0 && container.length > 0)) {
      container[index - 1].innerHTML =
        "<img  src='" +
        this.images[index - 1].url +
        "' style='cursor: pointer; border-radius: 3px;'>";
      container[index - 1].classList.add('before');
      container[index - 1].classList.remove('after');
    }
    for (let c of container) {
      if (
        c != container[index - 1] &&
        c != container[index] &&
        c != container[index + 1]
      ) {
        c.classList.remove('after');
        c.classList.remove('before');
        c.removeAttribute('style');
        c.setAttribute(
          'style',
          'top: 0px; bottom: 0px;display: none !important;'
        );
      } else {
        c.removeAttribute('style');
        c.setAttribute('style', 'top: 0px; bottom: 0px');
      }
    }
  }

  contact(f): void {
    if (f.form.status == 'VALID') {
      Swal.fire({
        title: 'Estamos notificando tu solicitud...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => Swal.showLoading(),
      });
      f.value['property'] = this.property.slug;
      f.value['g-recaptcha-response'] = this.captcha;
      this.http
        .postData('contact/property', f.value, { auth: false })
        .subscribe(
          (response: any) => {
            gtag('event', 'Envío de formulario de contacto de inmueble', {
              event_category:
                'Boton de enviar del formulario en el detalle del inmueble',
              event_label: window.location.pathname,
            });
            $('#collapseContacto').toggleClass('transition');
            if ($('#contactModalMobile').hasClass('show'))
              $('#contactModalMobile').modal('toggle');
            Swal.close();
            f.form.reset();
            // $("#designmodal > div").remove();
            // $("#designmodal").addClass('p-5');
            // $("#designmodal").html(`
            //   <div class="container text-center justify-content-center p-5">
            //     <h1 style="font-size:10rem;color: #0096a3;"><i class="far fa-check-circle"></i></h1>
            //     <h2>¡Gracias por contactarte con el propietario del inmueble ${this.property.type_property.type_property} en ${this.property.type_offer} ${this.property.zone}, ${this.property.city}!</h2>
            //     <a class="d-block text-bold btn-pink text-white text-center m-auto" href="${window.location.href}">Regresar</a>
            //   </div>
            // `);
            this.graciasResponse = true;
            history.pushState(
              {},
              'Gracias por contactarte',
              `${window.location.pathname}/gracias`
            );
            gtag('event', 'page_view', {
              page_title: 'Gracias por contactarte',
              page_location: window.location.href,
              page_path: window.location.pathname,
            });
          },
          (error) => {
            if (error.status == 401) {
              Swal.fire({
                title:
                  'Para poder continuar por favor confirma que no esres un robot',
              }).then(() => {
                this.showCaptcha = true;
              });
            } else if (error.status == 400) {
              Swal.fire({
                title: 'Asegúrate que pusiste un correo válido',
              }).then(() => {
                this.captchaRef.reset();
              });
            } else {
              Swal.fire({
                title:
                  'En este momento no podemos atender tu solictud, por favor inténtalo más tarde',
              });
            }
          }
        );
    }
  }

  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
  }

  exportPdf() {
    this.toExport = true;
    setTimeout(() => {
      let element = document.getElementById('pdfContainer') as HTMLElement;

    if (!element) {
      console.error('Element not found!');
      return;
    }

    html2canvas(element, {}).then(canvas=>{

      const imgData = canvas.toDataURL('image/jpeg');
      const imgWidth = 210; // mm (A4 width)
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      

      var pdf = new jspdf.jsPDF('p', 'pt', [canvas.width, canvas.height]);
      var pdfWidth = pdf.internal.pageSize.getWidth();
      var pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);

      //const pdf = new jspdf.jsPDF('p', 'mm', 'a4');

      //pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
      pdf.save('Inmueble' + '.pdf');
    });
    
    this.toExport = false;
    }, 500);
  }
}
