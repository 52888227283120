<footer>
  <div class="container">
    <div class="row">
      <div class="col-md-2">
        <a href="/" title="Vivienda">
          <img width="150px" src="//cdn.vivienda.com/images/logo-footer.png" class="img-fluid mb-3" alt="Vivienda | Compra o vende un inmueble" />
        </a>
        <p class="mt-2">
          <a class="mr-1" href="https://www.instagram.com/vivienda_com_/" target="_blank">
            <img src="//cdn.vivienda.com/images/icons/instagram.png" width="25" />
          </a>
          <a href="https://www.facebook.com/Vivienda-101695609007329" target="_blank" >
            <img src="//cdn.vivienda.com/images/icons/facebook.png" width="25" style="position: relative; top: 1px" />
          </a>
        </p>
      </div>
      <div class="col-md-3">
        <h6>Enlaces rapidos</h6>
        <p>
          <a href="/"> Home </a>
          <br />
          <a href="/about"> ¿Quiénes somos? </a>
          <br />
          <a [routerLink]="['/busqueda']"> Encuentra tu inmueble </a>
          <br />
          <a href="/propiedad/registrar"
          onclick="gtag('event', 'btn_publicar_footer', {'event_label' : 'btn_publicar_footer_label'});"
          > Publica tu inmueble </a>
          <br />
          <a href="https://learning.vivienda.com/"> Centro de aprendizaje </a>
          <br />
          <a href="/contactanos"> Contáctanos </a>
        </p>
      </div>
      <div class="col-md-3">
        <h6>Accesos</h6>
        <p>
          <a href="/auth/login" *ngIf="!session"> Iniciar sesión </a>
          <br>
          <a href="/auth/register" *ngIf="!session" 
          onclick="gtag('event', 'btn_registrate_footer', {'event_label' : 'btn_registrate_footer_label'});"> Regístrate </a>
          <br>
          <a href="/servicios/buscador"> Servicios para tu inmueble </a>
          <br>
          <a href="/terminos-y-condiciones"> Terminos y condiciones </a>
          <br>
          <a href="/politica-de-tratamiento-de-datos-personales">
            Política de tratamiento de datos personales
          </a>
        </p>
      </div>
      <div class="col-md-4">
        <h6>Navegación del sitio</h6>
        <p>
          <a href="/">Vivienda</a>
        </p>
      </div>
    </div>
  </div>
</footer>

<div class="container-fluid bg-gray d-none">
  <div class="container pt-4 pb-3">
    <div class="row foot">
      <div class="col-md-3 logo">
        <img
          src="https://cdn.vivienda.com/images/logo-vertical-beta.png"
          class="img-fluid logo mb-md-2"
        />
        <div class="social" class="mb-3 mt-3 mb-md-1">
          <a href="https://www.facebook.com/Vivienda-101695609007329">
            <img
              src="https://cdn.vivienda.com/images/icons/facebook-foot.png"
              class="mr-2"
              width="25px"
            />
          </a>
          <a class="my-0" href="https://www.instagram.com/vivienda_com_/ ">
            <img
              src="https://cdn.vivienda.com/images/icons/instagram-foot.png"
              class="mr-2"
              width="25px"
            />
          </a>
        </div>
      </div>
      <div class="col-md-3">
        <h5 class="text-semibold mb-3 mb-md-4">Enlaces rápidos</h5>
        <a href="/">
          <p class="text-thin text-dark mb-0 mt-2">Home</p>
        </a>
        <a href="/about">
          <p class="text-thin text-dark mb-0 mt-2">¿Quiénes somos?</p>
        </a>
        <a [routerLink]="['/busqueda']">
          <p class="text-thin text-dark mb-0 mt-2">Encuentra tu inmueble</p>
        </a>
        <a href="/propiedad/registrar">
          <p class="text-thin text-dark mb-0 mt-2">Publica tu inmueble</p>
        </a>

        <a href="https://learning.vivienda.com/">
          <p class="text-thin text-dark mb-0 mt-2">Centro de aprendizaje</p>
        </a>
        <a href="/contactanos">
          <p class="text-thin text-dark mb-0 mt-2">Contáctanos</p>
        </a>
      </div>
      <div class="col-md-3">
        <h5 class="text-semibold mb-3 mb-md-4 mt-4 mt-md-0">Accesos</h5>
        <a href="/auth/login" *ngIf="!session">
          <p class="text-thin text-dark mb-0 mt-2">Iniciar sesión</p>
        </a>
        <a href="/auth/register" *ngIf="!session">
          <p class="text-thin text-dark mb-0 mt-2">Regístrate</p>
        </a>
        <a href="/servicios/buscador">
          <p class="text-thin text-dark mb-0 mt-2">
            Servicios para tu inmueble
          </p>
        </a>
        <a href="/terminos-y-condiciones">
          <p class="text-thin text-dark mb-0 mt-2">Terminos y condiciones</p>
        </a>
        <a href="/politica-de-tratamiento-de-datos-personales">
          <p class="text-thin text-dark mb-0 mt-2">
            Política de tratamiento de datos personales
          </p>
        </a>
      </div>
      <div class="col-md-3">
        <h5 class="text-semibold mb-3 mb-md-4 mt-4 mt-md-0">Información</h5>
        <p class="text-thin text-dark mb-0 mt-2">Dirección:</p>
        <p class="text-thin text-dark mb-0 mt-2">PBX:</p>
        <p class="text-thin text-dark mb-0 mt-2">Correo:</p>
      </div>
    </div>
  </div>
</div>
