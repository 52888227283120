import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as Cookies from 'js-cookie';

@Injectable({
  providedIn: 'root'
})

export class ApiConnectionService {
  API_URI: any;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  };

  constructor(
    private http: HttpClient
  ) {
    this.API_URI = environment.api_uri;
  }

  getData(uri: String, headers: any = null): any {
    headers = headers.auth !== undefined ? this.buildHttpOptions() : this.httpOptions;
    return this.http.get(this.API_URI + uri, headers);
  }

  postData(uri: String, body: Object, headers: any = null): any {
    headers = headers.auth !== undefined ? this.buildHttpOptions() : this.httpOptions;
    return this.http.post(this.API_URI + uri, body, headers);
  }

  putData(uri: String, body: Object, headers: any = null): any {
    headers = headers.auth !== undefined ? this.buildHttpOptions() : this.httpOptions;
    return this.http.put(this.API_URI + uri, body, headers);
  }

  deleteData(uri: String, body: Object): any {
    return this.http.delete(this.API_URI + uri, body);
  }

  buildHttpOptions(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + Cookies.get('vivienda_com_auth_token'),
      })
    };
  }

  parseDefaultHttpResponse(response): void {
    if (document !== undefined) {
      let messages = '';
      // tslint:disable-next-line:forin
      for (const i in response.data) {
        // tslint:disable-next-line:forin
        for (const j in response.data[i]) {
          messages += '<span class="text-danger">' + response.data[i][j] + '</span></br>';
        }
      }
      Swal.fire({
        title: response.message,
        html: messages,
        icon: 'error'
      });
    }
  }
}
