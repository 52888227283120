<div class="container-fluid bg-grey pl-4 pt-5 pb-4">
  <div class="inmueble-selected d-none">
    <div class="row">
      <div class="col-4 pl-0 pr-2">
        <a href="">
          <img
            src="https://cdn.vivienda.com/images/properties/inm1.jpg"
            alt=""
            class="w-100 ml-auto"
          />
        </a>
      </div>
      <div class="col-8 px-0">
        <h5 class="text-bold text-color-black mb-0">$520.000.000 COP</h5>
        <h5 class="text-regular text-color-black mb-1">Gratamira Bogotá</h5>
        <h6 class="text-regular text-color-black mb-0">Apartamento en venta</h6>
        <h5 class="text-bold text-gray mb-0">
          3 <span class="text-regular" style="font-size: 1rem">Hab </span>2
          <span class="text-regular" style="font-size: 1rem">Baños </span>280
          <span class="text-regular" style="font-size: 1rem">mt2</span>
        </h5>
      </div>
    </div>
  </div>
  <a
    id="mapa-btn"
    onclick="$(this).addClass('collapse');$('#inmuebles-btn').removeClass('collapse');$('#inmuebles').addClass('collapse');$('#mapa').addClass('d-block')"
    class="shadow bg-white text-yellow text-bold text-center position-fixed map-btn py-2 w-50 d-md-none"
    (click)="showMap = !showMap"
  >
    <img
      src="https://cdn.vivienda.com/images/icons/map-icon.png"
      alt=""
      class="mr-2"
    />
    Mapa
  </a>
  <a
    id="inmuebles-btn"
    onclick="$(this).addClass('collapse');$('#mapa-btn').removeClass('collapse');$('#inmuebles').removeClass('collapse');$('#mapa').removeClass('d-block')"
    class="collapse shadow bg-white text-yellow text-bold text-center position-fixed map-btn py-2 w-50 d-md-none"
    (click)="showMap = !showMap"
  >
    <img
      src="https://cdn.vivienda.com/images/icons/list-icon.png"
      alt=""
      class="mr-2"
    />
    Lista
  </a>
  <!-- barra de filtro de busqueda de inmuebles -->
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-12 float-right">
        <div class="row d-none d-lg-flex">
          <div class="col-lg-6 col-12">
            <div class="row">
              <div class="col-lg-3 col-12 p-1">
                <div class="dropdown">
                  <button
                    class="d-flex justify-content-between align-items-center btn-yellow-outline text-white text-semibold text-left w-100 px-1 p-80"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <p class="mb-0 w-100 text-bold p-2">Oferta</p>
                    <span class="float-right">
                      <i class="fas fa-chevron-down mt-1"></i>
                    </span>
                  </button>
                  <div class="dropdown-menu p-2">
                    <div class="row no-close">
                      <div class="col-12">
                        <label
                          for="arriendoc-search"
                          class="text-color-black float-left h6"
                          >Arrendar</label
                        >
                        <input
                          type="radio"
                          name="arriendoc-search"
                          id="arriendoc-search"
                          [checked]="arriendo"
                          class="circular red"
                          (click)="changeButton('arriendo')"
                        />
                        <label
                          for="arriendoc-search"
                          class="float-right"
                        ></label>
                      </div>
                      <div class="col-12">
                        <label
                          for="nuevo-search"
                          class="text-color-black float-left h6"
                          >Comprar</label
                        >
                        <input
                          type="radio"
                          name="nuevo-search"
                          id="nuevo-search"
                          [checked]="venta"
                          class="circular blue"
                          (click)="changeButton('venta')"
                        />
                        <label for="nuevo-search" class="float-right"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-12 p-1">
                <div class="dropdown">
                  <button
                    class="d-flex justify-content-between align-items-center btn-yellow-outline text-white text-semibold text-left w-100 px-1 p-80"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <p class="mb-0 w-100 text-bold p-2">Tipo Inmueble</p>
                    <span class="float-right">
                      <i class="fas fa-chevron-down mt-1"></i>
                    </span>
                  </button>
                  <div class="dropdown-menu p-2" style="width: 350px">
                    <div class="row no-close">
                      <div
                        class="col-md-4 no-close"
                        *ngFor="let tp of type_properties"
                      >
                        <input
                          type="checkbox"
                          name="filter{{ tp.slug }}"
                          id="filter{{ tp.slug }}"
                          class="tipo no-close"
                          [(ngModel)]="tipoInmueble[tp.slug]"
                        />
                        <label
                          for="filter{{ tp.slug }}"
                          (click)="register()"
                          class="text-center"
                        >
                          <img
                            src="{{ tp.image }}"
                            class="img-fluid mx-auto no-close"
                          />
                          <hr class="mt-0 mb-1" />
                          <p class="p-70 text-center mb-0">
                            {{ tp.type_property }}
                          </p>
                        </label>
                      </div>
                    </div>
                    <div class="row pt1 text-center justify-content-center">
                      <a
                        class="btn-yellow text-white d-block w-50 text-semibold text-center py-2 p-70 cursor-pointer"
                        onclick="$('a.tipo-inmueble').click();"
                      >
                        Hecho
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-12 p-1">
                <div class="dropdown">
                  <button
                    class="d-flex justify-content-between align-items-center btn-yellow-outline text-white text-semibold text-left w-100 px-1 p-80"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <p class="mb-0 float-left text-bold p-2 w-100">Precio</p>
                    <span class="float-right">
                      <i class="fas fa-chevron-down mt-1"></i>
                    </span>
                  </button>
                  <div class="dropdown-menu p-2" style="width: 350px">
                    <div class="row no-close">
                      <div
                        class="col no-close"
                        [formGroup]="sliderFormPriceRange"
                        *ngIf="tipoOferta != 'venta'"
                      >
                        <p
                          class="text-medium text-color-black p-90 mb-4 pb-3 no-close"
                        >
                          <span class="float-left no-close">Desde</span>
                          <span class="float-right no-close">Hasta</span>
                        </p>
                        <ngx-slider
                          [(value)]="price.min"
                          [(highValue)]="price.max"
                          [options]="optionsPriceRange"
                          formControlName="sliderControlPriceRange"
                          (userChangeEnd)="onUserChangeEnd()"
                          *ngIf="browser"
                        >
                        </ngx-slider>
                      </div>

                      <div
                        class="col no-close"
                        [formGroup]="sliderFormPriceRangeVenta"
                        *ngIf="tipoOferta == 'venta'"
                      >
                        <p
                          class="text-medium text-color-black p-90 mb-4 pb-3 no-close"
                        >
                          <span class="float-left no-close">Desde</span>
                          <span class="float-right no-close">Hasta</span>
                        </p>
                        <ngx-slider
                          [(value)]="priceVenta.min"
                          [(highValue)]="priceVenta.max"
                          [options]="optionsPriceRangeVenta"
                          formControlName="sliderControlPriceRangeVenta"
                          (userChangeEnd)="onUserChangeEnd('priceVenta')"
                          *ngIf="browser"
                        >
                        </ngx-slider>
                      </div>
                    </div>

                    <div class="row pt1 text-center justify-content-center">
                      <a
                        class="bg-yellow text-white d-block w-50 text-bold text-center py-2 p-70 cursor-pointer"
                        onclick="$('#precio').removeClass('show');"
                      >
                        Hecho
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-12 p-1">
                <div class="dropdown">
                  <button
                    class="d-flex justify-content-between align-items-center btn-yellow-outline text-white text-semibold text-left w-100 px-1 p-80"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <p class="mb-0 p-2 text-bold w-100">Área</p>
                    <span class="float-right">
                      <i class="fas fa-chevron-down mt-1"></i>
                    </span>
                  </button>
                  <div class="dropdown-menu py-2 px-3" style="width: 350px">
                    <div class="row no-close">
                      <div
                        class="col no-close"
                        [formGroup]="sliderFormAreaRange"
                      >
                        <p
                          class="text-medium text-color-black p-90 mb-4 pb-3 no-close"
                        >
                          <span class="float-left no-close">Desde</span>
                          <span class="float-right no-close">Hasta</span>
                        </p>
                        <ngx-slider
                          [(value)]="area.min"
                          [(highValue)]="area.max"
                          [options]="optionsAreaRange"
                          formControlName="sliderControlAreaRange"
                          (userChangeEnd)="onUserChangeEnd('area')"
                          *ngIf="browser"
                        >
                        </ngx-slider>
                      </div>
                    </div>

                    <div class="d-flex pt-1 text-center justify-content-center">
                      <a
                        class="bg-white text-yellow d-block w-50 text-bold text-center py-2 p-70 cursor-pointer"
                        *ngIf="area.active"
                        (click)="onUserChangeEnd('area')"
                      >
                        Borrar
                      </a>
                      <a
                        class="bg-yellow text-white d-block w-50 text-bold text-center py-2 p-70 cursor-pointer"
                        onclick="$('#area').removeClass('show');"
                      >
                        Hecho
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="row">
              <div class="col-12 col-lg-4 col-xl-3 p-1">
                <div class="dropdown">
                  <button
                    class="d-flex justify-content-between align-items-center btn-yellow-outline text-white text-semibold text-left w-100 px-1 p-80"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                  <p class="mb-0 p-2 text-bold w-100">Estado del inmueble</p>
                  <span class="float-right">
                    <i class="fas fa-chevron-down mt-1"></i>
                  </span>
                  </button>
                <div
                  class="dropdown-menu p-2"
                  style="width: 350px"
                >
                  <div class="row text-center align-items-center">
                    <div class="col-6">
                      <input
                        type="radio"
                        [(ngModel)]="offer"
                        [value]="'nuevo'"
                        name="offerw"
                        id="offernw"
                        class="sestrato no-close"
                        (click)="register()"
                      />
                      <label
                        for="offernw"
                        style="background-image: none"
                        class="w-100"
                        >Nuevo</label
                      >
                    </div>
                    <div class="col-6">
                      <input
                        type="radio"
                        [(ngModel)]="offer"
                        [value]="'usado'"
                        name="offerw"
                        id="offeruw"
                        class="sestrato no-close"
                        (click)="register()"
                      />
                      <label
                        for="offeruw"
                        style="background-image: none"
                        class="w-100"
                        >Usado</label
                      >
                    </div>
                  </div>
                  <div class="d-flex pt1 text-center justify-content-center">
                    <a
                      class="btn-white d-block w-50 text-semibold text-center py-2 p-70 cursor-pointer"
                      *ngIf="offer != null"
                      (click)="offer = null; register()"
                    >
                      Borrar
                    </a>
                    <a
                      class="btn-yellow text-white d-block w-50 text-semibold text-center py-2 p-70 cursor-pointer"
                      onclick="$('#oferta').removeClass('show');"
                    >
                      Hecho
                    </a>
                  </div>
                </div>
                </div>
              </div>
              <div class="col-12 col-lg-3 col-xl-3 p-1">
                <a
                  href="#filtros"
                  data-toggle="modal"
                  data-backdrop="static"
                  data-keyboard="false"
                  class="d-inline-block btn-yellow-outline text-white text-semibold p-2 px-1 w-100 p-80"
                >
                  <p class="mb-0 float-left text-bold">Más filtros</p>
                  <span class="float-right ml-auto">
                    <i class="fas fa-plus mt-1"></i>
                  </span>
                </a>
              </div>
              <div class="col-12 col-lg-5 col-xl-6 p-1">
                <div class="input-group col-md-12">
                  <input
                    type="search"
                    id="keyword"
                    [(ngModel)]="keyword"
                    name="keyword"
                    class="form-control py-2"
                    autocomplete="off"
                    placeholder="Palabra clave"
                    (keyup.enter)="register()"
                  />
                  <span
                    class="input-group-append"
                    style="z-index: 1; border: 1px solid #ced4da"
                  >
                    <button
                      class="btn btn-outline-secondary pointer"
                      type="button"
                      (click)="register()"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row d-block d-lg-none">
          <div class="col-12">
            <div class="col-12 p-0">
              <div class="row">
                <a
                  href="#filt-mobile"
                  onclick="$('#mas-filt-mobile').removeClass('show')"
                  data-toggle="collapse"
                  class="col-10 d-inline-block btn-yellow-outline text-white text-semibold p-2 px-1 w-100 p-80"
                >
                  <p class="mb-0 float-left text-bold">Filtrar</p>
                  <span class="float-right">
                    <i class="fas fa-chevron-down mt-1"></i>
                  </span>
                </a>
                <a
                  href="#buscadornav"
                  onclick="$('#buscadornav').toggleClass('d-none')"
                  data-toggle="collapse"
                  class="col-2 d-inline-block p-2 px-1 w-100 p-80 text-center"
                >
                  <i class="fas fa-search mr-2" style="font-size: 0.9rem"></i>
                </a>
              </div>
            </div>
            <div class="collapse submenu w-100 no-close" id="filt-mobile">
              <div class="row text-right no-close">
                <div class="col no-close">
                  <i
                    class="fas fa-times fa-lg text-yellow"
                    onclick="$('#filt-mobile').removeClass('show')"
                  ></i>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <a
                    href="#tip-submenu"
                    data-toggle="collapse"
                    class="d-block text-color-black text-black text-left my-2 no-close"
                  >
                    Tipo inmueble
                    <span class="float-right no-close">
                      <i class="fas fa-chevron-down no-close"></i>
                    </span>
                  </a>
                  <div id="tip-submenu" class="collapse no-close">
                    <div
                      class="row pt-0 no-close"
                      *ngFor="let tp of type_properties"
                    >
                      <div class="col px-4 no-close">
                        <p class="mb-0 float-left no-close">
                          {{ tp.type_property }}
                        </p>
                        <div class="float-right no-close">
                          <input
                            type="checkbox"
                            name="{{ tp.slug }}1"
                            id="{{ tp.slug }}1"
                            class="check-yellow no-close"
                            [(ngModel)]="tipoInmueble[tp.slug]"
                          />
                          <label for="{{ tp.slug }}1">
                            <i class="fas fa-check fa-lg no-close"></i>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    href="#precio"
                    data-toggle="collapse"
                    class="d-block text-color-black text-black text-left my-2 no-close"
                  >
                    Precio
                    <span class="float-right no-close">
                      <i class="fas fa-chevron-down no-close"></i>
                    </span>
                  </a>
                  <div id="precio" class="collapse card px-4 py-2 no-close">
                    <div class="row no-close">
                      <div
                        class="col no-close"
                        [formGroup]="sliderFormPriceRange"
                        *ngIf="tipoOferta != 'venta'"
                      >
                        <p
                          class="text-medium text-color-black p-90 mb-4 pb-3 no-close"
                        >
                          <span class="float-left no-close">Desde</span>
                          <span class="float-right no-close">Hasta</span>
                        </p>
                        <ngx-slider
                          [(value)]="price.min"
                          [(highValue)]="price.max"
                          [options]="optionsPriceRange"
                          formControlName="sliderControlPriceRange"
                          (userChangeEnd)="onUserChangeEnd()"
                          *ngIf="browser"
                        >
                        </ngx-slider>
                      </div>

                      <div
                        class="col no-close"
                        [formGroup]="sliderFormPriceRangeVenta"
                        *ngIf="tipoOferta == 'venta'"
                      >
                        <p
                          class="text-medium text-color-black p-90 mb-4 pb-3 no-close"
                        >
                          <span class="float-left no-close">Desde</span>
                          <span class="float-right no-close">Hasta</span>
                        </p>
                        <ngx-slider
                          [(value)]="priceVenta.min"
                          [(highValue)]="priceVenta.max"
                          [options]="optionsPriceRangeVenta"
                          formControlName="sliderControlPriceRangeVenta"
                          (userChangeEnd)="onUserChangeEnd('priceVenta')"
                          *ngIf="browser"
                        >
                        </ngx-slider>
                      </div>
                    </div>
                  </div>
                  <a
                    href="#area"
                    data-toggle="collapse"
                    class="d-block text-color-black text-black text-left my-2 no-close"
                  >
                    Área
                    <span class="float-right no-close">
                      <i class="fas fa-chevron-down no-close"></i>
                    </span>
                  </a>
                  <div id="area" class="collapse card px-4 py-2 no-close">
                    <div class="row no-close">
                      <div
                        class="col no-close"
                        [formGroup]="sliderFormAreaRange"
                      >
                        <p
                          class="text-medium text-color-black p-90 mb-4 pb-3 no-close"
                        >
                          <span class="float-left no-close">Desde</span>
                          <span class="float-right no-close">Hasta</span>
                        </p>
                        <ngx-slider
                          [(value)]="area.min"
                          [(highValue)]="area.max"
                          [options]="optionsAreaRange"
                          formControlName="sliderControlAreaRange"
                          (userChangeEnd)="onUserChangeEnd('area')"
                          *ngIf="browser"
                        >
                        </ngx-slider>
                      </div>
                    </div>
                  </div>
                  <a
                    href="#estrato"
                    data-toggle="collapse"
                    class="d-block text-color-black text-black text-left my-2 no-close"
                  >
                    Estrato
                    <span class="float-right no-close">
                      <i class="fas fa-chevron-down no-close"></i>
                    </span>
                  </a>
                  <div id="estrato" class="collapse no-close">
                    <div class="row text-center no-close">
                      <div
                        class="col-2 px-2 no-close"
                        *ngFor="let i of [1, 2, 3, 4, 5, '5+']"
                      >
                        <input
                          type="radio"
                          [(ngModel)]="estrato"
                          [value]="i"
                          name="estratom"
                          id="{{ i == '+5' ? '5more' : i }}m"
                          class="sestrato no-close"
                        />
                        <label
                          for="{{ i == '+5' ? '5more' : i }}m"
                          style="background-image: none"
                          class="w-100 d-flex align-items-center justify-content-center no-close"
                          >{{ i }}</label
                        >
                      </div>
                    </div>
                  </div>

                  <a
                    href="#ofertam"
                    data-toggle="collapse"
                    class="d-block text-color-black text-black text-left my-2 no-close"
                  >
                    Tipo de oferta
                    <span class="float-right no-close">
                      <i class="fas fa-chevron-down no-close"></i>
                    </span>
                  </a>

                  <div id="ofertam" class="collapse no-close">
                    <div class="row text-center">
                      <div class="col-6 px-2">
                        <input
                          type="radio"
                          [(ngModel)]="offer"
                          [value]="'nuevo'"
                          name="offer"
                          id="offern"
                          class="sestrato no-close"
                          (click)="register()"
                        />
                        <label
                          for="offern"
                          style="background-image: none"
                          class="w-100 d-flex align-items-center justify-content-center"
                          >Nuevo</label
                        >
                      </div>
                      <div class="col-6 px-2">
                        <input
                          type="radio"
                          [(ngModel)]="offer"
                          [value]="'usado'"
                          name="offer"
                          id="offeru"
                          class="sestrato no-close"
                          (click)="register()"
                        />
                        <label
                          for="offeru"
                          style="background-image: none"
                          class="w-100 d-flex align-items-center justify-content-center"
                          >Usado</label
                        >
                      </div>
                    </div>
                    <!--<div class="row pt1 text-center justify-content-center">
                                            <a class="bg-white text-yellow d-block w-50 text-bold text-center py-2 p-70 cursor-pointer" style="color: rgba(247, 191, 80, 1);" *ngIf="offer != null" (click)="offer = null;register()">
                                                Borrar
                                            </a>
                                            <a class="btn-yellow text-white d-block w-50 text-bold text-center py-2 p-70 cursor-pointer" onclick="$('#oferta').removeClass('show');">
                                                Hecho
                                            </a>
                                        </div>-->
                  </div>
                  <div class="row mt-3">
                    <div class="col-6">
                      <a
                        (click)="clearFilters()"
                        class="d-block w-100 text-yellow text-center text-bold py-2"
                        style="color: rgba(247, 191, 80, 1)"
                        >Borrar todo</a
                      >
                    </div>
                    <div class="col-6">
                      <a
                        (click)="register()"
                        class="d-block w-100 text-white bg-yellow text-center text-bold py-2"
                        >Aplicar</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- resultados de la busquedas de inmuebles -->
  <div class="row" id="resultados">
    <div class="col-12 col-lg-7 pr-md-3" id="mapa">
      <div class="w-100 position-relative">
        <div class="bisel-map"></div>
        <app-map
          [lat]="lat"
          [lng]="lng"
          [zoom]="zoom"
          *ngIf="showMap"
          [properties]="properties"
          [typeProperties]="tipoInmueble"
          [offer]="{ arriendo: arriendo, venta: venta }"
          (propertiesChange)="setProperties($event)"
        ></app-map>
      </div>
    </div>
    <div class="col-12 col-lg-5" id="inmuebles">
      <!--
            <div class="row">
                <div [class]="slug ? 'col-10' : 'col-11'">
                    <h5 class="text-regular mb-0 d-inline-block" style="font-size:.8rem">{{ tiposInmuebles }} {{
                        tipoOferta ? 'en ' + tipoOferta : '' }} {{ city ? '- ' + city : '' }} | {{ quantity }}
                        Resultados</h5>
                </div>
                <div class="col-2" *ngIf="slug">
                    <a (click)="saveSeeker()" onclick="return false;" href="#" class="d-inline-block text-center"
                        id="saveSeeker" style="width: 30px!important;">
                        <img src="https://cdn.vivienda.com/images/icons/busqueda-guardar.png" width="40px">
                    </a>
                </div>
            </div>
             -->
      <div
        *ngIf="loader"
        class="spinner-border"
        style="
          top: 40%;
          left: 40%;
          width: 120px;
          height: 120px;
          position: absolute;
          z-index: 50;
        "
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <div class="row">
        <div
          class="col-12 col-md-4 col-lg-6 col-xl-4 mt-1 px-2"
          *ngFor="let property of properties; index as i"
        >
          <div class="card">
            <div class="box position-relative">
              <a href="javascript:void(0);" (click)="propertyModal(property)">
                <div
                  class="ribbon ribbon-top-left pointer"
                  *ngIf="property.featured"
                >
                  <span>Destacado</span>
                </div>
                <img
                  src=""
                  onerror="this.src = 'https://cdn.vivienda.com/images/properties/no-disponible.jpg'"
                  class="w-100 d-block d-md-none"
                />

                <img
                  [src]="property.images"
                  onerror="this.src = 'https://cdn.vivienda.com/images/properties/no-disponible.jpg'"
                  class="w-100 d-none d-md-block object-fit-contain"
                  width="165"
                  height="165"
                />
              </a>
              <div class="image-length px-1 d-none" *ngIf="property.images.length > 0">
                <i class="fas fa-image"></i>
                <h6 class="mb-0">
                  {{ property.images.length }}
                </h6>
              </div>
            </div>
            <div class="p-2 pb-0 py-0 col-12">
              <h2
                class="mr-1 mt-0 mb-1 type_offer text-color-black d-flex align-items-center typepro"
              >
                <span>
                  <span class="text-regular">
                    <a href="/propiedad/{{ property.slug }}">
                      {{
                        property.type_property.type_property == "Apartamento"
                          ? "Apto."
                          : property.type_property.type_property
                      }}
                      en
                      {{
                        property.type_offer.charAt(0).toUpperCase() +
                          property.type_offer.slice(1)
                      }}
                    </a>
                  </span>
                </span>
              </h2>
              <h1
                class="card-title title-inmueble my-0 d-flex align-items-center"
              >
                <img
                  src="//cdn.vivienda.com/images/icons/gps.png"
                  class="mr-1"
                />
                <a
                  href="javascript:void(0);"
                  (click)="propertyModal(property)"
                  class="text-color-black"
                >
                  <span>
                    <span class="d-block text-regular overflow">
                      {{ property.zone
                      }}{{ property.city ? ", " + property.city : "" }}
                    </span>
                    <h6 class="text-bold font-weight-bold">
                      ${{ property.amount }} {{ property.currency }}
                    </h6>
                  </span>
                </a>
              </h1>
              <h2 class="precio text-color-black d-flex align-items-center">
                <span>
                  <span
                    *ngIf="false; else h2Empty"
                    class="d-block text-regular overflow"
                  >
                    <span
                      *ngFor="let opt of property.options; let key = index"
                      class="d-inline-block"
                      [class]="(key + 1) % 2 == 0 ? 'mr-2' : 'mr-1'"
                      style="text-transform: uppercase"
                    >
                      {{ opt.value }}
                      {{
                        opt.name == "Baños"
                          ? "BA "
                          : opt.name == "mt2"
                          ? "M2 "
                          : opt.name
                      }}
                      {{ key % 2 != 0 ? " | " : " " }}
                    </span>
                  </span>
                  <ng-template #h2Empty>
                    <ng-container>
                      <span class="d-block text-regular overflow">
                        <span class="d-inline-block"></span>
                      </span>
                    </ng-container>
                  </ng-template>
                </span>
              </h2>
              <a
                href="javascript:void(0);"
                (click)="propertyModal(property)"
                class="d-block btn-yellow py-2 showMore"
              >
                Detalle <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 text-center pb-3" *ngIf="next">
        <a
          class="btn btn-yellow text-white text-bold p-90"
          (click)="loadMoreParams()"
          >Cargar más resultados</a
        >
      </div>
    </div>
  </div>
</div>

<!--Modals-->
<div
  class="modal fade"
  id="filtros"
  tabindex="-1"
  role="dialog"
  aria-labelledby="filtros"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    <div class="modal-content" style="height: 600px">
      <div class="modal-body" id="inmuebles">
        <button
          type="button"
          class="close float-right"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="fas fa-times text-yellow"></i
          ></span>
        </button>

        <h5 class="text-color-black text-black mt-5">Estrato</h5>
        <div class="row text-center px-2">
          <div class="col-md-2 col-2 px-2" *ngFor="let i of [1, 2, 3, 4, 5, 6]">
            <input
              type="radio"
              [(ngModel)]="estrato"
              [value]="i"
              name="estrato"
              id="{{ i }}"
              class="sestrato no-close"
            />
            <label for="{{ i }}" style="background-image: none">{{ i }}</label>
          </div>
        </div>

        <h5 class="text-color-black text-black mt-5">Antigüedad</h5>
        <div class="dropdown no-close">
          <a
            class="text-gray text-thin dropdown-toggle text-limit no-close px-1"
            href="#antiguedad-select"
            data-toggle="collapse"
            id="dropdownMenuLink"
            title=""
          >
            <span class="float-left">{{ timeselected }}</span>
            <i
              class="float-right mt-2 fas fa-chevron-down fa-custom text-gray"
            ></i>
          </a>
          <div
            class="dropdown-menu busqueda-tipo p-3 w-100 border-0"
            aria-labelledby="dropdownMenuLink"
            id="antiguedad-select"
          >
            <div class="row">
              <div class="col-12">
                <h6 class="text-color-black float-left text-regular">
                  Entre 0 a 5 años
                </h6>
                <input
                  type="radio"
                  name="time"
                  id="0-5"
                  [(ngModel)]="antiquity"
                  class="no-close circular"
                  value="[0, 5]"
                  [value]="[0, 5]"
                />
                <label
                  for="0-5"
                  (click)="timeselected = 'Entre 0 a 5 años'"
                  class="float-right no-close"
                ></label>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h6 class="text-color-black float-left text-regular">
                  Entre 5 a 10 años
                </h6>
                <input
                  type="radio"
                  name="time"
                  id="5-10"
                  [(ngModel)]="antiquity"
                  class="no-close circular"
                  value="[5, 10]"
                  [value]="[5, 10]"
                />
                <label
                  for="5-10"
                  (click)="timeselected = 'Entre 5 a 10 años'"
                  class="float-right no-close"
                ></label>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h6 class="text-color-black float-left text-regular">
                  Entre 10 a 20 años
                </h6>
                <input
                  type="radio"
                  name="time"
                  id="10-20"
                  [(ngModel)]="antiquity"
                  class="no-close circular"
                  value="[10, 20]"
                  [value]="[10, 20]"
                />
                <label
                  for="10-20"
                  (click)="timeselected = 'Entre 10 a 20 años'"
                  class="float-right no-close"
                ></label>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h6 class="text-color-black float-left text-regular">
                  Más de 20 años
                </h6>
                <input
                  type="radio"
                  name="time"
                  id="21"
                  [(ngModel)]="antiquity"
                  class="no-close circular"
                  value="[21]"
                  [value]="[21]"
                />
                <label
                  for="21"
                  (click)="timeselected = 'Más de 20 años'"
                  class="float-right no-close"
                ></label>
              </div>
            </div>
            <div class="row justify-content-center">
              <a
                class="btn-yellow text-white d-block w-50 text-bold text-center py-2 p-70 cursor-pointer no-close"
                onclick="$('#antiguedad-select').removeClass('show')"
                >Aplicar</a
              >
            </div>
          </div>
        </div>
        <!--<div class="row pt-2">
                    <div class="col px-1">
                        <div class="form-group">
                            <select [(ngModel)]="antiquity" id="antiquity" name="antiquity" class="form-control w-100">
                                <option [value]="''">Selecciona</option>
                                <option [value]="[0, 5]">Entre 0 a 5 años</option>
                                <option [value]="[6, 10]">Entre 5 a 10 años</option>
                                <option [value]="[11, 20]">Entre 10 a 20 años</option>
                                <option [value]="[21]">Más de 20 años</option>
                            </select>

                        </div>
                    </div>
                </div>-->

        <h5 class="text-color-black text-black mt-3">
          Características generales
        </h5>
        <p class="p-90 mt-3">
          <img
            src="https://cdn.vivienda.com/images/icons/habitacion.png"
            width="25px"
          />
          <span class="ml-4">Número de habitaciones</span>
        </p>
        <div class="row text-center px-2">
          <div
            class="col-md-2 col-2 px-2"
            *ngFor="let i of [1, 2, 3, 4, 5, '5+']; index as index"
          >
            <input
              type="checkbox"
              [(ngModel)]="habitaciones[index]"
              [value]="i"
              name="{{ i == '5+' ? '5more' : i }}habMoreFiltersModal"
              id="{{ i == '5+' ? '5more' : i }}habMoreFiltersModal"
              class="radio-yellow"
            />
            <label
              for="{{ i == '5+' ? '5more' : i }}habMoreFiltersModal"
              class="w-100"
              >{{ i }}</label
            >
          </div>
        </div>
        <p class="p-90">
          <img
            src="https://cdn.vivienda.com/images/icons/baño.png"
            width="25px"
          />
          <span class="ml-4">Número de baños</span>
        </p>
        <div class="row text-center px-2">
          <div
            class="col-md-2 col-2 px-2"
            *ngFor="let i of [1, 2, 3, 4, 5, '5+']; index as index"
          >
            <input
              type="checkbox"
              [(ngModel)]="banos[index]"
              [value]="i"
              name="{{ i == '5+' ? '5more' : i }}bathMoreFiltersModal"
              id="{{ i == '5+' ? '5more' : i }}bathMoreFiltersModal"
              class="radio-yellow"
            />
            <label
              for="{{ i == '5+' ? '5more' : i }}bathMoreFiltersModal"
              class="w-100"
              >{{ i }}</label
            >
          </div>
        </div>
        <p class="p-90">
          <img
            src="https://cdn.vivienda.com/images/icons/visitantes.png"
            width="25px"
          />
          <span class="ml-4">Número de parqueaderos</span>
        </p>
        <div class="row text-center px-2">
          <div
            class="col-md-2 col-2 px-2"
            *ngFor="let i of [1, 2, 3, 4, 5, '5+']; index as index"
          >
            <input
              type="checkbox"
              [(ngModel)]="parking[index]"
              [value]="i"
              name="{{ i == '5+' ? '5more' : i }}parkingMoreFiltersModal"
              id="{{ i == '5+' ? '5more' : i }}parkingMoreFiltersModal"
              class="radio-yellow"
            />
            <label
              for="{{ i == '5+' ? '5more' : i }}parkingMoreFiltersModal"
              class="w-100"
              >{{ i }}</label
            >
          </div>
        </div>

        <h5 class="text-color-black text-black mt-3">
          Características generales
        </h5>
        <div class="row" *ngFor="let ch of characteristics; index as i">
          <div class="col px-4">
            <p class="mb-0 float-left">{{ ch.name }}</p>
            <div class="float-right">
              <input
                type="checkbox"
                name="{{ ch.slug }}"
                id="{{ ch.slug }}"
                [(ngModel)]="characteristics[i].checked"
                class="check-yellow"
              />
              <label for="{{ ch.slug }}">
                <i class="fas fa-check fa-lg"></i>
              </label>
            </div>
          </div>
        </div>

        <h5 class="text-color-black text-black mt-3">Sitios cercanos</h5>
        <div class="row text-center mt-3">
          <div
            class="col-md-6 col-6"
            *ngFor="let ns of nearby_sites; index as i"
          >
            <input
              type="checkbox"
              name="{{ ns.slug }}"
              id="{{ ns.slug }}"
              [(ngModel)]="nearby_sites[i].checked"
              class="large-check"
            />
            <label for="{{ ns.slug }}">
              <i class="fas fa-check text-yellow position-absolute"></i>
              <p class="mb-0 p-80 text-yellow">{{ ns.name }}</p>
            </label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-12 row text-center">
          <div class="col-md-6 col-6">
            <a
              (click)="resetModalFilters()"
              class="w-100 text-yellow text-semibold d-block py-2 cursor-pointer"
              data-dismiss="modal"
              >Borrar Todo</a
            >
          </div>
          <div class="col-md-6 col-6">
            <a
              data-dismiss="modal"
              (click)="register()"
              class="w-100 text-white btn-yellow text-semibold d-block py-2 cursor-pointer"
              >Aplicar</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Modals-->
<div
  #DesignModal
  class="modal full-modal"
  id="DesignModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="DesignModal"
  aria-hidden="true"
  [backURI]="currentUri"
  (sendList)="lists = $event"
  (activeSearch)="disableSearch = $event"
  [lists]="lists"
  [list]="list"
  [listSelected]="listSelected"
></div>
<!-- Lista de deseos modal -->
<div
  class="modal fade"
  id="lists"
  tabindex="-1"
  role="dialog"
  aria-labelledby="lists"
  aria-hidden="true"
  *ngIf="session"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content p-0">
      <div class="modal-body p-0" style="overflow-y: auto; height: auto">
        <div class="row">
          <div class="col-12">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times fa-lg text-yellow"></i>
            </button>
          </div>
        </div>
        <h4 class="text-bold text-color-black text-center">
          Escoge la lista a la que vas asociar el inmueble
        </h4>
        <div class="container lista" *ngIf="lists.length > 0">
          <div class="row" *ngFor="let l of lists; index as i">
            <div class="col-1 card-body text-center py-0">
              <input
                type="radio"
                name="list"
                id="list{{ i }}"
                [(ngModel)]="listSelected"
                [value]="l.token"
                (click)="list.name = ''; list.description = ''"
                class="input-list circular"
              />
              <label for="list{{ i }}"> </label>
            </div>
            <div class="col-11 card-body px-0 py-0">
              <p class="text-bold text-color-black mb-0">{{ l.name }}</p>
            </div>
          </div>
        </div>
        <div class="row text-center justify-content-center">
          <h4 class="text-bold text-color-black text-center">
            Crea una nueva lista
          </h4>
          <div class="col-12">
            <div class="form-group">
              <!--<label for="listName">
								<span class="text-thin">Nombre</span>
							</label>-->
              <input
                type="text"
                placeholder="Nombre"
                name="listName"
                id="listName"
                [(ngModel)]="list.name"
                class="w-75 px-4"
                (click)="listSelected = null"
              />
            </div>
            <div class="form-group">
              <!--<label for="listName">
								<span class="text-thin">Descripción</span>
							</label>-->
              <textarea
                class="w-75 px-4 modal-textarea"
                placeholder="Descripción"
                [(ngModel)]="list.description"
                (click)="listSelected = null"
                cols="9"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a
          onclick="return false;"
          class="bg-yellow text-white py-1 mx-auto text-bold px-3 cursor-pointer"
          (click)="modalHijo.savedProperty()"
          >Guardar</a
        >
      </div>
    </div>
  </div>
</div>
<!-- Rportar inmueble modal -->
<div
  class="modal fade"
  id="report"
  tabindex="-1"
  role="dialog"
  aria-labelledby="report"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-header pb-1"></div>
    <div class="modal-content">
      <div class="modal-body pt-0" style="height: auto; overflow-y: auto">
        <div id="step1">
          <h4 class="text-black text-color-black text-center">
            ¿Por cuál motivo quieres<br />reportar este inmueble?
          </h4>
          <div class="px-5 mt-5">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="text-thin text-gray mb-0 float-left">
                Inmueble vendido/arrendado
              </h5>
              <input
                type="radio"
                id="vendido"
                name="reporte"
                class="circular"
              />
              <label
                for="vendido"
                class="m-0 ml-auto pointer"
                (click)="report = 'Inmueble vendido/arrendado'"
              ></label>
            </div>
            <div class="d-flex justify-content-center mb-3">
              <h5 class="text-thin text-gray mb-0 float-left">
                Publicación duplicada
              </h5>
              <input
                type="radio"
                id="duplicado"
                name="reporte"
                class="circular"
              />
              <label
                for="duplicado"
                class="m-0 ml-auto pointer"
                (click)="report = 'Publicación duplicada'"
              ></label>
            </div>
            <div class="d-flex justify-content-center mb-3">
              <h5 class="text-thin text-gray mb-0 float-left">
                Posible fraude
              </h5>
              <input type="radio" id="fraude" name="reporte" class="circular" />
              <label
                for="fraude"
                class="m-0 ml-auto pointer"
                (click)="report = 'Posible fraude'"
              ></label>
            </div>
            <div class="d-flex justify-content-center mb-3">
              <h5 class="text-thin text-gray mb-0 float-left">
                Ubicación errónea
              </h5>
              <input
                type="radio"
                id="erronea"
                name="reporte"
                class="circular"
              />
              <label
                for="erronea"
                class="m-0 ml-auto pointer"
                (click)="report = 'Ubicación errónea'"
              ></label>
            </div>
            <div class="d-flex justify-content-center mb-3">
              <h5 class="text-thin text-gray mb-0 float-left">
                Error en fotos
              </h5>
              <input type="radio" id="fotos" name="reporte" class="circular" />
              <label
                for="fotos"
                class="m-0 ml-auto"
                (click)="report = 'Error en fotos'"
              ></label>
            </div>
            <div class="d-flex justify-content-center mb-3">
              <h5 class="text-thin text-gray mb-0 float-left">
                Error en teléfono de contacto
              </h5>
              <input
                type="radio"
                id="telefono"
                name="reporte"
                class="circular"
              />
              <label
                for="telefono"
                class="m-0 ml-auto pointer"
                (click)="report = 'Error en teléfono de contacto'"
              ></label>
            </div>
            <div class="d-flex justify-content-center mb-3">
              <h5 class="text-thin text-gray mb-0 float-left">
                Precio diferente al publicado
              </h5>
              <input type="radio" id="precio" name="reporte" class="circular" />
              <label
                for="precio"
                class="m-0 ml-auto pointer"
                (click)="report = 'Precio diferente al publicado'"
              ></label>
            </div>
            <div class="d-flex justify-content-center mb-3">
              <h5 class="text-thin text-gray mb-0 float-left">
                Área diferente a la publicación
              </h5>
              <input type="radio" id="area" name="reporte" class="circular" />
              <label
                for="area"
                class="m-0 ml-auto pointer"
                (click)="report = 'Área diferente a la publicación'"
              ></label>
            </div>
            <div class="d-flex justify-content-center mb-3">
              <h5 class="text-thin text-gray mb-0 float-left">
                Contenido ofensivo
              </h5>
              <input
                type="radio"
                id="ofensivo"
                name="reporte"
                class="circular"
              />
              <label
                for="ofensivo"
                class="m-0 ml-auto pointer"
                (click)="report = 'Contenido ofensivo'"
              ></label>
            </div>
            <div class="d-flex justify-content-center mb-2">
              <h5 class="text-thin text-gray mb-0 float-left">Otro motivo</h5>
              <input type="radio" id="otro" name="reporte" class="circular" />
              <label
                for="otro"
                class="m-0 ml-auto pointer"
                (click)="report = 'Otro motivo'"
              ></label>
            </div>
          </div>
          <div class="text-center my-3">
            <button
              type="button"
              class="text-yellow pointer"
              data-dismiss="modal"
              aria-label="Close"
            >
              Cancelar
            </button>
            <a
              class="bg-yellow text-white py-2 mx-auto text-bold px-3 pointer"
              style="width: 150px"
              *ngIf="report && report !== '' && report.trim() !== ''"
              onclick="$('#step1').toggleClass('d-none');$('#step2').toggleClass('d-none');"
              >Continuar</a
            >
          </div>
        </div>
      </div>
      <div
        id="step2"
        class="d-none"
        *ngIf="report && report !== '' && report.trim() !== ''"
      >
        <h4 class="text-black text-color-black text-center px-3">
          Reportar inmueble por {{ report }}
        </h4>
        <div class="px-5 mt-5">
          <input
            type="email"
            id="report-modal-mail"
            name="report-modal-mail"
            class="w-100 px-3"
            placeholder="Correo electrónico"
          />
          <input
            type="text"
            id="report-modal-phone"
            name="report-modal-phone"
            inputmode="numeric"
            class="w-100 px-3"
            placeholder="Teléfono de contacto"
          />
          <textarea
            name="report-modal-comment"
            id="report-modal-comment"
            cols="30"
            rows="4"
            class="form-control"
            placeholder="Escribe un comentario adicional o detalles sobre tu reporte"
          ></textarea>
          <div class="g-recaptcha recaptcha-1 my-0 mx-auto">
            <re-captcha
              #captchaRefModalReport="reCaptcha"
              (resolved)="resolved($event)"
              siteKey="6LdkKNwUAAAAABGyZqGidUfLfPWk73AuG0u_ylMa"
              class="g-recaptcha"
              required
            ></re-captcha>
          </div>
        </div>
        <div class="row py-5 px-5 text-center">
          <div class="col-6">
            <a
              class="d-block text-yellow py-2 w-100 text-bold pointer"
              onclick="$('#step1').toggleClass('d-none');$('#step2').toggleClass('d-none');"
              >Volver</a
            >
          </div>
          <div class="col-6">
            <a
              class="d-block bg-yellow text-white py-2 w-100 text-bold pointer"
              (click)="reportProperty()"
              >Reportar</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Sugerencias -->
<div
  class="modal fade"
  id="suggestModalLong"
  tabindex="-1"
  role="dialog"
  aria-labelledby="suggestModalLongTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header py-2 px-3">
        <h6 class="modal-title" id="suggestModalLongTitle">
          <i class="fas fa-envelope-open-text"></i> Buzón de sugerencias
        </h6>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="p-3 text-center">
        <h5 class="mb-3">Ayudanos a mejorar.</h5>
        <h5 class="mb-3">
          Si tienes comentarios o sugerencias, puedes dejarlos
        </h5>
        <div class="pt-1">
          <a
            href="/sugerencias"
            class="btn btn-yellow btn-rounded font-weight-bold px-3"
            style="background-color: #ff9c00"
            >¡Aquí!</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
