import { Component, OnInit, Input } from '@angular/core';
import { ApiConnectionService } from 'src/app/services/api-connection.service';
import { ActivatedRoute, Router } from '@angular/router';

import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.css']
})
export class PdfComponent implements OnInit {
  @Input() property: any = []
  @Input() image: any = []
  lat: any = -4.0587;
  lng: any = 7.3244;

  total_area = '';
  total_private = '';
  totalArea = null;

  slug: any = '';
  qrslug: any;
  mapImg: any = '';

  counterImg:number = 0;

  imagesof: any = [];

  constructor(
    protected http: ApiConnectionService,
    protected route: ActivatedRoute,
  ) {
    route.params.subscribe((params) => {
      this.slug = params['slug'];
    });
  }

  ngOnInit(): void {
    let nav = document.getElementsByTagName('app-navbar');
    nav[0].remove();
    let foot = document.getElementsByTagName('app-footer');
    foot[0].remove();

    this.http.getData('property/details/' + this.slug, {}).subscribe(
      (response) => {
        console.log(response.data);
        this.property = response.data;
        for (let i = 0; i < response.data.images.length; i++) {
          this.getBase64ImageFromUrl(response.data.images[i]?.path)
            .then(result => {
              this.imagesof.push({'path': result, 'order': i});
            })
            .catch(err => console.error(err));

          this.counterImg++;
        }
        setTimeout(() => {
          this.imagesof.sort(function(a, b){return a.order - b.order});
          }, 1000);
         
        this.lat = parseFloat(this.property.latitude);
        this.lng = parseFloat(this.property.longitude);
        this.mapImg = this.buildGoogleMapsImageUri();
        this.qrslug = window.location.href.replace('pdf/', '');
        this.buildArea();

        
        if(this.counterImg == response.data.images.length || this.counterImg == 10){
          setTimeout(() => {
            this.exportPdf();          
          }, 1000);
        }
      });
  }

  buildArea() {
    this.property.areas?.forEach((value, index) => {
      if (value.description == 'Área total') {
        this.total_area = this.totalArea = value.area;
      } else if (value.description == 'Área privada') {
        this.total_private = value.area;
      }
    });
  }

  buildGoogleMapsImageUri(): string {
    return (
      'https://maps.googleapis.com/maps/api/staticmap?center="' +
      this.lat +
      ',' +
      this.lng +
      '"&maptype=hybrid&zoom=16&size=1000x500&markers=color:red|' +
      this.lat +
      ',' +
      this.lng +
      '&key=AIzaSyDwGE_CeEzo4IjbZD_YoQdNBqN1jKachWo'
    );
  }

  countOp(validate: any) {
    let resultadoContar = 0;
    this.property.options.forEach((op: any) => {
      if (op.type_option == validate) {
        op.options.forEach(characteristic => {
          if (characteristic.config.value == true ||
            characteristic.config.items ||
            (characteristic.config.value != false &&
              characteristic.config.value != undefined &&
              characteristic.config.value != null &&
              characteristic.config.value.trim() != '')) {
            resultadoContar++;
          }
        });
      }
    });

    return resultadoContar > 0 ? true : false;
  }

  exportPdf() {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });

      let element = document.getElementById('pdfContainer') as HTMLElement;

      if (!element) {
        console.error('Element not found!');
        return;
      }

      html2canvas(element, { useCORS: true }).then(canvas => {
        const imgData = canvas.toDataURL('image/jpeg');
        const imgWidth = 210; // mm (A4 width)
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        var pdf = new jspdf.jsPDF('p', 'pt', [canvas.width, canvas.height]);
        var pdfWidth = pdf.internal.pageSize.getWidth();
        var pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(this.property.type_property?.type_property + '_en_' + this.property.type_offer + '_en_Vivienda-com.pdf');

        setTimeout(() => {
          window.close();
        }, 500);
      });
    }, 1000);

    console.log(this.imagesof);
  }

  async getBase64ImageFromUrl(oldimageUrl) {
    var imageUrl = oldimageUrl.replace("https:", 'http:');
    var res = await fetch(imageUrl);
    var blob = await res.blob();
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

}
