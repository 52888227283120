<div class="container pt-4">
  <div class="row">
    <div class="col-12">
      <h2>POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES VIVIENDA.COM</h2>

      <h3>INTRODUCCIÓN</h3>

      Vivienda.com, es una empresa de carácter privada, constituida como persona
      jurídica, ordenada para la ejecución y cumplimiento de todas las funciones
      propias reconocidas en el código de comercio y el decreto 898 de 202,
      adicionalmente, se acoge a todas las normas nacionales sobre la protección
      de datos, tales como:
      <ol>
        <li>
          La ley Estatutaria 1581 de 2012, por la cual se dictan disposiciones
          generales con respecto a la protección de datos personales, tiene como
          objeto desarrollar el derecho constitucional que tienen todas las
          personas a conocer, actualizar y rectificar la información que se haya
          recogido sobre ellas en bases de datos o archivos, y los demás
          derechos, libertades y garantías constitucionales a que se refiere el
          artículo 15 de la constitución política; así como el derecho a la
          información consagrada en el artículo 20 de la misma.
          <br />
          Los principios y disposiciones de la ley 1581 de 2012 serán aplicables
          a los datos personales registrados en cualquier base de datos que los
          haga susceptibles de tratamiento por parte de 5G.
          <br />
          El régimen de protección de datos personales contemplados en
          mencionada ley aplicará al tratamiento de datos personales efectuado
          en el territorio colombiano.
        </li>
        <li>Constitución política de Colombia, Artículos 15, 20.</li>
        <li>
          Ley Estatutaria 1266 de 2008- Disposiciones generales del hábeas data
          y se regula el manejo de la información contenida en bases de datos
          personales, en especial la financiera, crediticia, comercial, de
          servicios y la proveniente de terceros países.
        </li>
        <li>
          Decreto 1377 de 2013- Reglamenta parcialmente la Ley 1581 de 2012.
        </li>
        <li>Decreto 886 de 2014- Registro Nacional de Bases de Datos</li>
      </ol>

      <h3>1. DEFINICIONES</h3>

      <p>
        Las siguientes definiciones, permiten la correcta y apropiada
        interpretación de la Ley 1581 de 2012, y la ejecución adecuada de la
        presente política:
      </p>

      <ul>
        <li>
          a) Autorización: Consentimiento previo, expreso e informado del
          Titular para llevar a cabo el Tratamiento de datos personales;
        </li>
        <li>
          b) Aviso de privacidad: Documento físico, electrónico o en cualquier
          otro formato generado por el Responsable que se pone a disposición del
          Titular para el tratamiento de sus datos personales. En el Aviso de
          Privacidad se comunica al Titular la información relativa a la
          existencia de las políticas de tratamiento de información que le serán
          aplicables, la forma de acceder a las mismas y la finalidad del
          tratamiento que se pretende dar a los datos personales;
        </li>
        <li>
          c) Base de Datos: Conjunto organizado de datos personales que sea
          objeto de Tratamiento;
        </li>
        <li>
          d) Dato personal: Cualquier información vinculada o que pueda
          asociarse a una o varias personas naturales determinadas o
          determinables;
        </li>
        <li>
          e) Dato público: Es el dato calificado como tal según los mandatos de
          la ley o de la Constitución Política y aquel que no sea semiprivado,
          privado o sensible. Son públicos, entre otros, los datos relativos al
          estado civil de las personas, a su profesión u oficio, a su calidad de
          comerciante o de servidor público y aquellos que puedan obtenerse sin
          reserva alguna. Por su naturaleza, los datos públicos pueden estar
          contenidos, entre otros, en registros públicos, documentos públicos,
          gacetas y boletines oficiales;
        </li>
        <li>
          f) Dato privado: Es el dato que por su naturaleza íntima o reservada
          sólo es relevante para el titular;
        </li>
        <li>
          g) Datos sensibles: Se entiende por datos sensibles aquellos que
          afectan la intimidad del Titular o cuyo uso indebido puede generar su
          discriminación, tales como aquellos que revelen el origen racial o
          étnico, la orientación política, las convicciones religiosas o
          filosóficas, la pertenencia a sindicatos, organizaciones sociales, de
          derechos humanos o que promueva intereses de cualquier partido
          político o que garanticen los derechos y garantías de partidos
          políticos de oposición, así como los datos relativos a la salud, a la
          vida sexual y los datos biométricos;
        </li>
        <li>
          h) Encargado del Tratamiento: Persona natural o jurídica, pública o
          privada, que por sí misma o en asocio con otros, realice el
          Tratamiento de datos personales por cuenta del Responsable del
          Tratamiento;
        </li>
        <li>
          i) Responsable del Tratamiento: Persona natural o jurídica, pública o
          privada, que por sí misma o en asocio con otros, decida sobre la base
          de datos y/o el Tratamiento de los datos;
        </li>
        <li>
          j) Titular: Persona natural cuyos datos personales sean objeto de
          Tratamiento;
        </li>
        <li>
          k) Tratamiento: Cualquier operación o conjunto de operaciones sobre
          datos personales, tales como la recolección, almacenamiento, uso,
          circulación o supresión de los mismos.
        </li>
        <li>
          l) Datos De Menores De Edad: El suministro de los datos personales de
          menores de edad es facultativo y debe realizarse con autorización de
          los padres de familia o representantes legales del menor.
        </li>
      </ul>
      <p>
        Requisitos especiales para el Tratamiento de datos personales de niños
        niñas y adolescentes. El Tratamiento de datos personales de niños, niñas
        y adolescentes está prohibido, excepto cuando se trate de datos de
        naturaleza pública, de conformidad con lo establecido en el artículo 7
        de la Ley 1581 de 2012 y cuando dicho Tratamiento cumpla con los
        siguientes parámetros y requisitos:
      </p>
      <ol>
        <li>
          1. Que responda y respete el interés superior de los niños, niñas y
          adolescentes.
        </li>
        <li>2. Que se asegure el respeto de sus derechos fundamentales.</li>
      </ol>
      <p>
        Cumplidos los anteriores requisitos, el representante legal del niño,
        niña o adolescente otorgará la autorización previo ejercicio del menor
        de su derecho a ser escuchado, opinión que será valorada teniendo en
        cuenta la madurez, autonomía y capacidad para entender el asunto. (Hecho
        que puede ser determinado por el departamento y/o psicólogo (a) del
        colegio y/o por la autoridad competente en el asunto). Todo Responsable
        y Encargado involucrado en el Tratamiento de los datos personales de
        niños, niñas y adolescentes, deberá velar por el uso adecuado de los
        mismos. Para este fin deberán aplicarse los principios y obligaciones
        establecidos en la Ley 1581 de 2012 y el decreto 1377 de 2013.
      </p>
      <p>
        La familia y la sociedad deben velar porque los Responsables y
        Encargados del Tratamiento de los datos personales de los menores de
        edad cumplan las obligaciones establecidas en la Ley 1581 de 2012 y el
        decreto 1377 de 2013.
      </p>
      <p>
        • Video de Vigilancia: Toda persona que ingrese a las instalaciones de
        VIVIENDA.COM, podrá ser video grabada por cámaras de seguridad, según se
        permita por la ley. Las imágenes captadas por las cámaras del sistema de
        circuito cerrado de televisión serán utilizadas para su seguridad y de
        las personas que nos visitan. Nuestra entidad cuenta, con monitoreo 24
        horas vía circuito cerrado de televisión, para evitar la salida no
        autorizada de información, tanto en medios de almacenamiento externo,
        como en físico. Por lo anterior, usted podrá ser grabado por las cámaras
        de seguridad. VIVIENDA.COM, cuenta con un circuito cerrado de
        Televisión, el cual podrá utilizar diversos medios de video vigilancia
        en diferentes sitios internos y externos de sus sedes u oficinas.
        Mediante avisos ubicados en nuestra(s) sede(s), informamos a nuestros
        trabajadores y a visitantes que están siendo grabados. Nuestro circuito
        cerrado de televisión no inspecciona áreas en la que la intimidad del
        titular prime (tales como baños o áreas privadas sensibles, salones de
        clase). El sistema es utilizado únicamente para garantizar la seguridad
        de los bienes, instalaciones y personas que se encuentren en éstas. Esta
        información puede ser empleada como prueba en cualquier tipo de proceso
        ante autoridades administrativas o judiciales con sujeción y
        cumplimiento de las normas aplicables.
      </p>
      <h3>2. DATOS DEL RESPONSABLE DEL TRATAMIENTO</h3>
      <p>Nombre o razón social: VIVIENDA.COM</p>
      <p>Domicilio o dirección:</p>
      <p>Correo Electrónico:</p>
      <p>Página web: www.vivienda.com</p>
      <p>Telefono:</p>
      <h3>
        3. FINALIDAD CON LA QUE SE EFECTÚA LA RECOLECCIÓN DE DATOS PERSONALES
      </h3>
      <p>
        Los datos personales son objeto de tratamiento por parte de VIVIENDA.COM
        con las siguientes finalidades:
      </p>
      <ul>
        <li>
          Para el envío de información a sus trabajadores, clientes y
          proveedores.
        </li>
        <li>
          Ejecutar la relación contractual existente con los clientes,
          proveedores y trabajadores, incluida el pago de obligaciones
          contractuales;
        </li>
        <li>
          Proveer los servicios y/o los productos requeridos por sus usuarios;
        </li>
        <li>
          Informar sobre nuevos productos o servicios y/o sobre cambios en los
          mismos;
        </li>
        <li>
          Desarrollar el proceso de selección, evaluación, y vinculación
          laboral;
        </li>
        <li>Para la verificación de saldos de sus acreedores;</li>
        <li>Registrar la información de empleados.</li>
        <li>
          Los indicados en la autorización otorgada por el titular del dato o
          descritos en el aviso de privacidad respectivo, según sea el caso;
        </li>
        <li>
          Para eventualmente contactar, vía correo electrónico, o por cualquier
          otro medio, a personas naturales con quienes tiene o ha tenido
          relación, tales como, sin que la enumeración signifique limitación,
          trabajadores y familiares de éstos, accionistas, consumidores,
          clientes, distribuidores, proveedores, acreedores y deudores, para las
          finalidades antes mencionadas.
        </li>
      </ul>
      <p>
        Si un dato personal es proporcionado, dicha información será utilizada
        sólo para los propósitos aquí señalados, y por tanto, VIVIENDA.COM no
        procederá a vender, licenciar, transmitir, o divulgar la misma, salvo
        que:
      </p>
      <ul>
        <li>i. Exista autorización expresa para hacerlo.</li>
        <li>
          ii. Sea necesario para permitir a los contratistas o agentes prestar
          los servicios encomendados.
        </li>
        <li>
          iii. Sea necesario con el fin de proveer nuestros servicios y/o
          productos.
        </li>
        <li>iv. Que sea requerido o permitido por la ley.</li>
      </ul>
      <h4>3.1 FINALIDADES EN MATERIA LABORAL.</h4>

      <table>
        <tbody>
          <tr>
            Para fines pertinentes a la relación laboral (EPS, ARL, fondos de
            pensiones y cesantías, cajas de compensación familiar, etc.)
          </tr>
          <tr>
            En el caso de requerimientos judiciales y legales.
          </tr>
          <tr>
            Contabilización y pago de nómina.
          </tr>
          <tr>
            Reclutar y seleccionar personal que ocuparán las vacantes.
          </tr>
          <tr>
            Procesar, confirmar y cumplir con las obligaciones laborales legales
            y extralegales derivadas del contrato laboral.
          </tr>
          <tr>
            Realizar transacciones.
          </tr>
          <tr>
            Pago de beneficios extralegales.
          </tr>
          <tr>
            Auditorias
          </tr>
          <tr>
            Análisis estadísticos
          </tr>
          <tr>
            Mantener base de datos de candidatos
          </tr>
          <tr>
            Capacitación y formación
          </tr>
          <tr>
            Video con fines CCTV, con fines de seguridad, monitoreo, Procesar
            videos y fotos con fines de publicidad.
          </tr>
          <tr>
            La administración del Sitio Web en el que el usuario entre o llegare
            a entrar para explorar las ofertas y promociones promocionadas
          </tr>
          <tr>
            Compartir los datos personales con entidades bancarias, empresas que
            ofrezcan beneficios a nuestros trabajadores activos entre otros
          </tr>
        </tbody>
      </table>
      <h4>3.2 FINALIDADES DE BASES DE DATOS DE CLIENTES</h4>
      <table>
        <tbody>
          <tr>
            Para fines comerciales y prestación de servicios
          </tr>
          <tr>
            Para envío de contenidos de interés relacionados con el objeto
            social de la empresa.
          </tr>
          <tr>
            Cumplir normas legales de entidades gubernamentales
          </tr>
          <tr>
            Manejo de la facturación y recaudo de cartera
          </tr>
          <tr>
            Videos con fines de CCTV, seguridad, publicidad, monitoreo, procesar
            videos y fotos con fines de publicidad
          </tr>
          <tr>
            La administración del Sitio Web en el que el usuario entre o llegare
            a entrar para explorar las ofertas y promociones proporcionadas.
          </tr>
          <tr>
            Informes a entidades gubernamentales
          </tr>
          <tr>
            Manejo de la facturación y recaudo de cartera
          </tr>
          <tr>
            Publicidad
          </tr>
          <tr>
            Eventos
          </tr>
          <tr>
            Análisis de Estadísticas
          </tr>
        </tbody>
      </table>

      <h4>3.2 FINALIDADES DE BASES DE DATOS PARA PROVEEDORES</h4>
      <table>
        <tbody>
          <tr>
            Para fines comerciales y de prestación de servicios
          </tr>
          <tr>
            Para proveedores generales de bienes y servicios
          </tr>
          <tr>
            Contabilización e informes
          </tr>
          <tr>
            Disposiciones legales y fiscales
          </tr>
          <tr>
            Transferencias de fondos
          </tr>
          <tr>
            Auditorías internas
          </tr>
          <tr>
            Facturación
          </tr>
          <tr>
            Convenios
          </tr>
          <tr>
            Contrataciones
          </tr>
          <tr>
            Video cuando visitan las instalaciones de CCTV
          </tr>
          <tr>
            Para fines comerciales y de prestación de servicios
          </tr>
          <tr>
            Contabilización e informes
          </tr>
          <tr>
            Disposiciones legales y fiscales
          </tr>
          <tr>
            Transferencias de fondos
          </tr>
          <tr>
            Auditorías internas
          </tr>
          <tr>
            Facturación
          </tr>
          <tr>
            Convenios
          </tr>
          <tr>
            Contrataciones
          </tr>
        </tbody>
      </table>

      <p>
        Cuando la información o datos personales sean de menores de edad, la
        autorización deberá ser impartida por sus padres y/o representantes de
        acuerdo a la ley.
      </p>

      <h3>4. PRINCIPIOS PARA EL TRATAMIENTO DE DATOS PERSONALES</h3>

      <p>
        VIVIENDA.COM, aplicará los siguientes principios específicos que se
        establecen a continuación, los cuales constituyen las reglas a seguir en
        la recolección, manejo, uso, tratamiento, almacenamiento e intercambio,
        de datos personales:
      </p>
      <table>
        <li>
          Principio de finalidad: El Tratamiento de los datos personales
          recogidos debe obedecer a una finalidad legítima, la cual debe ser
          informada al Titular;
        </li>
        <li>
          Principio de libertad: El Tratamiento sólo puede llevarse a cabo con
          el consentimiento, previo, expreso e informado del Titular. Los datos
          personales no podrán ser obtenidos o divulgados sin previa
          autorización, o en ausencia de mandato legal o judicial que releve el
          consentimiento;
        </li>
        <li>
          Principio de veracidad o calidad: La información sujeta a Tratamiento
          debe ser veraz, completa, exacta, actualizada, comprobable y
          comprensible. No será efectuado el Tratamiento de datos parciales,
          incompletos, fraccionados o que induzcan a error;
        </li>
        <li>
          Principio de transparencia: En el Tratamiento debe garantizarse el
          derecho del Titular a obtener de 5G en cualquier momento y sin
          restricciones, información acerca de la existencia de datos que le
          conciernan;
        </li>
        <li>
          Principio de acceso y circulación restringida: El Tratamiento se
          sujeta a los límites que se derivan de la naturaleza de los datos
          personales, de las disposiciones de la ley 1581 de 2012. Los datos
          personales, salvo la información pública, y lo dispuesto en la
          autorización otorgada por el titular del dato, no podrán estar
          disponibles en Internet u otros medios de divulgación o comunicación
          masiva, salvo que el acceso sea técnicamente controlable para brindar
          un conocimiento restringido sólo a los Titulares o terceros
          autorizados;
        </li>
        <li>
          Principio de seguridad: La información sujeta a Tratamiento por parte
          de VICIENDA.COM, se deberá proteger mediante el uso de las medidas
          técnicas, humanas y administrativas que sean necesarias para otorgar
          seguridad a los registros evitando su adulteración, pérdida, consulta,
          uso o acceso no autorizado o fraudulento;
        </li>
        <li>
          Principio de confidencialidad: Todas las personas que intervengan en
          el Tratamiento de datos personales están obligadas a garantizar la
          reserva de la información, inclusive después de finalizada su relación
          con alguna de las labores que comprende el Tratamiento.
        </li>
      </table>
      <h3>5. DERECHOS DE LOS TITULARES DE LOS DATOS PERSONALES</h3>
      <p>
        Las personas naturales cuyos datos personales sean objeto de tratamiento
        por parte de VIVIENDA.COM tienen los siguientes derechos, los cuales
        puede ejercer en cualquier momento:
      </p>
      <ul>
        <li>
          Conocer, actualizar y rectificar en cualquier momento los datos
          personales que estén bajo el control de VIVIENDA.COM, este derecho se
          podrá ejercer entre otros frente a datos parciales, inexactos,
          incompletos, fraccionados, que induzcan a error, o aquellos cuyo
          tratamiento este expresamente prohibido o no haya sido autorizado.
        </li>
        <li>
          Solicitar prueba de la autorización otorgada a VIVIENDA.COM, salvo en
          los eventos en los cuales, según las normas legales vigentes, no se
          requiera de la autorización para realizar el tratamiento.
        </li>
        <li>
          Ser informado por VIVIENDA.COM, previa solicitud, respecto del uso que
          ésta le ha dado a sus datos personales.
        </li>
        <li>
          presentar quejas ante la Superintendencia de Industria y comercio por
          infracciones a lo dispuesto en la normatividad vigente sobre
          tratamiento de datos personales.
        </li>
        <li>
          Acceder de forma gratuita a sus datos personales objeto de
          tratamiento.
        </li>
        <li>
          Solicitar a VIVIENDA.COM la supresión de sus Datos Personales y/o
          revocar la autorización otorgada para el Tratamiento de los mismos,
          mediante la presentación de un reclamo, de acuerdo con los
          procedimientos establecidos en el numeral 11.2 de esta Política. No
          obstante, la solicitud de supresión de la información y la revocatoria
          de la autorización no procederán cuando el Titular de la información
          tenga un deber legal o contractual de permanecer en la Base de Datos
          y/o Archivos, ni mientras se encuentre vigente la relación entre el
          Titular y LA EMPRESA, en virtud de la cual fueron recolectados sus
          datos.
        </li>
      </ul>

      <h3>
        6. DEBERES DEL RESPONSABLE Y ENCARGADOS DEL TRATAMIENTO DE DATOS
        PERSONALES
      </h3>

      <p>
        VIEVIENDA.COM está obligada a cumplir los deberes que al respecto
        imponga la ley 1581 de 2012. En consecuencia se deben cumplir las
        siguientes obligaciones:
      </p>

      <h4>6.1 Deberes de los responsables del tratamiento:</h4>

      <ul>
        <li>
          Solicitar y conservar, en las condiciones previstas en esta política,
          copia de la respectiva autorización otorgada por el titular.
        </li>
        <li>
          Informar de manera clara y suficiente al titular sobre la finalidad de
          la recolección y los derechos que le asisten por virtud de la
          autorización otorgada.
        </li>
        <li>
          Informar a solicitud del titular sobre el uso dado a sus datos
          personales.
        </li>
        <li>
          Tramitar las consultas y reclamos formulados en los términos señalados
          en la presente política.
        </li>
        <li>
          Procurar que los principios de veracidad, calidad, seguridad y
          confidencialidad en los términos establecidos en la siguiente
          política.
        </li>
        <li>
          Conservar la información bajo las condiciones de seguridad necesarias
          para impedir su adulteración, pérdida, consulta, uso o acceso no
          autorizado o fraudulento.
        </li>
        <li>Actualizar la información cuando sea necesario.</li>
        <li>Rectificar los datos personales cuando ello sea procedente.</li>
      </ul>

      <h4>6.2 Deberes de los encargados del tratamiento:</h4>

      <ul>
        <li>
          Establecer que el Responsable del tratamiento esté autorizado para
          suministrar los datos personales que tratará como Encargado.
        </li>
        <li>
          Garantizar al titular, en todo tiempo, el pleno y efectivo ejercicio
          del derecho de hábeas data.
        </li>
        <li>
          Conservar la información bajo las condiciones de seguridad necesarias
          para impedir su adulteración, pérdida, consulta, uso o acceso no
          autorizado o fraudulento.
        </li>
        <li>
          Realizar oportunamente la actualización, rectificación o supresión de
          los datos.
        </li>
        <li>
          Actualizar la información reportada por los Responsables del
          tratamiento dentro de los cinco (5) días hábiles contados a partir de
          su recibo.
        </li>
        <li>
          Tramitar las consultas y los reclamos formulados por los titulares en
          los términos señalados en la presente política.
        </li>
        <li>
          Registrar en la base de datos la leyenda “reclamo en trámite” en la
          forma en que se establece en la presente política.
        </li>
        <li>
          Insertar en la base de datos la leyenda “información en discusión
          judicial” una vez notificado por parte de la autoridad competente
          sobre procesos judiciales relacionados con la calidad del dato
          personal.
        </li>
        <li>
          Abstenerse de circular información que esté siendo controvertida por
          el titular y cuyo bloqueo haya sido ordenado por la Superintendencia
          de Industria y Comercio.
        </li>
        <li>
          Permitir el acceso a la información únicamente a las personas
          autorizadas por el titular o facultadas por la ley para dicho efecto.
        </li>
        <li>
          Informar a la Superintendencia de Industria y Comercio cuando se
          presenten violaciones a los códigos de seguridad y existan riesgos en
          la administración de la información de los titulares.
        </li>
        <li>
          Cumplir las instrucciones y requerimientos que imparta la
          Superintendencia de Industria y Comercio.
        </li>
      </ul>

      <h3>7. AUTORIZACIÓN</h3>

      <p>
        VIVIENDA.COM debe solicitar autorización previa, expresa e informada a
        los Titulares de los Datos Personales sobre los que requiera realizar el
        Tratamiento.
      </p>
      <h4>
        7.1 Autorización previa significa, que el consentimiento debe ser
        otorgado por el Titular, a más tardar en el momento de la recolección de
        los Datos Personales.
      </h4>
      <h4>
        7.2 Autorización expresa quiere decir que el consentimiento del Titular
        debe ser explícito y concreto, no son válidas las autorizaciones
        abiertas y no específicas. Se requiere que el Titular manifieste su
        voluntad de autorizar que VIVIENDA.COM realice el Tratamiento de sus
        Datos Personales.
      </h4>
      <p>
        Esta manifestación de voluntad del Titular puede darse a través de
        diferentes mecanismos puestos a disposición por VIVIENDA.COM, tales
        como:
      </p>
      <ul>
        <li>Por escrito.</li>
        <li>
          De forma oral, por ejemplo, en una conversación telefónica o en
          videoconferencia.
        </li>
        <li>
          Mediante conductas inequívocas que permitan concluir que otorgó su
          autorización, por ejemplo, a través de su aceptación expresa a los
          Términos y Condiciones de una actividad dentro de los cuales se
          requiera la autorización de los participantes para el Tratamiento de
          sus Datos Personales.
        </li>
      </ul>
      <p>
        IMPORTANTE: En ningún caso VIVIENDA.COM asimilará el silencio del
        Titular a una conducta inequívoca. <br />
        Cualquiera que sea el mecanismo utilizado por VIVIENDA.COM, es necesario
        que la autorización se conserve para poder ser consultada con
        posterioridad.
      </p>
      <h4>
        7.3 Autorización Informada significa que al momento de solicitar el
        consentimiento al Titular, debe informársele claramente:
      </h4>
      <ul>
        <li>Los Datos Personales que serán recolectados.</li>
        <li>
          La identificación y datos de contacto del Responsable y del Encargado
          del Tratamiento.
        </li>
        <li>
          Las finalidades específicas del Tratamiento que se pretende realizar,
          es decir: cómo y para qué se va a hacer la recolección, el uso, la
          circulación de los Datos Personales.
        </li>
        <li>
          Cuáles son los derechos que tiene como Titular de los Datos
          Personales; para el efecto ver el numeral 4 de ésta Política.
        </li>
        <li>
          El carácter facultativo de la respuesta a las preguntas que le sean
          hechas, cuando éstas versen sobre datos sensibles o sobre los datos de
          niñas, niños y adolescentes.
        </li>
      </ul>

      <h3>8. AVISO DE PRIVACIDAD</h3>

      <p>
        El aviso de privacidad es el documento físico, electrónico o en
        cualquier otro formato, que es puesto a disposición del titular para el
        tratamiento de sus datos personales a más tardar al momento de
        recolección de los datos personales. A través de este documento se
        informa al titular la información relativa a la existencia de las
        políticas y procedimientos del tratamiento para la protección de datos
        personales que le serán aplicables por VIVIENDA.COM, la forma de acceder
        a las mismas y las características del tratamiento que se pretende dar a
        los datos personales.
      </p>

      <h3>
        9. ÁREA RESPONSABLE DE LA IMPLEMENTACIÓN Y OBSERVACIÓN DE LA POLÍTICA
      </h3>

      <p>
        El área administrativa de VIVIENDA.COM, tiene a su cargo la labor de
        desarrollo, implementación, capacitación y observancia de esta política.
        Adicionalmente, será el responsable de atender las peticiones, quejas y
        reclamos que reciban por parte de los titulares de datos personales.
      </p>

      <h3>
        10. DISPOSICIONES ESPECIALES PARA EL TRATAMIENTO DE DATOS PERSONALES DE
        NATURALEZA SENSIBLE.
      </h3>
      <p>
        De acuerdo con la Ley de Protección de Datos Personales, se consideran
        como datos de naturaleza sensible aquellos que afectan la intimidad o
        cuyo uso indebido puede generar discriminación, tales como los
        relacionados con :
      </p>
      <ul>
        <li>Origen racial o étnico.</li>
        <li>Orientación política.</li>
        <li>Convicciones religiosas / filosóficas.</li>
        <li>
          Pertenencia a sindicatos, a organizaciones sociales, a organizaciones
          de derechos humanos o a partidos políticos.
        </li>
        <li>Salud.</li>
        <li>Vida sexual.</li>
        <li>
          Datos biométricos (como la huella dactilar, la firma y la foto).
        </li>
      </ul>
      <p>
        El Tratamiento de los Datos Personales de naturaleza sensible está
        prohibido por la ley, salvo que se cuente con autorización expresa,
        previa e informada del Titular, entre otras excepciones consagradas en
        el Artículo 6º de la Ley 1581 de 2012. <br />
        En este caso, además de cumplir con los requisitos establecidos para la
        autorización, VIVIENDA.COM deberá:
      </p>
      <ul>
        <li>
          Informar al Titular que por tratarse de datos sensibles no está
          obligado a autorizar su Tratamiento.
        </li>
        <li>
          Informar al Titular cuáles de los datos que serán objeto de
          Tratamiento son sensibles y la finalidad del Tratamiento.
        </li>
      </ul>
      <p>
        IMPORTANTE: Ninguna actividad podrá condicionarse a que el Titular
        suministre Datos Personales sensibles.
      </p>
      <p>
        VIVIENDA.COM, podrá acceder y hacer uso de datos sensibles de sus
        trabajadores tales como videos, fotografías y huellas dactilares. Así
        mismo, VIVIENDA.COM obtiene información sobre el estado de salud de sus
        empleados en cumplimiento de obligaciones legales sobre salud
        ocupacional. Para estos efectos, VIVIENDA.COM dará aplicación a las
        disposiciones legales sobre tratamiento de datos sensibles, incluyendo:
      </p>
      <ol>
        <li>
          I. Obtener autorización explícita del Titular para el tratamiento,
          informando el carácter facultativo de la misma y los datos que se
          consideran sensibles. Esta autorización será implementada en toda
          recolección de datos sensibles, salvo en los siguientes casos en los
          que no se requiere autorización por disposición legal:
          <ol>
            <li>
              El Tratamiento sea necesario para salvaguardar el interés vital
              del Titular y este se encuentre física o jurídicamente
              incapacitado. En estos eventos, los representantes legales deberán
              otorgar su autorización.
            </li>
            <li>
              El Tratamiento sea efectuado en el curso de las actividades
              legítimas y con las debidas garantías por parte de una fundación,
              ONG, asociación o cualquier otro organismo sin ánimo de lucro,
              cuya finalidad sea política, filosófica, religiosa o sindical,
              siempre que se refieran exclusivamente a sus miembros o a las
              personas que mantengan contactos regulares por razón de su
              finalidad. En estos eventos, los datos no se podrán suministrar a
              terceros sin la autorización del Titular.
            </li>
            <li>
              El Tratamiento se refiera a datos que sean necesarios para el
              reconocimiento, ejercicio o defensa de un derecho en un proceso
              judicial.
            </li>
            <li>
              El Tratamiento tenga una finalidad histórica, estadística o
              científica. En este evento deberán adoptarse las medidas
              conducentes a la supresión de identidad de los Titulares.
            </li>
          </ol>
        </li>
      </ol>
      <p>
        En los casos en que VIVIENDA.COM tenga acceso a datos sensibles, el
        tratamiento de los mismos se realizará con las siguientes finalidades:
      </p>
      <p>
        Datos relativos al estado de salud e historia clínica ocupacional de
        candidatos y trabajadores para los siguientes fines:
      </p>
      <ul>
        <li>
          Verificar si el solicitante cumple con los requisitos físicos
          necesarios para desempeñar el cargo para el cual está aplicando o fue
          contratado.
        </li>
        <li>
          Contar con la información necesaria para atender cualquier emergencia
          médica que se presente durante la prestación de servicios en las
          instalaciones de 5G.
        </li>
        <li>
          Cumplir con las normas de seguridad y salud en el trabajo e
          implementar el SG-SST, y cualquier otro programa, sistema y/o plan que
          busque proteger la salud del trabajador y las personas en el lugar de
          trabajo.
        </li>
      </ul>
      <p>
        Estudios de antecedentes y resultados de pruebas de polígrafo de
        candidatos.
      </p>
      <ol>
        <li>
          Identificar al personal que acceda a las instalaciones de
          VIVIENDA.COM.
        </li>
        <li>
          Identificar los riesgos que conlleva para la seguridad de las
          instalaciones o personas que laboran en VIVIENDA.COM la contratación
          de determinado candidato.
        </li>
        <li>
          Determinar comportamientos o situaciones que perjudiquen el ambiente
          laboral de o la debida prestación de servicios por parte de
          VIVIENDA.COM.
        </li>
      </ol>
      <h3>
        11. DISPOSICIONES ESPECIALES PARA EL TRATAMIENTO DE DATOS PERSONALES DE
        NIÑOS, NIÑAS Y ADOLESCENTES
      </h3>
      pSegún lo dispuesto por el Artículo 7º de la Ley 1581 de 2012 y el
      artículo 12 del Decreto 1377 de 2013, 5G sólo realizará el Tratamiento,
      esto es, la recolección, almacenamiento, uso, circulación y/o supresión de
      Datos Personales correspondientes a niños, niñas y adolescentes, siempre y
      cuando este Tratamiento responda y respete el interés superior de los
      niños, niñas y adolescentes y asegure el respeto de sus derechos
      fundamentales. <br />
      Cumplidos los anteriores requisitos, VIVIENDA.COM deberá obtener la
      Autorización del representante legal del niño, niña o adolescente, previo
      ejercicio del menor de su derecho a ser escuchado, opinión que será
      valorada teniendo en cuenta la madurez, autonomía y capacidad para
      entender el asunto.
      <h3>
        12. PROCEDIMIENTO PARA ATENCIÓN Y RESPUESTA A PETICIONES, CONSULTAS,
        QUEJAS Y RECLAMOS DE LOS TITULARES DE DATOS PERSONALES
      </h3>
      <p>
        Los Titulares de los Datos Personales que estén siendo recolectados,
        almacenados, utilizados, puestos en circulación por VIVIENDA.COM, podrán
        ejercer en cualquier momento sus derechos a conocer, actualizar,
        rectificar y suprimir información y revocar la autorización.
      </p>
      <h4>12.1 ATENCIÓN Y RESPUESTA A PETICIONES Y CONSULTAS:</h4>
      <p>
        ¿En qué consiste el trámite? <br />
        El Titular o sus causahabientes, podrán solicitar a VIVIENDA.COM, a
        través de los medios indicados más adelante:
      </p>
      <ul>
        <li>
          Información sobre los Datos Personales del Titular que son objeto de
          Tratamiento.
        </li>
        <li>
          Solicitar prueba de la autorización otorgada a VIVIENDA.COM para el
          Tratamiento de sus Datos Personales.
        </li>
        <li>
          Información respecto del uso que se le ha dado por VIVIENDA.COM a sus
          datos personales.
        </li>
      </ul>
      <p>
        Medios habilitados para la presentación de peticiones y consultas:
        <br />
        VIVIENDA.COM ha dispuesto los siguientes medios para la recepción y
        atención de peticiones y consultas, todos los cuales permiten conservar
        prueba de las mismas:
      </p>
      <ul>
        <li>
          Comunicación dirigida a VIVIENDA.COM, Dirección AV CRA 45 118 45 en la
          ciudad de Bogotá D.C.
        </li>
        <li>
          Solicitud presentada al correo electrónico: juridico@grupo5g.com
        </li>
      </ul>
      <p>
        Las peticiones y consultas serán atendidas en un término máximo de diez
        (10) días hábiles contados a partir de la fecha de recibo de las mismas.
        Cuando no fuere posible atender la petición o consulta dentro de dicho
        término, se informará al interesado, expresando los motivos de la demora
        y señalando la fecha en que se atenderá su petición o consulta, la cual
        en ningún caso podrá superar los cinco (5) días hábiles siguientes al
        vencimiento del primer término.
      </p>
      <h4>12.2 ATENCIÓN Y RESPUESTA A QUEJAS Y RECLAMOS:</h4>
      <p>¿En qué consiste el trámite?</p>
      <p>
        El Titular o sus causahabientes, podrán solicitar a VIVIENDA.COM a
        través de una queja o reclamo presentado mediante los canales indicados
        más adelante:
      </p>
      <ul>
        <li>La corrección o actualización de la información.</li>
        <li>
          La supresión de sus Datos Personales o la revocatoria de la
          autorización otorgada para el Tratamiento de los mismos.
        </li>
        <li>
          Que se subsane o corrija el presunto incumplimiento a cualquiera de
          los deberes contenidos en la Ley de Protección de Datos Personales.
        </li>
      </ul>
      <p>
        La solicitud deberá contener la descripción de los hechos que dan lugar
        a la queja o reclamo, la dirección y datos de contacto del solicitante,
        y deberá acompañarse de los documentos que se quiera hacer valer.
      </p>
      <p>Medios habilitados para la presentación de quejas y reclamos:</p>
      pVIVIENDA.COM ha dispuesto los siguientes medios para la recepción y
      atención de quejas y reclamos, todos los cuales permiten conservar prueba
      de su presentación:
      <ul>
        <li>
          Comunicación dirigida a VIVIENDA.COM, dirección AV CRA 45 118 45 en la
          ciudad de Bogotá D.C.
        </li>
        <li>
          Solicitud presentada al correo electrónico: juridico@grupo5g.com
        </li>
      </ul>
      <p>
        La queja o reclamo deberá contener la identificación del Titular, la
        descripción de los hechos que dan lugar al reclamo, la dirección, y
        acompañando los documentos que se quiera hacer valer. Si el reclamo
        resulta incompleto, se requerirá al interesado dentro de los cinco (5)
        días siguientes a la recepción del reclamo para que subsane las fallas.
        Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el
        solicitante presente la información requerida, se entenderá que ha
        desistido del reclamo. En caso de que quien reciba el reclamo no sea
        competente para resolverlo, dará traslado a quien corresponda en un
        término máximo de dos (2) días hábiles e informará de la situación al
        interesado. Una vez recibido el reclamo completo, se incluirá en la base
        de datos una leyenda que diga “reclamo en trámite” y el motivo del
        mismo, en un término no mayor a dos (2) días hábiles. Dicha leyenda
        deberá mantenerse hasta que el reclamo sea decidido. El término máximo
        para atender el reclamo será de diez (10) días hábiles contados a partir
        del día siguiente a la fecha de su recibo. Cuando no fuere posible
        atender el reclamo dentro de dicho término, se informará al interesado
        los motivos de la demora y la fecha en que se atenderá su reclamo, la
        cual en ningún caso podrá superar los ocho (8) días hábiles siguientes
        al vencimiento del primer término.
      </p>
      <h3>13. SEGURIDAD DE LOS DATOS PERSONALES</h3>
      <p>
        VIVIENDA.COM , en estricta aplicación del Principio de Seguridad en el
        Tratamiento de Datos Personales, proporcionará las medidas técnicas,
        humanas y administrativas que sean necesarias para otorgar seguridad a
        los registros evitando su adulteración, pérdida, consulta, uso o acceso
        no autorizado o fraudulento. La obligación y responsabilidad de
        VIVIENDA.COM se limita a disponer de los medios adecuados para este fin.
        VIVIENDA.COM no garantiza la seguridad total de su información ni se
        responsabiliza por cualquier consecuencia derivada de fallas técnicas o
        del ingreso indebido por parte de terceros a la Base de Datos o Archivo
        en los que reposan los Datos Personales objeto de Tratamiento por parte
        de VIVIENDA.COM y sus Encargados. VIVIENDA.COM exigirá a los proveedores
        de servicios que contrata, la adopción y cumplimiento de las medidas
        técnicas, humanas y administrativas adecuadas para la protección de los
        Datos Personales en relación con los cuales dichos proveedores actúen
        como Encargados.
      </p>
      <h3>
        14. MODIFICACIÓN Y/O ACTUALIZACIÓN DE LA POLÍTICA DE PROTECCIÓN DE DATOS
        PERSONALES Y MANEJO DE INFORMACIÓN
      </h3>
      <p>
        Cualquier cambio sustancial en las políticas de tratamiento, se
        comunicará de forma oportuna a los Titulares de los datos a través del
        correo electrónico institucional y/o a través de la página web de 5G:
        www.vivienda.com
      </p>
      <h3>15. PERMANENCIA DE LA INFORMACIÓN EN LAS BASES DE DATOS</h3>
      <p>
        Los datos se conservan de acuerdo con los principios de necesidad y
        razonabilidad, así como con los de caducidad y temporalidad según lo
        previsto en la Ley y en la jurisprudencia.
      </p>
      <p>
        Las bases de datos en las que se registrarán los datos personales
        tendrán una vigencia igual al tiempo en que se mantenga y utilice la
        información para las finalidades descritas en esta política. Una vez se
        cumpla(n) esa(s) finalidad(es) y siempre que no exista un deber legal o
        contractual de conservar su información, se procederá a la eliminación
        de los datos personales de la base de datos o archivos de VIVIENDA.COM.
      </p>
      <h3>16. VIGENCIA</h3>

      <p>
        La presente política de protección de datos entra en vigencia a partir
        de marzo de 2022.
      </p>

      <p>
        Atentamente, <br />
        VIVIENDA.COM <br />
      </p>
    </div>
  </div>
</div>
