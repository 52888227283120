import { Component, ViewEncapsulation, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ApiConnectionService } from 'src/app/services/api-connection.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import * as Cookies from 'js-cookie';
declare const $: any;
declare const Editor: any;

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EditorComponent implements OnInit {
  template: any = '';
  token: any = '';
  browser = false;

  constructor(
    protected apiService: ApiConnectionService,
    protected http: ApiConnectionService,
    protected route: ActivatedRoute,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    route.params.subscribe(params => {
      this.template = params['id'];
      this.token = params['property'];
    });
    this.browser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (this.browser) {
      this.http.getData('template/' + this.template + '/' + this.token, { auth: true })
        .subscribe(
          response => {
            setTimeout(() => {
              var editor = new Editor({
                root: 'https://cdn.vivienda.com/js/builder/',
                buildMode: true,
                legacyMode: false,
                urlBack: window.location.origin,
                uploadAssetUrl: environment.api_uri + 'upload',
                uploadAssetMethod: 'POST',
                saveUrl: environment.api_uri + 'template',
                saveMethod: 'POST',
                dataType: 'json',
                data: {
                  _token: Cookies.get('vivienda_com_auth_token'),
                  type: 'custom',
                  template: this.template,
                  token: this.token
                },
                changeTemplateCallback: function (url) {
                  window.location = url;
                },
                loadHtmlContent: response.content
              });
              if (response.code) {
                editor.export = {
                  url: environment.serverUrl + '/export/' + response.code
                }
              }
              editor.init();
              window['editor'] = editor;
              setTimeout(() => {
                $("a.save-and-close").remove();
                $("a#nav-contact-tab").remove();
                $("div#nav-layouts").remove();
                $("img.logo-img").attr('src', 'https://cdn.vivienda.com/images/logo.png');
                $("button.btn.btn-primary.btn-close.menu-bar-action > i").removeClass(['fa', 'fa-times']);
                $("button.btn.btn-primary.btn-close.menu-bar-action > i").addClass(['fas', 'fa-undo']);
                $("a.builder-close").attr('href', '/usuario/perfil');
              }, 2000);

              setTimeout(() => {
                $("#editable").click();
              }, 2500);

              let interval = setInterval(() => {
                if ($("div[class-name='VideoWidget']"))
                  $("div[class-name='VideoWidget']").remove();
                else
                  clearInterval(interval);
              }, 1000);
            }, 2000);
          }
        );
    }
  }
}
