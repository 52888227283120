import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HomeService } from 'src/app/services/home/home.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  stats = {
    companies: 0,
    professionals: 0,
    sale: 0,
    rent: 0,
    users: 0
  }

  constructor(
    protected title: Title,
    protected meta: Meta,
    protected homeService: HomeService
  ) {
    this.title.setTitle(`Sobre nosotros - Vivienda.com`);
    this.meta.addTags([
      { name: 'title', content: this.title.getTitle() },
      { name: 'description', content: 'Somos un portal que integra todo lo que necesitas para tu vivienda. Podrás además vender, comprar inmuebles nuevos y usados y si quieres publicar el tuyo tendrás todas las herramientas disponible para hacerlo. Complementando con todos los servicios que tu hogar pueda llegar a necesitar' },
      { property: 'og:title', content: this.title.getTitle() },
      { property: 'og:description', content: 'Somos un portal que integra todo lo que necesitas para tu vivienda. Podrás además vender, comprar inmuebles nuevos y usados y si quieres publicar el tuyo tendrás todas las herramientas disponible para hacerlo. Complementando con todos los servicios que tu hogar pueda llegar a necesitar' },
      { property: 'og:url', content: 'https//vivienda.com/about' },
      { property: 'og:image', content: 'https://cdn.vivienda.com/images/about/banner.jpg' }
    ]);
  }

  ngOnInit(): void {
    this.homeService.getAbout()
      .subscribe(
        (data: any) => {
          for (var i in data)
            this.stats[i] = data[i];
        }
      );
  }
}
