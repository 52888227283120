<div *ngIf="profile?.type == 'Empresa constructora'" class="text-right">
  <a
    href="/constructoras/{{ profile.slug }}/proyecto/register"
    class="text-color-black text-bold"
    style="
    position: absolute;
    top: 3%;
    right: 16px;
"
  >
    <img
      src="https://cdn.vivienda.com/images/icons/empresas-enabled.png"
      class="select-img disabled mr-2"
      width="18px"
    />
    Subir un proyecto
  </a>
</div>

<h5 class="text-color-black text-bold text-center">Datos de la cuenta</h5>
<p class="text-gray text-thin text-center">
  Aquí podrás visualizar y administrar toda la información que verán todos
  los<br />interesados en tus inmuebles y/o servicios
</p>
<div class="row">
  <div class="col-12 px-0">
    <form class="content ml-3" autocomplete="off" autocapitalize="off">
      <div class="row pb-2" *ngIf="isProfile">
        <div
          class="col-md-12 px-1 text-center banner-profile"
          style="max-height: 150px; overflow: hidden"
        >
          <img
            src="{{
              profile.banner
                ? profile.banner
                : 'https://cdn.vivienda.com/images/icons/banner-public-profile.jpg'
            }}"
            [class]="profile.banner ? 'w-100' : 'h-100'"
            onerror="https://cdn.vivienda.com/images/icons/persona.png"
          />
          <div
            class="camera-banner pointer d-flex align-items-center justify-content-center"
            onclick="document.getElementById('image-banner').click()"
          >
            <label class="pointer mb-0">
              <i class="fas fa-camera text-yellow"></i>
            </label>
            <input
              accept="image/png, image/jpeg, image/jpg"
              #file
              type="file"
              id="image-banner"
              accept="image/*"
              class="d-none"
              (change)="uploadImage($event, 'banner')"
            />
          </div>

          <button
            *ngIf="profile.banner"
            data-toggle="modal"
            data-target="#imagesModal"
            data-backdrop="static"
            data-keyboard="false"
            class="border btn-white text-yellow cursor-pointer pl-2 pb-1"
            style="position: absolute; top: 0.25em; right: 0.5em; z-index: 100"
            (click)="croppedImage = profile.banner"
          >
            <i class="fas fa-edit"></i>
          </button>

          <!--Modal edit-->
          <div
            class="modal fade bd-example-modal-lg p-0"
            id="imagesModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-lg py-3 px-0 px-md-5"
              style="max-width: fit-content !important"
            >
              <div class="modal-content p-2 p-md-3">
                <div
                  class="row px-md-5 px-3 align-items-center"
                >
                  <div class="col-12">
                    <h3 class="text-black text-color-black text-center mt-3">
                      Editor de fotos
                    </h3>
                    <h6 class="text-regular text-color-black text-center mb-3">
                      Realiza el recorte de tu imagen para ser visualizada
                      dentro de nuestro portal.
                    </h6>
                  </div>
                  <div class="col-lg-6 px-1 px-md-5 px-2">
                    <image-cropper
                      [imageURL]="croppedImage"
                      [maintainAspectRatio]="true"
                      [containWithinAspectRatio]="false"
                      [aspectRatio]="3"
                      [resizeToWidth]="1920"
                      [cropperMinWidth]="0"
                      [onlyScaleDown]="true"
                      [roundCropper]="false"
                      [transform]="transform"
                      [canvasRotation]="canvasRotation"
                      format="jpeg"
                      (cropperReady)="cropperReady()"
                      (imageCropped)="imageCropped($event)"
                    ></image-cropper>
                    
                  </div>
                  <div class="col-lg-6">
                    <div class="row my-2 text-center">
                        <div class="col-3">
                          <button
                            (click)="rotateLeft()"
                            class="btn btn-yellow-outline btn-sm px-0 px-md-1"
                          >
                            Girar a la izquierda <i class="fas fa-undo-alt ml-1"></i>
                          </button>
                        </div>
                        <div class="col-3">
                          <button
                            (click)="rotateRight()"
                            class="btn btn-yellow-outline btn-sm px-0 px-md-1"
                          >
                            Girar a la derecha <i class="fas fa-redo-alt ml-1"></i>
                          </button>
                        </div>
                        <div class="col-3">
                          <button
                            (click)="flipHorizontal()"
                            class="btn btn-yellow-outline btn-sm px-0 px-md-1"
                          >
                            Voltear Horizontal
                            <i class="fas fa-exchange-alt ml-1"></i>
                          </button>
                        </div>
                        <div class="col-3">
                          <button
                            (click)="flipVertical()"
                            class="btn btn-yellow-outline btn-sm px-md-1"
                          >
                            Voltear Vertical
                            <i
                              class="fas fa-exchange-alt ml-1"
                              style="transform: rotate(90deg)"
                            ></i>
                          </button>
                        </div>
                        <div class="col-12 text-center mt-2">
                          <button
                            (click)="resetImage()"
                            class="rounded-8 border border-secondary btn-sm bg-transparent text-secondary"
                          >
                            Restaurar imagen
                            <i
                              class="fa fa-window-restore ml-1"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="col-12 text-center flex-row">
                  <a
                    type="button"
                    class="btn bg-white text-yellow mt-1 mb-3 py-2 text-bold"
                    data-dismiss="modal"
                    style="width: 180px"
                    >Cancelar</a
                  >
                  <button
                    type="button"
                    class="btn btn-yellow mt-1 mb-3 py-2 text-bold"
                    data-dismiss="modal"
                    (click)="useCropper()"
                    style="width: 180px"
                  >
                    Guardar imagen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row px-3">
        <div class="col-md-2 px-1 text-center">
          <div class="profile-image mb-3 mb-md-0">
            <img
              src="{{
                !isProfile
                  ? user.image
                    ? user.image
                    : 'https://cdn.vivienda.com/images/icons/persona.png'
                  : profile.image
                  ? profile.image
                  : 'https://cdn.vivienda.com/images/icons/persona.png'
              }}"
              class="h-100"
              onerror="this.src = 'https://cdn.vivienda.com/images/icons/persona.png'"
            />
          </div>
          <div
            class="camera pointer d-flex align-items-center justify-content-center pointer"
            onclick="document.getElementById('image').click()"
          >
            <label class="pointer mb-1">
              <i class="fas fa-camera fa-sm text-yellow"></i>
            </label>
            <input
              accept="image/png, image/jpeg, image/jpg"
              #file
              type="file"
              id="image"
              accept="image/*"
              class="d-none"
              (change)="uploadImage($event)"
            />
          </div>
        </div>
        <div class="col-md-10">
          <h5 class="text-color-black text-bold text-left">
            Datos {{ isProfile ? "del perfil" : "personales" }}:
          </h5>
          <div class="row px-2">
            <div class="col-md-6 px-2" *ngIf="!isProfile">
              <input
                type="text"
                name="name"
                id="name"
                class="w-100 px-3"
                [placeholder]="'Nombre(s)'"
                [(ngModel)]="user.name"
                required
                *ngIf="!isProfile"
              />
            </div>
            <div class="col-md-6 px-2" *ngIf="isProfile">
              <input
                type="text"
                name="name"
                id="name"
                class="w-100 px-3"
                [placeholder]="'Nombre o Razón social'"
                [(ngModel)]="profile.name"
                required
                *ngIf="isProfile"
              />
            </div>
            <div class="col-md-6 px-2" *ngIf="!isProfile">
              <input
                type="text"
                name="surname"
                id="surname"
                class="w-100 px-3"
                placeholder="Apellido(s)"
                [(ngModel)]="user.surname"
                required
                *ngIf="!isProfile"
              />
            </div>
            <div class="col-md-6 px-2" *ngIf="!isProfile">
              <input
                type="text"
                name="phone"
                id="phone"
                class="w-100 px-3"
                placeholder="Celular"
                [(ngModel)]="user.cellphone"
                min="0"
                required
                *ngIf="!isProfile"
              />
            </div>
            <div class="col-md-6 px-2" *ngIf="isProfile">
              <input
                type="text"
                name="phone"
                id="phone"
                class="w-100 px-3"
                placeholder="Celular"
                [(ngModel)]="profile.phone"
                min="0"
                required
                *ngIf="isProfile"
              />
            </div>
            <div class="col-md-6 px-2">
              <input
                type="email"
                name="email"
                id="email"
                class="w-100 px-3"
                placeholder="Correo electrónico"
                [(ngModel)]="isProfile ? profile.email : user.email"
                readonly
                disabled
              />
            </div>

            <div class="col-md-6 px-2" *ngIf="!isProfile">
              <input
                type="text"
                name="adddress"
                id="adddress"
                class="w-100 px-3"
                placeholder="Dirección"
                [(ngModel)]="user.address"
                *ngIf="!isProfile"
              />
            </div>
            <div class="col-md-6 px-2" *ngIf="!isProfile">
              <input
                type="text"
                name="city"
                id="city"
                class="w-100 px-3"
                placeholder="Ciudad"
                [(ngModel)]="user.city"
                *ngIf="!isProfile"
              />
            </div>
          </div>
          <h5 class="text-color-black text-bold text-left mt-4">
            Descripción:
          </h5>
          <textarea
            name="description"
            id="description"
            cols="30"
            rows="10"
            class="p-3"
            placeholder="Escribe una pequeña descripción de tu empresa la cual aparecerá en la página principal"
            [(ngModel)]="user.description"
            *ngIf="!isProfile"
          ></textarea>
          <textarea
            name="description"
            id="description"
            cols="30"
            rows="10"
            class="p-3"
            placeholder="Escribe una pequeña descripción de tu empresa la cual aparecerá en la página principal"
            [(ngModel)]="profile.description"
            *ngIf="isProfile"
          ></textarea>

          <h5
            class="text-gray text-center text-semibold mt-4 mb-3 removable"
            *ngIf="isProfile"
          >
            Ingresa tus números de teléfono
          </h5>
          <h5
            class="text-color-black text-bold text-left mt-4 removable"
            *ngIf="isProfile"
          >
            Números de teléfono
          </h5>
          <div class="row px-2 removable" *ngIf="isProfile">
            <div
              class="col-12"
              *ngFor="let sn of profile.cellphones; index as i"
            >
              <input
                type="text"
                name="cellphone-{{ i + 1 }}"
                id="cellphone-{{ i + 1 }}"
                placeholder="Celular {{ i + 1 }}"
                [(ngModel)]="profile.cellphones[i].value"
                class="w-100 px-3 ng-pristine ng-valid ng-touched"
              />
              <a
                class="icon-remove cursor-pointer"
                (click)="profile.cellphones.splice(i, 1)"
                *ngIf="profile.cellphones.length > 1"
              >
                <i class="fas fa-times-circle text-danger"></i>
              </a>
            </div>
            <div class="col-12 text-center">
              <a
                (click)="profile.cellphones.push({ value: '' })"
                class="text-yellow pointer"
              >
                Añadir Teléfono <i class="fas fa-plus"></i>
              </a>
            </div>
          </div>

          <h5
            class="text-gray text-center text-semibold mt-4 mb-3 removable"
            *ngIf="isProfile"
          >
            Ingresa tus sitios web
          </h5>
          <h5
            class="text-color-black text-bold text-left mt-4 removable"
            *ngIf="isProfile"
          >
            Sitios web
          </h5>
          <div class="row px-2 removable" *ngIf="isProfile">
            <div class="col-12" *ngFor="let sn of profile.websites; index as i">
              <input
                type="text"
                name="website-{{ i + 1 }}"
                id="website-{{ i + 1 }}"
                placeholder="Sitio Web {{ i + 1 }}"
                [(ngModel)]="profile.websites[i].value"
                class="w-100 px-3 ng-pristine ng-valid ng-touched"
              />
              <a
                class="icon-remove cursor-pointer"
                (click)="profile.websites.splice(i, 1)"
                *ngIf="profile.websites.length > 1"
              >
                <i class="fas fa-times-circle text-danger"></i>
              </a>
            </div>
            <div class="col-12 text-center">
              <a
                (click)="profile.websites.push({ value: '' })"
                class="text-yellow pointer"
              >
                Añadir Sitio Web <i class="fas fa-plus"></i>
              </a>
            </div>
          </div>

          <h5
            class="text-gray text-center text-semibold mt-4 mb-3 removable"
            *ngIf="isProfile"
          >
            Tus ubicaciones
          </h5>
          <input
            class="px-3 mt-2 d-block mx-auto w-100 removable"
            type="text"
            name="address"
            id="address"
            placeholder="Dirección*"
            *ngIf="isProfile"
          />
          <div
            id="address-components"
            class="d-none removable"
            *ngIf="isProfile"
          ></div>
          <div class="row px-2 mt-3 removable" *ngIf="isProfile">
            <div
              class="col-md-6 mt-2 mt-md-0 mb-3 cursor-pointer"
              [class]="city.deleted_at ? 'd-none' : ''"
              *ngFor="let city of profile.locations; index as i"
              (click)="
                hasMoreLocations()
                  ? city.id !== undefined
                    ? (profile.locations[i].deleted_at = true)
                    : profile.locations.splice(i, 1)
                  : false
              "
            >
              <div
                class="ciudad"
                title="Al hacer click eliminarás la dirección"
              >
                <p class="text-thin text-white text-center mb-0">
                  {{ city.address }}, {{ city.city }}
                </p>
              </div>
            </div>
          </div>
          <span class="text-danger removable" *ngIf="isProfile"
            >Para eliminar una ubicación haz clic sobre ella y tendrás que
            <a (click)="update()" class="pointer">Guardar</a> los cambios</span
          >

          <h5 class="text-gray text-center text-semibold mt-4 mb-3 removable">
            Ingresa tus enlaces de redes sociales
          </h5>
          <h5 class="text-color-black text-bold text-left mt-4 removable">
            Redes sociales
          </h5>
          <div class="row px-2 removable">
            <div class="col-md-6" *ngFor="let sn of social; index as i">
              <div class="row">
                <div class="col-2 px-2">
                  <img
                    src="{{ sn.image }}"
                    class="w-100"
                    alt="{{ sn.name }} Vivienda"
                  />
                </div>
                <div class="col-10 px-2">
                  <input
                    type="text"
                    name="{{ sn.name.toLowerCase() }}"
                    id="{{ sn.name.toLowerCase() }}"
                    placeholder="{{ sn.name }}"
                    [(ngModel)]="social[i].value"
                    class="w-100 px-3 ng-pristine ng-valid ng-touched"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        (click)="update()"
        class="d-block bg-yellow text-white text-bold text-center mx-auto my-3 py-2 pointer save-web"
        style="width: 150px"
        >Guardar cambios</a
      >
      <div class="save-mobile">
        <a
          (click)="update()"
          class="d-lg-none bg-yellow text-white text-bold text-center mx-auto my-3 py-2 pointer save-mobile-btn"
          style="width: 150px"
          >Guardar cambios</a
        >
      </div>
      <div class="save-mobile-spacing"></div>
    </form>
  </div>
</div>
