<section class="banner">
  <div class="container pt-5">
    <div class="txt row justify-content-center col-12 align-items-center">
      <div class="col-lg-7">
        <p class="mb-0">Conéctate con tus próximos clientes</p>
        <h3 class="mb-4">¡Registra tus servicios totalmente <span>GRATIS</span>!</h3>
        <p class="mb-2">
          <span>Puedes ser parte de nuestros aliados</span>, te invitamos a que
          nos compartas los detalles de que servicio prestas como proveedor.
          <br />
          <span class="invitacion">
            Te invitamos a registrarte, es gratis y es seguro
          </span>
        </p>
        <!--
                <p class="mt-5" align="center">
                    <a href="Servicios#servicios" class="btn-yellow pl-3 pr-3 pt-3 pb-3 ancla">
                        Encuentra tus servicios
                    </a>
                </p>
-->
      </div>
      <div class="col-lg-5">
        <form
          action="#"
          class="inscribe_servicio mt-3"
          #f="ngForm"
          (ngSubmit)="register(f)"
          autocomplete="off"
        >
          <div class="form-group">
            <label for=""> Nombre </label>
            <input
              type="text"
              name="name"
              id="name"
              [(ngModel)]="name"
              class="px-4"
              required
            />
            <br />
            <span
              *ngIf="f.submitted && f.controls['name'].errors"
              class="text-danger animated fadeIn"
              >El/Los nombre(s) es obligatorio</span
            >
          </div>
          <div class="form-group">
            <label for=""> Apellido </label>
            <input
              type="text"
              name="surname"
              id="surname"
              [(ngModel)]="surname"
              class="px-4"
              required
            />
            <br />
            <span
              *ngIf="f.submitted && f.controls['surname'].errors"
              class="text-danger animated fadeIn"
              >El/Los apellido(s) es obligatorio</span
            >
          </div>
          <div class="form-group">
            <label for=""> Correo electrónico </label>
            <input
              type="email"
              name="email"
              id="email"
              [(ngModel)]="email"
              class="px-4"
              required
            />
            <br />
            <span
              *ngIf="f.submitted && f.controls['email'].errors"
              class="text-danger animated fadeIn"
              >El correo electrónico es obligatorio</span
            >
          </div>
          <div class="form-group">
            <label for="password">Contraseña
            </label>
            <input
              type="password"
              name="password"
              id="password"
              [(ngModel)]="password"
              class="px-4"
              required
            />
            <br />
            <span
              *ngIf="f.submitted && f.controls['password'].errors"
              class="text-danger animated fadeIn"
              >La contraseña es obligatoria y debe tener mínimo 6 caracteres</span
            >
          </div>
          <div class="form-group">
            <label for=""> Teléfono </label>
            <input
              _ngcontent-serverapp-c187=""
              type="text"
              inputmode="numeric"
              [(ngModel)]="cellphone"
              name="cellphone"
              id="cellphone"
              min="7"
              required=""
              class="px-4 ng-untouched ng-pristine ng-invalid"
              ng-reflect-required=""
              ng-reflect-name="cellphone"
              data-intl-tel-input-id="0"
              placeholder="+57 321 1234567"
            />
          </div>
          <div class="form-group text-center my-2">
            <input
            type="checkbox"
            name="terms"
            id="terms"
            [(ngModel)]="terms"
            required
          />
            <label for="terms"></label>
            <h6 class="text-gray text-thin text-left textos terminos mx-auto">
              Acepto los términos y condiciones, política de privacidad y el
              tratamiento de mis datos personales
            </h6>
          </div>
          <div class="col-12 row m-0 justify-content-center p-0">
            <re-captcha
              #captchaRef="reCaptcha"
              (resolved)="resolved($event)"
              siteKey="6LdkKNwUAAAAABGyZqGidUfLfPWk73AuG0u_ylMa"
              class="g-recaptcha"
              required
            ></re-captcha>
          </div>
          <div class="form-group m-2">
            <button type="submit" class="btn-yellow">Registrate</button>
          </div>
        </form>
      </div>
      <!--
            <div class="col-md-12 justify-content-center d-flex">
                <div class="card-counter primary col-9 col-lg-2">
                    <i class="fa fa-wrench"></i>
                    <p class="text-center ">
                        <span class="count-description">Ya contamos con</span>
                        <span class="count-numbers">12</span>
                        <span class="count-name">Profesionales</span>
                    </p>
                </div>
            </div>
    --></div>
  </div>
</section>

<!--
<section class="buscador mt-2 pt-3">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-12 col-md-3 px-0 mx-0">
                <div class="form-group text-center relative mb-0">
                    <a href="#tip-submenu-services" data-toggle="collapse" class="d-flex flex-column flex-md-row justify-content-center align-items-center realative  text-white  btn-yellow text-bold  text-color-white text-center w-100 py-2 px-3 no-close" id="tip-submenu-services-href">
                        <span class="mr-auto ml-auto">Servicios el hogar y/o oficina</span>
                        <i class="ml-md-auto fas fa-chevron-down"></i>
                    </a>
                </div>
            </div>
            <div class="col-12 col-md-4 d-none d-md-flex icon-service-2 px-0 pl-md-3 pr-md-0 mx-0">
                <div class="form-group text-center relative w-100">
                    <input type="text" name="city-filter" id="location-service-filter" class="pl-3 pr-2 py-3 w-100" placeholder="Escribe la ciudad, zona o sector" autocomplete="off">
                </div>
                <span class="text-gray search-icon location">
                    <i class="fas fa-map-marker-alt fa-lg"></i>
                </span>
            </div>
            <div class="col-12 col-md-2 px-1 d-none d-md-flex">
                <a class="d-block realative text-white btn-yellow text-bold text-color-white text-center w-100 py-2 px-3 no-close pointer">
                    Buscar
                </a>
            </div>
        </div>
    </div>
</section>


<section class="servicios mt-4 pb-5" id="servicios">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 Titulo mb-4">
                <h3 class="text-color-black mb-0 mt-1 mt-md-0 p-3 text-center titulo-proyectos">
                    Servicios
                </h3>
                <p>
                    Nuestra lista de servicios con personal altamente calificado, preparado y listo para ayudarte.
                </p>
            </div>
            <div class="services box1 col-6 col-md-2">
                <div class="header-img">
                    <a href="#">
                        <img src="./assets/img/servicios/aire_acondicionado.svg" alt="" width="50">
                    </a>
                </div>
                <div class="body-content">
                    <h5>
                        <a href="#">
                            Aire <br> acondicionado
                        </a>
                    </h5> <a href="#">Ver profesionales</a>
                </div>
            </div>
            <div class="services box1 col-6 col-md-2">
                <div class="header-img">
                    <a href="#">
                        <img src="./assets/img/servicios/mudanzas.svg" alt="" width="50">
                    </a>
                </div>
                <div class="body-content">
                    <h5>
                        <a href="#">
                            Trasteos
                        </a>
                    </h5> <a href="#">Ver profesionales</a>
                </div>
            </div>
            <div class="services box1 col-6 col-md-2">
                <div class="header-img">
                    <a href="#">
                        <img src="./assets/img/servicios/albanil.svg" alt="" width="50">
                    </a>
                </div>
                <div class="body-content">
                    <h5>
                        <a href="#">
                            Albañil
                        </a>
                    </h5> <a href="#">Ver profesionales</a>
                </div>
            </div>
            <div class="services box1 col-6 col-md-2">
                <div class="header-img">
                    <a href="#">
                        <img src="./assets/img/servicios/venta.svg" alt="" width="50">
                    </a>
                </div>
                <div class="body-content">
                    <h5>
                        <a href="#">
                            Venta
                        </a>
                    </h5> <a href="#">Ver profesionales</a>
                </div>
            </div>
            <div class="services box1 col-6 col-md-2">
                <div class="header-img">
                    <a href="#">
                        <img src="./assets/img/servicios/carpinteria.svg" alt="" width="50">
                    </a>
                </div>
                <div class="body-content">
                    <h5>
                        <a href="#">
                            Carpinteria
                        </a>
                    </h5> <a href="#">Ver profesionales</a>
                </div>

            </div>
            <div class="services box1 col-6 col-md-2">
                <div class="header-img">
                    <a href="#">
                        <img src="./assets/img/servicios/aire_acondicionado.svg" alt="" width="50">
                    </a>
                </div>
                <div class="body-content">
                    <h5>
                        <a href="#">
                            Aire <br> acondicionado
                        </a>
                    </h5> <a href="#">Ver profesionales</a>
                </div>
            </div>
            <div class="services box1 col-6 col-md-2">
                <div class="header-img">
                    <a href="#">
                        <img src="./assets/img/servicios/mudanzas.svg" alt="" width="50">
                    </a>
                </div>
                <div class="body-content">
                    <h5>
                        <a href="#">
                            Trasteos
                        </a>
                    </h5> <a href="#">Ver profesionales</a>
                </div>
            </div>
            <div class="services box1 col-6 col-md-2">
                <div class="header-img">
                    <a href="#">
                        <img src="./assets/img/servicios/albanil.svg" alt="" width="50">
                    </a>
                </div>
                <div class="body-content">
                    <h5>
                        <a href="#">
                            Albañil
                        </a>
                    </h5> <a href="#">Ver profesionales</a>
                </div>
            </div>
            <div class="services box1 col-6 col-md-2">
                <div class="header-img">
                    <a href="#">
                        <img src="./assets/img/servicios/venta.svg" alt="" width="50">
                    </a>
                </div>
                <div class="body-content">
                    <h5>
                        <a href="#">
                            Venta
                        </a>
                    </h5> <a href="#">Ver profesionales</a>
                </div>
            </div>
            <div class="services box1 col-6 col-md-2">
                <div class="header-img">
                    <a href="#">
                        <img src="./assets/img/servicios/carpinteria.svg" alt="" width="50">
                    </a>
                </div>
                <div class="body-content">
                    <h5>
                        <a href="#">
                            Carpinteria
                        </a>
                    </h5> <a href="#">Ver profesionales</a>
                </div>

            </div>
            <div class="services box1 col-6 col-md-2">
                <div class="header-img">
                    <a href="#">
                        <img src="./assets/img/servicios/aire_acondicionado.svg" alt="" width="50">
                    </a>
                </div>
                <div class="body-content">
                    <h5>
                        <a href="#">
                            Aire <br> acondicionado
                        </a>
                    </h5> <a href="#">Ver profesionales</a>
                </div>
            </div>
            <div class="services box1 col-6 col-md-2">
                <div class="header-img">
                    <a href="#">
                        <img src="./assets/img/servicios/mudanzas.svg" alt="" width="50">
                    </a>
                </div>
                <div class="body-content">
                    <h5>
                        <a href="#">
                            Trasteos
                        </a>
                    </h5> <a href="#">Ver profesionales</a>
                </div>
            </div>
            <div class="services box1 col-6 col-md-2">
                <div class="header-img">
                    <a href="#">
                        <img src="./assets/img/servicios/albanil.svg" alt="" width="50">
                    </a>
                </div>
                <div class="body-content">
                    <h5>
                        <a href="#">
                            Albañil
                        </a>
                    </h5> <a href="#">Ver profesionales</a>
                </div>
            </div>
            <div class="services box1 col-6 col-md-2">
                <div class="header-img">
                    <a href="#">
                        <img src="./assets/img/servicios/venta.svg" alt="" width="50">
                    </a>
                </div>
                <div class="body-content">
                    <h5>
                        <a href="#">
                            Venta
                        </a>
                    </h5> <a href="#">Ver profesionales</a>
                </div>
            </div>
            <div class="services box1 col-6 col-md-2">
                <div class="header-img">
                    <a href="#">
                        <img src="./assets/img/servicios/carpinteria.svg" alt="" width="50">
                    </a>
                </div>
                <div class="body-content">
                    <h5>
                        <a href="#">
                            Carpinteria
                        </a>
                    </h5> <a href="#">Ver profesionales</a>
                </div>

            </div>
        </div>
    </div>
</section>
-->

<!--
<section class="do_service text-white position-relative">
    <div class="container h-100">
        <div class="row h-100 align-content-center">
            <div class="col-md-6">
                <h2 class="text-mega-bold">¿Quieres prestar Tus servicios?</h2>
                <p class=" mt-2 mb-4 ">En vivienda.com estamos en búsqueda de nuevos servicios. Haz parte de nuestro
                    servicios y consigue nuevos clientes fácil, rapido y gratis</p>
                <a href="/usuario/perfil?tab=services"
                    class=" d-inline-flex justify-content-center align-items-center text-white  btn-yellow text-bold  text-color-white text-center py-3 px-3"
                    role="button" aria-expanded="false" aria-controls="categories">
                    Comienza ahora
                </a>
            </div>
        </div>
    </div>
</section>
 -->
