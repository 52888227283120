import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  ElementRef,
  ViewChild,
  ViewChildren,
  NgZone,
} from '@angular/core';
import { ApiConnectionService } from 'src/app/services/api-connection.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AgmInfoWindow, AgmMap, MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { HomeService } from 'src/app/services/home/home.service';
import { isPlatformBrowser } from '@angular/common';
import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { FormControl, FormGroup } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalPropertyComponent } from '../modal-propertie/modal-propertie.component';
import { TokenService } from 'src/app/services/auth/token.service';
import { RecaptchaComponent } from 'ng-recaptcha';
declare const $: any;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit {
  loader: boolean = true;
  properties: any = [];
  estrato: any = '';
  tiposInmuebles = '';
  tipoInmueble = {
    finca: false,
    casa: false,
    edificio: false,
    bodega: false,
    lote: false,
    apartamento: false,
    local: false,
    oficina: false,
    consultorio: false,
  };
  currency:string = localStorage.getItem('currency');
  tipoOferta = '';
  city: any = null;
  browser = false;
  arriendo = false;
  venta = false;
  type_properties = [
    {
      type_property: 'Finca',
      slug: 'finca',
      image: 'https://cdn.vivienda.com/images/icons/finca.png',
    },
    {
      type_property: 'Casa',
      slug: 'casa',
      image: 'https://cdn.vivienda.com/images/icons/casa.png',
    },
    {
      type_property: 'Edificio',
      slug: 'edificio',
      image: 'https://cdn.vivienda.com/images/icons/edificio.png',
    },
    {
      type_property: 'Bodega',
      slug: 'bodega',
      image: 'https://cdn.vivienda.com/images/icons/bodega.png',
    },
    {
      type_property: 'Lote',
      slug: 'lote',
      image: 'https://cdn.vivienda.com/images/icons/lote.png',
    },
    {
      checked: true,
      type_property: 'Apartamento',
      slug: 'apartamento',
      image: 'https://cdn.vivienda.com/images/icons/apartamento.png',
    },
    {
      type_property: 'Local',
      slug: 'local',
      image: 'https://cdn.vivienda.com/images/icons/local.png',
    },
    {
      type_property: 'Consultorio',
      slug: 'consultorio',
      image: 'https://cdn.vivienda.com/images/icons/consultorio.png',
    },
    {
      type_property: 'Oficina',
      slug: 'oficina',
      image: 'https://cdn.vivienda.com/images/icons/oficina.png',
    },
  ];
  next = null;
  keyword = null;
  search = '';
  quantity = 0;
  habitaciones = [false, false, false, false, false, false];
  banos = [false, false, false, false, false, false];
  parking = [false, false, false, false, false, false];
  timeselected = 'Entre 0 a 5 años';
  lat: number = 4.6799359;
  lng: number = -74.1603453;
  zoom = 13;
  radius = 2000;
  distance = 20;
  currentUri:string;
  private geoCoder;

  currentIW: AgmInfoWindow = null;
  previousIW: AgmInfoWindow = null;
  disableSearch: boolean = false;
  // @ViewChildren('search')
  @ViewChild('DesignModal') modalHijo: ModalPropertyComponent;
  public searchElementRef: ElementRef;
  map;
  circle;
  offer;
  report = '';
  uri = '/busqueda';

  timeOut: any;
  location = [
    { lat: 0, lng: 0 },
    { lat: 0, lng: 0 },
  ];
  slug = null;
  ip = null;

  price = {
    min: 100000,
    max: 5000000,
    active: false,
  };
  sliderFormPriceRange: FormGroup = new FormGroup({
    sliderControlPriceRange: new FormControl([100000, 5000001]),
  });
  optionsPriceRange: Options = {
    floor: 100000,
    ceil: 5000001,
    step: 100000,
    draggableRange: true,
    translate: (value: any): string => {
      return value <= 5000000
        ? parseInt(value).toLocaleString('es-CO', {
            style: 'currency',
            currency: this.currency,
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })
        : '+ 5.000.000' +this.currency;
    },
  };

  priceVenta = {
    min: 10000000,
    max: 500000000,
    active: false,
  };
  sliderFormPriceRangeVenta: FormGroup = new FormGroup({
    sliderControlPriceRangeVenta: new FormControl([10000000, 500000001]),
  });
  optionsPriceRangeVenta: Options = {
    floor: 10000000,
    ceil: 500000001,
    step: 5000000,
    draggableRange: true,
    translate: (value: any): string => {
      return value <= 500000000
        ? parseInt(value).toLocaleString('es-CO', {
            style: 'currency',
            currency: 'COP',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })
        : '+ 500.000.000'+ this.currency;
    },
  };
  rangeTimeout = null;

  area = {
    min: 5,
    max: 500,
    active: false,
  };
  sliderFormAreaRange: FormGroup = new FormGroup({
    sliderControlAreaRange: new FormControl([5, 501]),
  });
  optionsAreaRange: Options = {
    floor: 5,
    ceil: 501,
    step: 5,
    draggableRange: true,
    translate: (value: any): string => {
      return value > 500 ? '+ 500' : value;
    },
  };

  antiquity = '';

  characteristics = [
    {
      name: 'Zona para niños',
      option: 'common_zones',
      slug: 'zona-ninos',
      checked: false,
    },
    {
      name: 'Acceso para discapacitados',
      option: 'exteriors',
      slug: 'discapacitados',
      checked: false,
    },
    { name: 'Piscina', option: 'exteriors', slug: 'piscina', checked: false },
    {
      name: 'Zonas verdes',
      option: 'common_zones',
      slug: 'zonas-verdes',
      checked: false,
    },
    {
      name: 'Zona de juegos',
      option: 'exteriors',
      slug: 'juegos',
      checked: false,
    },
    { name: 'Portería', option: 'exteriors', slug: 'porteria', checked: false },
    {
      name: 'Cuarto de servicio',
      option: 'common_zones',
      slug: 'cuarto-servicio',
      checked: false,
    },
    {
      name: 'Con chimenea',
      option: 'interiors',
      slug: 'chimeneas',
      type: 'chimenea',
      checked: false,
    },
    {
      name: 'Cuarto de estudio',
      option: 'interiors',
      slug: 'cuarto-estudio',
      checked: false,
    },
  ];
  nearby_sites = [
    { name: 'Colegios', slug: 'colegios', checked: false },
    { name: 'Universidades', slug: 'universidades', checked: false },
    { name: 'Transporte público', slug: 'transporte', checked: false },
    { name: 'Centros comerciales', slug: 'cc', checked: false },
    { name: 'Gimnasio', slug: 'gimnasio', checked: false },
    { name: 'Supermercados', slug: 'market', checked: false },
    { name: 'Hospitales', slug: 'hospitales', checked: false },
    { name: 'Parques', slug: 'parques', checked: false },
  ];

  activeMarker = false;

  showMap = false;
  lists = [];
  list = {
    name: '',
    description: ''
  };
  listSelected = null;
  propertySelected:any;
  session:any;
  captcha;
  @ViewChild('captchaRef')
  public captchaRef: RecaptchaComponent;
  @ViewChild('captchaRefModalReport')
  public captchaRefModalReport: RecaptchaComponent;
  showCaptcha = false;
  constructor(
    protected mapsAPILoader: MapsAPILoader,
    protected ngZone: NgZone,
    protected apiService: ApiConnectionService,
    protected httpClient: HttpClient,
    private http: ApiConnectionService,
    protected requestHttp: HttpClient,
    protected homeService: HomeService,
    private metaTagService: Meta,
    private title: Title,
    private router: Router,
    protected tokenService: TokenService,

    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.browser = isPlatformBrowser(platformId);
    this.uri = location?.pathname;
    this.session = this.tokenService.isLoggedIn();
    this.parseUriItems();
    this.title.setTitle('Encuentra tu inmueble | Vivienda.com');
    this.metaTagService.addTags([
      { name: 'title', content: this.title.getTitle() },
      { name: 'description', content: 'Encuentra apartamentos, casas, oficinas, bodegas, fincas, lotes y más inmuebles en venta o arriendo. Vivienda.com el sitio web para comprar, vender y arrendar finca raíz totalmente gratis.' },
      { name: 'og:title', content: this.title.getTitle() },
      { name: 'og:description', content: 'Encuentra apartamentos, casas, oficinas, bodegas, fincas, lotes y más inmuebles en venta o arriendo. Vivienda.com el sitio web para comprar, vender y arrendar finca raíz totalmente gratis.' },
      { name: 'og:url', content: this.router.url }
    ]);
  }

  ngOnInit(): void {
    if (this.browser) {
      const lat = new URLSearchParams(window.location.search).get('lat');
      const lng = new URLSearchParams(window.location.search).get('lng');
      if (lat && lng) {
        this.lat = lat ? parseFloat(lat) : this.lat;
        this.lng = lng ? parseFloat(lng) : this.lng;
      } else this.setCurrentPosition();

      $(document).ready(() => {

        let ck = document.cookie.split(';');
        let modalSg = true;

        for( var i = 0; i < ck.length; i++) {
          let subel = ck[i].split('=');
          if (subel[1] == 'modalSuggestActive') {
            modalSg = false;
            break;
          } else {

          }
        }

        if (modalSg) {
            setTimeout(() => {
              $('#suggestModalLong').modal('show');

              var date = new Date();
              date.setTime(date.getTime()+(24*60*60*1000));
              document.cookie = 'modalSuggest=modalSuggestActive;expires=' + date.toUTCString()  + '; path=/';
            }, 60000);
          }


        if ($(window).width() > 767) {
          this.showMap = true;
        }
        // $('body').click(function (event) {
        //   if (
        //     !$(event.target).hasClass('no-close') &&
        //     $(event.target).attr('data-toggle') !== 'collapse' &&
        //     !$(event.target).is('img') &&
        //     !$(event.target).is('label')
        //   )
        //     setTimeout(() => {
        //       $('.collapse').removeClass('show');
        //     }, 100);
        // });

        setTimeout(() => {
          $('.ngx-slider-floor, .ngx-slider-ceil').addClass('d-none');
          $('.ngx-slider > span').each(function () {
            $(this).addClass('no-close');
          });
        }, 500);

        $(window).resize(() => {
          if ($(window).width() > 767 && this.showMap) return;
          if ($(window).width() > 767) this.showMap = true;
          else if (this.showMap) this.showMap = false;
        });

      });
    }


  }

  setCurrentPosition(): void {
    let loc = JSON.parse(localStorage.getItem('recent_locations'));
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          this.zoom = 13;
          loc = { lat: this.lat, lng: this.lng, zoom: this.zoom };
          localStorage.setItem('recent_locations', JSON.stringify(loc));
          var date = new Date();
          date.setDate(date.getDate() + 365);
          document.cookie =
            'recent_locations = ' + JSON.stringify(loc) + '; expire = ' + date;
        },
        (error) => {
          this.lat = 4.6799359;
          this.lng = -74.1603453;
          this.zoom = 13;
        }
      );
    } else {
      this.lat = 4.6799359;
      this.lng = -74.1603453;
      this.zoom = 13;
    }
  }

  changeButton(type){
    if(type == 'arriendo') this.arriendo = !this.arriendo;
    if(type == 'venta') this.venta = !this.venta;
    if(this.arriendo && !this.venta ) this.tipoOferta = 'arriendo'
    if(this.venta && !this.arriendo) this.tipoOferta = 'venta'
    if(this.venta && this.arriendo) this.tipoOferta = null
    if(!this.venta && !this.arriendo) this.tipoOferta = null
    this.register()
  }

  register(): void {
    this.properties = [];
    this.next = null;
    this.currentIW = null;
    this.previousIW = null;
    setTimeout(() => this.searchItems(), 100);
    setTimeout(() => this.updateMetaTags(), 500);
  }

  searchItems() {
    if (!this.activeMarker && !this.disableSearch) {
      this.previousIW = null;
      this.currentIW = null;
      if (this.browser) this.loader = true; //$('#inmuebles > .spinner-border').removeClass('d-none');
      this.properties = [];
      const data = this.buildQuery();

      this.apiService
        .postData('busqueda/properties?thumbs=true', data, { auth: true })
        .subscribe((response: any) => {
          this.properties = response;
          //console.log('propiedades opara map', this.properties);
          
          this.properties.sort((a, b) => b.featured - a.featured);
          // if (this.browser)
            //$('#inmuebles > .spinner-border').addClass('d-none');
          this.loader = false;
          this.quantity = response.length;
        });
    }
  }

  clearFilters() {
    for (var i in this.tipoInmueble) {
      this.tipoInmueble[i] = false;
    }
    this.estrato = '';
    this.tipoOferta = '';
    this.city = '';
  }

  buildBodyRequest() {
    setTimeout(() => {
      let uri = '';
      let typeProperty = '';
      for (var i in this.tipoInmueble) {
        if (this.tipoInmueble[i]) typeProperty += i + '-';
      }
      typeProperty = typeProperty.toString().slice(0, -1);
      const typeOffer = this.tipoOferta;
      const offer = this.offer;
      const estrato = this.estrato;
      const keyword = this.keyword;
      const city = this.city;
      if (typeProperty.length > 0) uri += '&typeProperty=' + typeProperty;
      if (typeOffer) uri += '&typeOffer=' + typeOffer;
      if (offer) uri += '&offer=' + offer;
      if (estrato) uri += '&estrato=' + estrato;
      if (keyword && keyword.trim() !== '') uri += '&keyword=' + keyword;
      if (this.lat && this.lng)
        uri +=
          '&lat=' +
          this.lat +
          '&lng=' +
          this.lng +
          (city ? '&city=' + city : '');
      if (this.price.active || this.priceVenta.active)
        uri +=
          '&price=' +
          (this.tipoOferta !== 'venta'
            ? this.price.min + '-' + this.price.max
            : this.priceVenta.min + '-' + this.priceVenta.max);
      if (this.area.active)
        uri += '&area=' + this.area.min + '-' + this.area.max;
      if (this.antiquity){
        if(typeof this.antiquity == 'string' && this.antiquity.trim() != ''){
          uri += '&antiquity=' + this.antiquity;
        }else{
          uri += '&antiquity=' + this.antiquity[0]+","+this.antiquity[1];
        }
      }

      var habs = [];
      this.habitaciones.forEach((v, i) => {
        if (v) habs.push(i == 5 ? '+5' : (i + 1).toString());
      });
      if (habs.length > 0) uri += '&habitaciones=' + habs.join('-');

      var banos = [];
      this.banos.forEach((v, i) => {
        if (v) banos.push(i == 5 ? '+5' : (i + 1).toString());
      });
      if (banos.length > 0) uri += '&banos=' + banos.join('-');

      var parking = [];
      this.parking.forEach((v, i) => {
        if (v) parking.push(i == 5 ? '+5' : (i + 1).toString());
      });
      if (parking.length > 0) uri += '&parqueaderos=' + parking.join('-');

      var characteristics = [];
      this.characteristics.forEach((v) => {
        if (v.checked) characteristics.push(v.slug);
      });
      if (characteristics.length > 0)
        uri += '&caracteristicas=' + characteristics.join('|');

      var sitios = [];
      this.nearby_sites.forEach((v) => {
        if (v.checked) sitios.push(v.slug);
      });
      if (sitios.length > 0) uri += '&sitios=' + sitios.join('|');
      const slug = this.buildSlug(
        typeProperty,
        typeOffer,
        city,
        estrato,
        offer
      );
      const title = this.buildPageTitle(
        typeProperty,
        typeOffer,
        city,
        estrato,
        offer
      );

      if (this.browser)
        window.history.pushState(
          {},
          title + ' | Vivienda.com',
          '/busqueda?source=seeker&search=true' +
            uri +
            (slug ? '&slug=' + slug : '')
        );
      this.saveParamsSearch();
    }, 100);
  }

  buildPageTitle(typeProperty, typeOffer, city, estrato, offer) {
    let title = '';
    typeOffer = typeOffer == 'arriendo' ? typeOffer : 'venta';
    city = city
      ? this.homeService.clearString(city.split(' ').join('-')).toLowerCase()
      : null;
    if (typeProperty.length > 0) title += typeProperty.split('-').join(' ');
    if (typeOffer) title += title.length > 0 ? ' en ' + typeOffer : typeOffer;
    if (offer) title += title.length > 0 ? ' ' + offer : '';
    if (estrato) title += title.length > 0 ? ' Estrato ' + estrato : '';
    if (city) title += title.length > 0 ? ' en ' + city : city;

    return title;
  }

  buildSlug(typeProperty, typeOffer, city, estrato, offer) {
    let slug = '';
    typeOffer = typeOffer
      ? typeOffer == 'arriendo'
        ? typeOffer
        : 'venta'
      : null;
    city = city
      ? this.homeService.clearString(city.split(' ').join('-')).toLowerCase()
      : null;
    if (typeProperty.length > 0) slug += typeProperty;
    if (typeOffer) slug += slug.length > 0 ? '-en-' + typeOffer : typeOffer;
    if (offer) slug += slug.length > 0 ? '-' + offer : '';
    if (estrato) slug += slug.length > 0 ? '-estrato-' + estrato : '';
    if (city) slug += slug.length > 0 ? '-en-' + city : city;
    this.slug = slug;
    return slug;
  }

  buildQuery() {
    let datos = {};

    if (this.keyword && this.keyword.trim() != '')
      datos['keyword'] = this.keyword;

    if (this.lat && this.lng) {
      if (this.location[0].lat != 0) {
        datos['lat'] = [this.location[0].lat, this.location[1].lat];
        datos['lng'] = [this.location[0].lng, this.location[1].lng];
      } else {
        datos['lat'] = this.lat;
        datos['lng'] = this.lng;
        datos['distance'] = this.distance;
      }
      let current_search = []
      current_search = JSON.parse(localStorage.getItem('current_search'));
      if(!current_search){
            current_search = [{
                "lat" : datos['lat'],
                "lan": datos['lng'],
            }];
      }else{
        current_search.push({
          "lat" : datos['lat'],
          "lan": datos['lng'],
      });
      }
      console.log('ajustando ubicación de busqueda');
      datos['location'] = {
        lat : this.lat,
        lng : this.lng,
        distance : this.distance
      };

      localStorage.setItem('current_search',JSON.stringify(current_search));
    }
    let tiposInmuebles = [];
    for (var i in this.tipoInmueble) {
      if (this.tipoInmueble[i]) tiposInmuebles.push(i);
    }
    if (tiposInmuebles.length > 0) datos['tiposInmueble'] = tiposInmuebles;

    if (this.tipoOferta) {
      datos['tipoOferta'] =
        this.tipoOferta == 'arriendo' ? this.tipoOferta : 'venta';
    }
    if (this.offer) {
      datos['offer'] = this.offer;
    }
    if (this.estrato) datos['estrato'] = parseInt(this.estrato.toString());

    if (this.price.active || this.priceVenta.active) {
      var amount = {};
      amount = {
        gte: this.tipoOferta !== 'venta' ? this.price.min : this.priceVenta.min,
        lte: this.tipoOferta !== 'venta' ? this.price.max : this.priceVenta.max,
      };
      if (this.price.max == 5000001 || this.priceVenta.max == 500000001) {
        amount = {
          gte:
            this.tipoOferta !== 'venta' ? this.price.min : this.priceVenta.max,
        };
      }
      datos['amount'] = amount;
    }
    if (this.area.active) {
      var area = {};
      area = {
        gte: this.area.min,
        lte: this.area.max,
      };
      if (this.area.max == 501) {
        area = {
          gte: this.area.min,
        };
      }
      datos['area'] = area;
    }

    if (this.antiquity) {
      let antiquity;
      var antiquity1 = {};
      if(typeof this.antiquity == 'string'){
        antiquity = this.antiquity.split(',');
      }else{
        antiquity = this.antiquity
      }
      if (antiquity[1])
        antiquity1 = {
          gte: parseInt(antiquity[0]),
          lte: parseInt(antiquity[1]),
        };
      else
        antiquity1 = {
          gte: parseInt(antiquity[0]),
        };

      datos['antiquity'] = antiquity1;
    }

    datos['limit'] = 100;
    datos['habitaciones'] = this.habitaciones;
    datos['banos'] = this.banos;
    datos['parking'] = this.parking;
    datos['characteristics'] = this.characteristics;

    var ns = [];
    this.nearby_sites.forEach((v, i) => {
      if (v.checked) ns.push(v.slug);
    });
    if (ns.length > 0) datos['nearby_sites'] = ns;

    this.buildBodyRequest();
    this.typePropertyContent();
    return datos;
  }

  saveSeeker() {
    $('#saveSeeker').css('width', '31%');
    $('#saveSeeker').attr('disabled', true);
    $('#saveSeeker > p').addClass('d-none');
    $('#saveSeeker > span').removeClass('d-none');
    const data = {
      quantity: this.quantity,
      slug: this.slug,
      name: (
        this.tiposInmuebles +
        ' ' +
        (this.tipoOferta ? 'en ' + this.tipoOferta : '')
      ).trim(),
      location: (this.city ? this.city : '').trim(),
      query: window.location.pathname + window.location.search,
    };
    this.http.postData('user/search', data, { auth: true }).subscribe(
      (response) => {
        Swal.fire(
          'Se ha agregado la búsqueda a búsquedas guardadas',
          '',
          'success'
        ).then(() => {
          $('#saveSeeker').css('width', '30%');
          $('#saveSeeker').attr('disabled', false);
          $('#saveSeeker > p').removeClass('d-none');
          $('#saveSeeker > span').addClass('d-none');
        });
      },
      (error) => {
        if (error.status == 401) {
          Swal.fire(
            'Para poder realizar esta función debes iniciar sesión',
            '',
            'warning'
          ).then(() => {
            $('#saveSeeker').css('width', '30%');
            $('#saveSeeker').attr('disabled', false);
            $('#saveSeeker > p').removeClass('d-none');
            $('#saveSeeker > span').addClass('d-none');
          });
        } else {
          Swal.fire(
            'En este momento no podemos atender tu solicitud, intenta más tarde',
            '',
            'warning'
          ).then(() => {
            $('#saveSeeker').css('width', '30%');
            $('#saveSeeker').attr('disabled', false);
            $('#saveSeeker > p').removeClass('d-none');
            $('#saveSeeker > span').addClass('d-none');
          });
        }
      }
    );
  }

  getFormatImage(image, size, type = 'image', forceFormat = null) {
    if (type == 'image') {
      var file = 'https://cdn.vivienda.com/images/properties/no-disponible.jpg';
      if (typeof image.thumbs !== 'undefined' && image.thumbs.length > 0) {
        //console.log(image.thumbs);
        
        const index = image.thumbs.findIndex((v) => v.size == size);        
        file = index > -1 ? image.thumbs[index].image : image.thumbs[0].image
        //if (index > -1) file = image.thumbs[index].image ;
      } else {
        var extension = image.path.substr(-3);
        if (!this.isAvailableImageFormat(extension)) {
          extension = image.path.substr(-4);
          if (!this.isAvailableImageFormat(extension)) {
            return file;
          }
        }
        if (!forceFormat)
          file =
            image.path.replace('.' + extension, '') +
            '-' +
            size +
            '.' +
            extension;
        else
          file =
            image.path.replace('.' + extension, '') +
            '-' +
            size +
            '.' +
            forceFormat;
      }


      return file;
    } else if (type == 'video') return this.getMimeVideo(image);

    return image.path;
  }

  getMimeVideo(video) {
    try {
      var host = new URL(video.path);
      switch (host.hostname) {
        case 'www.youtube.com':
          let base = host.pathname.split('/');
          return 'https://i.ytimg.com/vi/' + base[2] + '/mqdefault.jpg';
          break;
      }
    } catch (e) {
      return 'https://i.ytimg.com/';
    }
  }

  isAvailableImageFormat(format): boolean {
    const formats = ['jpeg', 'jpg', 'png', 'jpe', 'jfif','gif','webp'];
    return formats.includes(format);
  }

  updateMetaTags() {
    var descripcionbuqueda = 'Busqueda';
    if (this.tiposInmuebles) {
      descripcionbuqueda =
        descripcionbuqueda + 'de ' + this.tiposInmuebles + ' ';
    }
    if (this.tipoOferta) {
      descripcionbuqueda = descripcionbuqueda + 'en ' + this.tipoOferta + ' ';
    }
    if (this.city) {
      descripcionbuqueda =
        descripcionbuqueda + 'en la ciudad de ' + this.city + ' ';
    }
    if (this.estrato) {
      descripcionbuqueda = descripcionbuqueda + 'estrato ' + this.estrato + ' ';
    }
    if (this.keyword) {
      descripcionbuqueda =
        descripcionbuqueda + 'con plabra clave ' + this.keyword + ' ';
    }
    if (this.area.min) {
      descripcionbuqueda =
        descripcionbuqueda +
        'con area minima de ' +
        String(this.area.min) +
        ' ';
    }
    if (this.area.max) {
      descripcionbuqueda =
        descripcionbuqueda +
        'con area maxima de ' +
        String(this.area.max) +
        ' ';
    }
    if (this.price.min) {
      descripcionbuqueda =
        descripcionbuqueda +
        'con precio minimo de ' +
        String(this.price.min) +
        ' ';
    }
    if (this.price.max) {
      descripcionbuqueda =
        descripcionbuqueda +
        'con precio maximo de ' +
        String(this.price.max) +
        ' ';
    }
    if (descripcionbuqueda == '') {
      descripcionbuqueda = 'Busqueda de tu inmueble ideal';
    }
    var keywords;
    if (this.slug) {
      keywords = this.slug.replace(/-en/gi, '');
      keywords = keywords.replace(/-/gi, ', ');
    } else {
      keywords =
        'vivienda, apartamento, arriendo, inmueble, bienes raices, residencia, domicilio, casa, habitación';
    }

    this.metaTagService.updateTag(
      { name: 'og:description', content: descripcionbuqueda },
      `name='og:description'`
    );
    this.metaTagService.updateTag(
      { name: 'description', content: descripcionbuqueda },
      `name='description'`
    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: keywords },
      `name='keywords'`
    );
  }

  parseUriItems() {
    const params = new URLSearchParams(window.location.href);
    const typeProperty = params.get('typeProperty');
    if (typeProperty)
      typeProperty.split('-').forEach((v) => {
        if (this.tipoInmueble[v] !== undefined) {
          this.tipoInmueble[v] = true;
          this.tiposInmuebles += v.charAt(0).toUpperCase() + v.slice(1) + ', ';
        }
      });
    this.tiposInmuebles = this.tiposInmuebles.slice(0, -2);
    let oferta = params.get('typeOffer');
    if(oferta == "arriendo"){
          this.arriendo = true;
          this.tipoOferta = 'arriendo';
    }else if(oferta == "venta"){
      this.venta = true;
      this.tipoOferta = 'venta';
    }else{
      this.arriendo = true;
      this.venta = true;
      this.tipoOferta = null;
    }
    // this.tipoOferta = params.get('typeOffer')
    //   ? params.get('typeOffer') != 'arriendo'
    //     ? 'venta'
    //     : 'arriendo'
    //   : null;
    this.city = params.get('city');
    this.estrato = params.get('estrato')
      ? parseInt(params.get('estrato'))
      : null;
    this.keyword = params.get('keyword');
    this.zoom = params.get('source') == 'nav' ? 12 : this.zoom;
    this.slug = params.get('slug');

    var area = params.get('area');
    if (area) {
      const areas = area.split('-');
      setTimeout(() => {
        (this.area.min = parseInt(areas[0])),
          (this.area.max = parseInt(areas[1])),
          (this.area.active = true);
      }, 100);
    }
    var price = params.get('price');
    if (price) {
      const prices = price.split('-');
      setTimeout(() => {
        this.price.min = parseInt(prices[0]);
        this.price.max = parseInt(prices[1]);
        this.price.active = true;
      }, 100);
    }
    this.slug = params.get('slug');
    this.antiquity = params.get('antiquity') || '';
    if (params.get('habitaciones')) {
      params
        .get('habitaciones')
        .split('-')
        .forEach((v) => {
          this.habitaciones[v == '+5' ? 5 : parseInt(v) - 1] = true;
        });
    }

    if (params.get('banos')) {
      params
        .get('banos')
        .split('-')
        .forEach((v) => {
          this.banos[v == '+5' ? 5 : parseInt(v) - 1] = true;
        });
    }

    if (params.get('parqueaderos')) {
      params
        .get('parqueaderos')
        .split('-')
        .forEach((v) => {
          this.parking[v == '+5' ? 5 : parseInt(v) - 1] = true;
        });
    }

    if (params.get('caracteristicas')) {
      params
        .get('caracteristicas')
        .split('|')
        .forEach((v) => {
          const index = this.characteristics.findIndex((i) => i.slug == v);
          if (index > -1) this.characteristics[index].checked = true;
        });
    }

    if (params.get('sitios')) {
      params
        .get('sitios')
        .split('|')
        .forEach((v) => {
          const index = this.nearby_sites.findIndex((i) => i.slug == v);
          if (index > -1) this.nearby_sites[index].checked = true;
        });
    }
    this.updateMetaTags();
    if (this.browser) {
      this.register();
    }
  }

  markerClick(infoWindow) {
    if (this.previousIW) {
      this.currentIW = infoWindow;
      this.previousIW.close();
    }
    this.previousIW = infoWindow;
    this.activeMarker = true;
    setTimeout(() => (this.activeMarker = false), 1000);
  }

  getArea(property) {
    let index = property.areas.findIndex(
      (val) =>
        val.description == 'Área total' ||
        val.description == 'area_total' ||
        val.area_total !== undefined
    );
    if (index >= 0) {
      return (
        'Área total: ' +
        (property.areas[index].area_total
          ? property.areas[index].area_total
          : property.areas[index].area)
      );
    }
    return '';
  }

  getRandomNumber(value) {
    const random = Math.floor(Math.random() * 10 + 5);
    const result = (
      value.toString().slice(0, value.length - 3) +
      (random.toString() + 50)
    ).toString();
    return result;
  }

  onMapReady(map: AgmMap) {
    this.map = map;
    this.map.setOptions({
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: true,
      minZoom: 3,
      maxZoom: 18,
      styles: [
        {
          featureType: 'administrative',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'transit',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'poi.attraction',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'poi.business',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'poi.government',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'poi.medical',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'poi.place_of_worship',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'poi.school',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'poi.sports_complex',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'landscape.man_made',
          stylers: [{ visibility: 'on' }, { color: '#fce8f0' }],
        },
        {
          featureType: 'landscape.natural',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'landscape.natural',
          elementType: 'geometry',
          stylers: [{ color: '#fce8f0' }, { visibility: 'on' }],
        },
        {
          featureType: 'water',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
      ],
    });
  }

  boundsChange(event) {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = null;
    }
    this.timeOut = setTimeout(() => {
      this.location = [
        { lat: event.Ab.h, lng: event.Ra.g },
        { lat: event.Ab.g, lng: event.Ra.h },
      ];
      this.searchItems();
      this.timeOut = null;
    }, 500);
  }

  typePropertyContent() {
    setTimeout(() => {
      let content = '';
      for (var i in this.tipoInmueble)
        if (this.tipoInmueble[i])
          content += i.charAt(0).toLocaleUpperCase() + i.slice(1) + ', ';

      if (content.slice(-2, content.length) == ', ')
        content = content.slice(0, -2);
      this.tiposInmuebles = content;
    }, 100);
  }

  saveParamsSearch() {
    if (this.browser && this.slug) {
      if (!this.ip) {
        fetch('https://ip.nf/me.json')
          .then((response) => response.json())
          .then((val: any) => {
            this.ip = val.ip.ip;
            this.saveParamsSearch();
          });
      } else {
        const data = {
          slug: this.slug,
          name: (
            this.tiposInmuebles +
            ' ' +
            (this.tipoOferta ? 'en ' + this.tipoOferta : '') +
            (this.city ? +this.city : '')
          ).trim(),
          ip: this.ip,
          query: window.location.pathname + window.location.search,
        };
        this.http.postData('busqueda', data, { auth: false }).subscribe(
          (response) => {},
          (error) => {}
        );
      }
    }
  }

  onUserChangeEnd(type = 'price'): void {
    this[type].active = true;
    if (this.rangeTimeout) {
      clearTimeout(this.rangeTimeout);
      this.rangeTimeout = null;
    }
    this.rangeTimeout = setTimeout(() => {
      this.register();
      this.rangeTimeout = null;
    }, 1500);
  }

  resetModalFilters() {
    this.antiquity = '';
    this.estrato = '';
    this.habitaciones.forEach((v, i) => {
      this.habitaciones[i] = false;
    });
    this.banos.forEach((v, i) => {
      this.banos[i] = false;
    });
    this.parking.forEach((v, i) => {
      this.parking[i] = false;
    });
    this.characteristics.forEach((v, i) => {
      this.characteristics[i].checked = false;
    });
    this.nearby_sites.forEach((v, i) => {
      this.nearby_sites[i].checked = false;
    });
    this.register();
  }

  async propertyModal(propiedad: any) {
      this.disableSearch = true;
      await this.modalHijo.getProperty(propiedad.slug);

      this.lists = this.modalHijo.lists;

      const title = `${propiedad.type_property.type_property} en ${propiedad.type_offer} - ${propiedad.zone}, ${propiedad.city}`;
      const url = `/propiedad/${propiedad.slug}`;
      this.currentUri = window.location.pathname + window.location.search
      window.history.pushState(
        {},
        `${title} | Vivienda.com`,
        url
      );
      this.propertySelected = propiedad;
      $('#DesignModal').modal('show');
  }

  reportProperty() {
    if (this.captcha && this.captcha.trim() != '' && this.report && this.report.trim() !== '') {
      Swal.fire({
        title: 'Espera un momento...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onBeforeOpen: () => Swal.showLoading()
      });
      this.http.postData('property/report/' + this.propertySelected.slug, {
        reason: this.report,
        email: $("#report-modal-mail").val(),
        phone: $("#report-modal-phone").val(),
        commentary: $("#report-modal-comment").val(),
        'g-recaptcha-response': this.captcha
      }, {auth: false})
      .subscribe(
        response => {
          Swal.fire({
            title: response.message,
            icon: 'success'
          }).then(() => {
            this.clearModalReport();
          });
        },
        error => {
          this.captchaRefModalReport.reset();
          if (error.status == 401) {
            Swal.fire({
              title: 'Para poder continuar por favor confirma que no eres un robot',
              con: 'warning'
            });
          } else if (error.status == 400) {
            this.http.parseDefaultHttpResponse(error.error);
          } else if (error.status == 404) {
            Swal.fire({
              title: error.error.message,
              icon: 'error'
            });
          } else {
            Swal.fire({
              title: 'En este momento no podemos atender tu solictud, por favor inténtalo más tarde',
            });
          }
        }
      )
    }
  }

  clearModalReport() {
    this.captchaRefModalReport.reset();
    this.captcha = null;
    $('#step1').toggleClass('d-none');
    $('#step2').toggleClass('d-none');
    $("#report-modal-mail").val('');
    $("#report-modal-phone").val('');
    $("#report-modal-comment").val('');
    $("#report").modal('hide');
    this.report = '';
  }

  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
  }

  setProperties(properties: any): void {
    this.properties = properties;
    this.properties.sort((a, b) => b.featured - a.featured);  
    console.log('setproper');
    this.loader = false;
  }

}
