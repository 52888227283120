import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as Cookies from 'js-cookie';

@Injectable({
  providedIn: 'root'
})

export class TokenService {

  isLoginSubject = new BehaviorSubject<boolean>(this.isValidToken());

  constructor() { }

  handleData(token) {
    var date = new Date();
    date.setMonth(date.getMonth() + 72);
    document.cookie = "vivienda_com_auth_token="+token+";expires="+date+";domain=."+window.location.hostname+";path=/";
  }

  getToken() {
    return Cookies.get('vivienda_com_auth_token');
  }

  // Verify the token
  private isValidToken() {
    const token = this.getToken();
    if (token && token !== 'undefined' && token !== null && token !== undefined) {
      const payload = this.payload(token);
      if (payload) {
        return payload.iss.indexOf('user') > -1 ? true : false;
      }
    }
    return false;
  }

  private payload(token) {
    const jwtPayload = token.split('.')[1];
    return JSON.parse(atob(jwtPayload));
  }

   // User state based on valid token
  isLoggedIn() {
    return this.isValidToken();
  }

  // Remove token
  removeToken() {
    Cookies.remove('vivienda_com_auth_token', {
      path: '/',
      domain: '.' + window.location.hostname
    });
    Cookies.remove('auth_token');
    Cookies.remove('verified');
  }
}