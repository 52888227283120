<div class="container-fluid p-0">
    <div class="banner-cont d-none d-md-block mt-1 pt-5 pb-1" style="background-image: url(https://cdn.vivienda.com/images/contacts/banner.jpg); background-size: cover;">
        <!-- <img src="" alt="Contacto Vivienda.com"> -->
        <h1 class="text-yellow text-center text-bold mb-5 ">Contáctanos</h1>
    </div>
    <div class="container">
        <div class="row text-center mt-3 alig">
            <div class="col-12 d-block d-md-none">
                <h1 class="text-yellow text-bold mb-2">Contáctanos</h1>
            </div>
            <div class="col-md-4 text-center text-lg-left order-1 order-md-0 pt-lg-4 mt-lg-3">
                <h5 class="text-gray text-regular d-none">Nos tomamos muy en serio nuestro compromiso con nuestros usuarios. Si necesitas nuestra ayuda con tu cuenta de usuario, tienes preguntas sobre cómo utilizar la plataforma o tienes dificultades técnicas, no dudes en ponerte en contacto con nosotros.</h5>
                <hr class="hr-gray d-lg-none">
                <p class="text-color-black text-medium p-sm-90 font-weight-bold">
                    <img width="45px" src="https://cdn.vivienda.com/images/icons/contact-phone.png" class="mr-3">311 665 8875
                </p>
                <p class="text-color-black text-medium p-sm-90 font-weight-bold">
                    <img width="45px" src="https://cdn.vivienda.com/images/icons/contact-email.png" class="mr-3">contacto@vivienda.com
                </p>
                <p class="text-color-black text-medium p-sm-90 d-flex flex-row font-weight-bold">
                    <img width="45px" src="https://cdn.vivienda.com/images/icons/contact-location.png" alt="" class="mr-3">
                    <span>Cra. 14 #90 - 31 Oficina 204, Bogotá, Cundinamarca</span>
                </p>
            </div>
            <div class="col-md-8 order-0 order-md-1">
                <form action="#" #f="ngForm" (ngSubmit)="contact(f)" autocomplete="off">
                    <div class="form-row">
                        <div class="form-group mb-0 col-lg-6">
                            <input type="text" [(ngModel)]="user.name" name="name" class="px-4 w-100 input-border" id="name" placeholder="Nombre(s)" autocomplete="new-name" required>
                            <br>
                            <span *ngIf="f.submitted && f.controls['name'].errors" class="text-danger animated fadeIn">El nombre(s) es obligatorio</span>
                        </div>
                        <div class="form-group mb-0 col-lg-6">
                            <input type="text" [(ngModel)]="user.surname" name="surname" class="px-4 w-100 input-border" id="surname" placeholder="Apellido(s)" autocomplete="new-surname" required>
                            <span *ngIf="f.submitted && f.controls['surname'].errors" class="text-danger animated fadeIn">El apellido(s) es obligatorio</span>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mb-0 col-lg-6">
                            <input type="email" placeholder="Correo electrónico" [(ngModel)]="user.email" name="email" class="px-4 w-100 input-border" id="email" autocomplete="new-email" required>
                            <span *ngIf="f.submitted && f.controls['email'].errors" class="text-danger animated fadeIn">El correo electrónico es obligatorio</span>
                        </div>
                        <div class="form-group mb-0 mt-3 col-lg-6">
                            <input type="text" placeholder="Teléfono de contacto" [(ngModel)]="user.phone" name="cellphone" class="px-4 w-100 input-border" id="cellphone" inputmode="numeric" required>
                            <span *ngIf="f.submitted && f.controls['phone'].errors" class="text-danger animated fadeIn">El teléfono es obligatorio</span>
                        </div>
                    </div>
                    <div class="form-group mb-0">
                        <textarea [(ngModel)]="user.message" placeholder="Mensaje" name="message" class="px-4 pt-2 input-border mb-2" id="message" rows="5" min="20" required></textarea>
                        <span *ngIf="f.submitted && f.controls['message'].errors" class="text-danger animated fadeIn">El mensaje es obligatorio y mínimo de 20 caracteres</span>
                    </div>
                    <div class="form-group mb-0" *ngIf="browser && !session">
                        <div class="g-recaptcha recaptcha-1 my-0">
                            <re-captcha #captchaRef="reCaptcha" (resolved)="resolved($event)" siteKey="6LdkKNwUAAAAABGyZqGidUfLfPWk73AuG0u_ylMa" class="g-recaptcha" required>
                            </re-captcha>
                        </div>
                    </div>
                    <div class="">
                        <button type="submit" class="btn btn-yellow mr-0 mr-md-auto btn text-white text-bold px-3 pb-2 mb-2" style="background-color: rgba(247, 191, 80, 1) !important;">
                            <h5 class="mb-0">Enviar</h5>
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="row text-center mt-3 mb-5">
            <div class="col-12">
                <div class="carousel" id="our-services">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="row px-5">
                                <div class="col-md-6 px-0 px-md-3 order-1 order-md-0 d-flex justify-content-center flex-column text-center text-lg-left">
                                    <h1 class="text-black text-color-black">Nuestros <span class="text-thin">Servicios</span></h1>
                                    <h4 class="text-bold text-color-black mr-lg-auto mb-2">Brindamos soluciones</h4>
                                    <h5 class="text-color-black text-thin mr-auto mb-3">Todos los servicios que te brindamos es con el fin de brindarte un apoyo con un amplio catálogo con expertos en el tema.</h5>
                                    <a href="/about" class="d-block bg-yellow text-white text-semibold text-center mx-auto ml-lg-0 mr-lg-auto py-2" style="width: 150px;">
                                        Ver más<i class="ml-4 fas fa-chevron-right"></i>
                                    </a>
                                </div>
                                <div class="col-md-6 order-0 order-md-1 mb-4 mb-md-0">
                                    <img src="https://cdn.vivienda.com/images/services/beneficios.jpg" class="w-75 mx-auto">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>