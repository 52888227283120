import { AgmInfoWindow, AgmMap } from '@agm/core';
import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { ApiConnectionService } from 'src/app/services/api-connection.service';
import { ImageService } from 'src/app/services/image.service';
import Swal from 'sweetalert2/dist/sweetalert2';
declare const $: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent {
  @Input() properties: any = [];
  @Input() lat: number = 4.6799359;
  @Input() lng: number = -74.1603453;
  @Input() zoom: number = 13;
  @Input() distance: number = 10;
  @Input() radius: number = 10000;
  @Input() typeProperties: any = [];
  @Input() location: any = [
    { lat: 0, lng: 0 },
    { lat: 0, lng: 0 }
  ];
  @Input() withProperty:string = '';
  @Input() offer: any = {
    arriendo: true,
    venta: true
  };
  @Input() price: any = {
    min: 100000,
    max: 5000000
  };
  @Input() priceVenta: any = {
    min: 10000000,
    max: 500000000,
    active: false
  };
  @Input() priceMix: any = {
    min: 100000,
    max: 500000000,
    active: false
  };

  @Input() fields:boolean = true;
  @Input() limits:boolean = false;
  @Input()  thumbs:boolean = true;
  @Input() searchAvailable:boolean = true;

  currentIW: AgmInfoWindow = null;
  previousIW: AgmInfoWindow = null;
  map: any;
  timeOut: any;
  allowed: boolean = true;
  currency: string = localStorage.getItem('currency');

  browser = false;

  propertySelected:any;

  @Output('propertiesChange') emitter = new EventEmitter();

  constructor(
    protected api: ApiConnectionService,
    public image: ImageService,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.browser = isPlatformBrowser(platformId);
  }

  onMapReady(map: AgmMap) {
    this.map = map;
    this.map.setOptions({
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: true,
      minZoom: 3,
      maxZoom: 18,
      styles: [
        {
          featureType: 'administrative',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'transit',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi.attraction',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi.business',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi.government',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi.medical',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi.place_of_worship',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi.school',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi.sports_complex',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'landscape.man_made',
          stylers: [
            {
              visibility: 'on',
            },
            {
              color: '#fce8f0',
            },
          ],
        },
        {
          featureType: 'landscape.natural',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'landscape.natural',
          elementType: 'geometry',
          stylers: [
            {
              color: '#fce8f0',
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
      ],
    });
  }

  boundsChange(event: any) {
    console.log('boundChanhge', event);

    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = null;
    }
    this.timeOut = setTimeout(() => {
      const ne = event.getNorthEast();
      const sw = event.getSouthWest();
      // console.log(ne.toJSON(),sw.toJSON());
      this.location = [
        ne.toJSON(),sw.toJSON()
      ];
      // console.log(this.location);
      this.search();
      this.timeOut = null;
    }, 500);
  }

  search() {
    if (this.allowed) {
      //this.properties = [];
      this.currentIW = null;
      this.previousIW = null;
      this.api
        .postData('busqueda/properties', this.buildQuery(), {
          auth: false,
        })
        .subscribe(
          (response: any) => {
            this.properties = response;
            this.emitter.emit(this.properties);
          },
          (error: any) => {
            console.log(error);
          }
        );
    }
  }

  buildQuery() {
    let datos = {};
    if (this.lat && this.lng) {
      if (this.location[0].lat != 0) {
        datos['lat'] = [this.location[0].lat, this.location[1].lat];
        datos['lng'] = [this.location[1].lng, this.location[0].lng];
      } else {
        datos['lat'] = this.lat;
        datos['lng'] = this.lng;
        datos['distance'] = this.distance;
      }

      datos['location'] = {
        lat : this.lat,
        lng : this.lng,
        distance : this.distance
      };
    }

    var typeProperties = [];
    for (var i in this.typeProperties) {
      if (this.typeProperties[i]) typeProperties.push(i);
    }
    if (typeProperties.length > 0) datos['tiposInmueble'] = typeProperties;

    if (this.offer?.arriendo) { datos['tipoOferta'] = 'arriendo'; }
    if (this.offer?.venta) { datos['tipoOferta'] = 'venta'; }
    if (this.offer?.venta && this.offer.arriendo) { datos['tipoOferta'] = undefined; }

    if (this.price.active || this.priceVenta.active || this.priceMix.active) {
      var amount = {};
      amount = {
        gte: this.offer.arriendo ? this.price.min : this.priceVenta.min,
        lte: this.offer.arriendo ? this.price.max : this.priceVenta.max
      };
      if ((this.price.max == 5000001) || (this.priceVenta.max == 500000001)) {
        amount = {
          gte: this.offer.arriendo ? this.price.min : this.priceVenta.max
        };
      }
      datos['amount'] = amount;

    }

    datos['fields'] = this.fields;
    datos['limit'] = this.limits;
    datos['thumbs'] = this.thumbs;
    this.attachSearch(datos);

    return datos;
  }

  getArea(property): string {
    const index = property.areas.findIndex(
      (val) =>
        val.description == 'Área total' ||
        val.description == 'area_total' ||
        val.area_total !== undefined
    );
    if (index >= 0) {
      return 'Área total: ' + property.areas[index].area || property.areas[index].area;
    }
    return '';
  }

  markerClick(infoWindow: AgmInfoWindow,slug:string): void {
    if (this.previousIW) {
      this.currentIW = infoWindow;
      this.previousIW.close();
    }
    this.previousIW = infoWindow;
    this.allowed = false;
    this.propertySelected = null;
    if(this.searchAvailable)this.searchProperty(slug)
    setTimeout(() => this.allowed = true, 1000);
  }

  markerDragEnd($event: any) {
    this.lat = $event.latLng.lat();
    this.lng = $event.latLng.lng();
    this.search();
    this.geocodePosition();
  }

  geocodePosition() {
    if (this.browser) {
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          location: {
            lat: this.lat,
            lng: this.lng,
          },
        },
        (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            $('#reference, #reference-mobile').val(
              results[0].formatted_address
            );
          }
        }
      );
    }
  }

  attachSearch(datos: any): void {
    try {
      var current_search = [];
      current_search = JSON.parse(localStorage.getItem('current_search'));

      if (!current_search) {
        current_search = [
          {
            lat: datos['lat'],
            lan: datos['lng'],
          },
        ];
      } else {
        current_search.push({
          lat: datos['lat'],
          lan: datos['lng'],
        });
      }
      localStorage.setItem('current_search', JSON.stringify(current_search));
    } catch (e) {

    }
  }
  compareLat(slug){
        if(slug != this.withProperty) return true
        else return false;
  }

  searchProperty(slug:string){
    this.api.getData('property/details/' + slug + '?onlyData=true',{}).subscribe(
      (res)=>{
          console.log(res.data);

          this.propertySelected = res.data
      },
      (error) => {
        if (error.status == 404) {
          Swal.fire({
            title: 'Este inmueble no se encuentra disponible o no existe',
            text: 'Por favor inténtalo de nuevo',
          }).then(() => {
            window.location.href = '/busqueda';
          });
        } else {
          this.api.parseDefaultHttpResponse(error);
        }
      }
    )
  }

  getFormatImage(image, size, type = 'image', forceFormat = null) {
    if (type == 'image') {
      var file = 'https://cdn.vivienda.com/images/properties/no-disponible.jpg';
      if (typeof image.thumbs !== 'undefined' && image.thumbs.length > 0) {
        const index = image.thumbs.findIndex((v) => v.size == size);
        if (index > -1) file = image.thumbs[index].image;
      } else {
        var extension = image.path.substr(-3);
        if (!this.isAvailableImageFormat(extension)) {
          extension = image.path.substr(-4);
          if (!this.isAvailableImageFormat(extension)) {
            return file;
          }
        }
        if (!forceFormat)
          file =
            image.path.replace('.' + extension, '') +
            '-' +
            size +
            '.' +
            extension;
        else
          file =
            image.path.replace('.' + extension, '') +
            '-' +
            size +
            '.' +
            forceFormat;
      }


      return file;
    } else if (type == 'video') return this.getMimeVideo(image);

    return image.path;
  }

  isAvailableImageFormat(format): boolean {
    const formats = ['jpeg', 'jpg', 'png', 'jpe', 'jfif','gif','webp'];
    return formats.includes(format);
  }
  getMimeVideo(video) {
    try {
      var host = new URL(video.path);
      switch (host.hostname) {
        case 'www.youtube.com':
          let base = host.pathname.split('/');
          return 'https://i.ytimg.com/vi/' + base[2] + '/mqdefault.jpg';
          break;
      }
    } catch (e) {
      return 'https://i.ytimg.com/';
    }
  }
}
