<div class="container-fluid p-0">
  <div class="banner-cont d-none d-md-block">
    <img
      src="https://cdn.vivienda.com/images/contacts/banner.jpg"
      alt="Contacto Vivienda.com"
      class="w-100 img-fluid"
      style="max-height: 250px; object-fit: cover;"
    />
  </div>
  <div class="container">
    <div class="row text-center">
      <div class="col-12">
        <h1 class="text-yellow text-bold mb-0">Observaciones y sugerencias</h1>
      </div>

      <div class="col-md-12 order-0 order-md-1">
        <form action="#" #f="ngForm" (ngSubmit)="contact(f)" autocomplete="off">
          <div class="row">
            <div class="col-md-6 pr-md-1">
              <div class="form-group mb-0">
                <input
                  type="text"
                  [(ngModel)]="user.name"
                  name="name"
                  class="px-4 w-100 input-border mt-1"
                  id="name"
                  placeholder="Nombre completo"
                  autocomplete="new-name"
                  required
                />
                <br />
                <span
                  *ngIf="f.submitted && f.controls['name'].errors"
                  class="text-danger animated fadeIn"
                  >El nombre(s) es obligatorio</span
                >
              </div>
            </div>
            <div class="col-md-6 pl-md-1">
              <div class="form-group mb-0">
                <input
                  type="email"
                  placeholder="Correo electrónico"
                  [(ngModel)]="user.email"
                  name="email"
                  class="px-4 w-100 input-border mt-1"
                  id="email"
                  autocomplete="new-email"
                  required
                />
                <span
                  *ngIf="f.submitted && f.controls['email'].errors"
                  class="text-danger animated fadeIn"
                  >El correo electrónico es obligatorio</span
                >
              </div>
            </div>
          </div>
          <div class="form-group mb-0">
            <textarea
              [(ngModel)]="user.message"
              placeholder="Mensaje"
              name="message"
              class="px-4 input-border mb-0 mt-2"
              id="message"
              rows="6"
              min="20"
              required
            ></textarea>
            <span
              *ngIf="f.submitted && f.controls['message'].errors"
              class="text-danger animated fadeIn"
              >El mensaje es obligatorio y mínimo de 20 caracteres</span
            >
          </div>
          <!-- <div class="form-group mb-0 d-none">
            <input
              type="text"
              [(ngModel)]="user.surname"
              name="surname"
              class="px-4 w-100 input-border"
              id="surname"
              placeholder="Apellido(s)"
              autocomplete="new-surname"
              required
            />
            <span
              *ngIf="f.submitted && f.controls['surname'].errors"
              class="text-danger animated fadeIn"
              >El apellido(s) es obligatorio</span
            >
          </div> -->
          <!-- <div class="form-group mb-0 mt-3">
            <input
              type="text"
              placeholder="Teléfono de contacto"
              [(ngModel)]="user.phone"
              name="cellphone"
              class="px-4 w-100 input-border"
              id="cellphone"
              inputmode="numeric"
              required
            />
            <span
              *ngIf="f.submitted && f.controls['phone'].errors"
              class="text-danger animated fadeIn"
              >El teléfono es obligatorio</span
            >
          </div> -->
          <div class="form-group mt-n2 mb-n3" *ngIf="browser && !session">
            <div class="g-recaptcha recaptcha-1 my-0">
              <re-captcha
                #captchaRef="reCaptcha"
                (resolved)="resolved($event)"
                siteKey="6LdkKNwUAAAAABGyZqGidUfLfPWk73AuG0u_ylMa"
                class="g-recaptcha"
                required
              >
              </re-captcha>
            </div>
          </div>
          <div class="">
            <button
              type="submit"
              class="btn btn-yellow mr-0 mr-md-auto btn text-white text-bold px-3 py-2 mb-0 mt-1"
              style="background-color: rgba(247, 191, 80, 1) !important"
            >
              <h5 class="mb-0">Enviar</h5>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="row text-center mb-2">
      <div class="col-12">
        <div class="carousel" id="our-services">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row align-items-center">
                <div
                  class="col-md-8 px-0 px-md-3 order-1 order-md-0 d-flex justify-content-center flex-column text-md-left"
                >
                  <h1 class="mb-0 text-black text-color-black">
                    Nuestros <span class="text-thin">Servicios</span>
                  </h1>
                  <h4 class="text-bold text-color-black mr-md-auto mb-0">
                    Brindamos soluciones
                  </h4>
                  <h5 class="text-color-black text-thin mr-auto mb-2">
                    Todos los servicios que te brindamos es con el fin de
                    brindarte un apoyo con un amplio catálogo con expertos en el
                    tema.
                  </h5>
                  <a
                    href="/about"
                    class="d-block bg-yellow text-white text-semibold text-center ml-auto ml-md-0 mr-auto py-2"
                    style="width: 150px"
                  >
                    Ver más<i class="ml-4 fas fa-chevron-right"></i>
                  </a>
                </div>
                <div class="col-md-4 order-0 order-md-1 mb-2 mb-md-0 mt-2 mt-md-0">
                  <img
                    src="https://cdn.vivienda.com/images/services/beneficios.jpg"
                    class="img-fluid mx-auto"
                    style=" 
                        aspect-ratio: 44/21;
                        object-fit: cover;
                        object-position: bottom;
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
