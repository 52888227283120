<div [class]="modal ? 'modal-dialog modal-dialog-centered modal-md mt-3 pl-0 pr-0' : ''" role="document"
  *ngIf="property.amount; else loading">
  <div class="modal-content pl-0 pr-0" *ngIf="!graciasResponse; else Gracias">
    <button type="button" class="close float-right" (click)="closeModal()" *ngIf="modal">
      <span aria-hidden="true"><i class="fas fa-times"></i></span>
    </button>

    <div class="modal-body" [class]="!modal ? 'fit-content' : ''" id="inmuebles"
      [style]="modal ? '' : 'height:fit-content !important; margin-bottom:1rem;'">
      <div class="row">
        <div class="col-md-7 col-lg-9">
          <div id="imageSectionModal" class="row justify-content-center">
            <div class="col-12 d-md-none">
              <!-- Título mobile -->
              <h3 class="text-center">
                {{ property.type_property.type_property }} en {{ property.type_offer }}
              </h3>
            </div>

            <ng-container *ngIf="property.images.length > 0">
              <div class="col-md-6 pr-md-0 img-container">
                <ng-container *ngIf="property.images[0].type != 'video'; else showVideo">
                  <div style="background-image: url('{{ property.images[0].path }}');" (click)="openGallery(0)"></div>
                  <img [src]="property.images[0].path" width="647" class="img-fluid shadow" height="490"
                    style="height: 100%; min-height: 490px; max-height: 600px;" (click)="openGallery(0)" />
                  <a class="text-magenta" (click)="openGallery(0)"
                    class="text-bold btn-pink text-white text-center mx-auto my-auto d-block d-md-none" style="
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-45%, 0);
                    opacity: .85;
                    "><i class="far fa-images mr-2"></i> Ver fotos <i class="fas fa-search-plus ml-2"></i></a>
                </ng-container>
                <ng-template #showVideo>
                  <ng-container>
                    <iframe [src]="checkVideo(property.images[0].path)" width="100%" height="100%" id="videoMobile"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen></iframe>
                  </ng-container>
                </ng-template>
              </div>
              <div *ngIf="property.images.length > 1" class="col-md-6 row m-0 pr-0 d-none d-md-flex">
                <ng-container *ngFor="let img of property.images; let key = index">
                  <div *ngIf="key != 0 && key <= 6" class="col-md-6 p-md-1 img-container">
                    <div>
                      <img [src]="img.path" class="img-detail img-fluid shadow" width="258" (click)="openGallery(key)" />
                    </div>
                  </div>
                </ng-container>
                <div *ngIf="property.images.length <= 7 && property.images.length % 2 == 0" class="col-md-6 p-md-1 img-container">
                  <img src="./assets/img/text1.jpg" class="img-fluid img-detail shadow" width="258" />
                </div>
                <div class="w-100 d-flex justify-content-end align-items-end px-2">
                  <a class="text-magenta" [href]="'/propiedad/'+property.slug"
                    class="d-block text-bold btn-pink text-white text-center">
                    Ver más
                  </a>
                </div>
              </div>
            </ng-container>
          </div>

          <ngx-image-gallery #imagesGallery id="imagesGallery" [images]="images" [conf]="conf"
            (onImageChange)="galleryImageChanged($event)"></ngx-image-gallery>
        </div>
        <div class="col-md-5 col-lg-3 d-none d-md-block">
          <div class="formulario-contact bg-gray1 h-100 pt-2">
            <form class="form-horizontal contactForm" #fContactWeb="ngForm" (ngSubmit)="contact(fContactWeb)"
              autocomplete="off">
              <div class="form-group text-center mb-0">
                <h5 class="mb-2">¡Estoy interesado!</h5>
              </div>
              <div class=".form-group mb-2">
                <div class="col-sm-12 text-center">
                  <input type="text" class="form-control text-dark mt-0" name="name" id="web-name" ngModel
                    placeholder="Nombre(s) {{ fContactWeb.submitted && fContactWeb.controls['name'].errors ? 'es requerido' : '' }}"
                    [class]="fContactWeb.submitted && fContactWeb.controls['name'].errors ? 'is-invalid' : ''"
                    autocomplete="new-name" required>
                </div>
              </div>
              <div class=".form-group mb-2">
                <div class="col-sm-12 text-center">
                  <input type="text" class="form-control text-dark mt-0" name="surname" id="web-surname" ngModel
                    placeholder="Apellido(s) {{ fContactWeb.submitted && fContactWeb.controls['surname'].errors ? 'es requerido' : '' }}"
                    [class]="fContactWeb.submitted && fContactWeb.controls['surname'].errors ? 'is-invalid' : ''"
                    autocomplete="new-surname" required>
                </div>
              </div>
              <div class=".form-group mb-2">
                <div class="col-sm-12 text-center">
                  <input type="number" class="form-control text-dark mt-0" name="cellphone" id="web-cellphone" ngModel
                    placeholder="Teléfono {{ fContactWeb.submitted && fContactWeb.controls['cellphone'].errors ? 'es requerido' : '' }}"
                    [class]="fContactWeb.submitted && fContactWeb.controls['cellphone'].errors ? 'is-invalid' : ''"
                    required>
                </div>
              </div>
              <div class=".form-group mb-2">
                <div class="col-sm-12 mb-2 text-center">
                  <input type="email" class="form-control text-dark mt-0" name="email" id="web-email" ngModel
                    placeholder="Correo {{ fContactWeb.submitted && fContactWeb.controls['email'].errors ? 'es requerido' : '' }}"
                    [class]="fContactWeb.submitted && fContactWeb.controls['email'].errors ? 'is-invalid' : ''"
                    autocomplete="new-email" required>
                </div>
              </div>
              <div class="form-group mb-0">
                <div class="col-sm-12 text-center">
                  <textarea type="text" name="message" id="web-message" ngModel
                    placeholder=""
                    class="px-4 py-1 ml-auto mt-1 text-gray form-control text-dark"
                    [(ngModel)]='textareaDefault'>
                  </textarea>
                </div>
              </div>
              <div class="checkbox mb-0 text-center mt-2 d-flex align-items-center">
                <label class="checkbox mb-0">
                  <input type="checkbox" checked="checked" id="web-terms" name="terms" ngModel required>
                  <span class="check"></span>
                </label>
                <p class="mb-0 text-gray text-thin text-left">Acepto los términos y condiciones, politica de privacidad
                  y el tratamiento de mis datos personales</p>
                <span class="text-danger" style="font-size: 10px;"
                  *ngIf="fContactWeb.submitted && fContactWeb.controls['terms'].errors">Debes aceptar los términos y
                  condiciones</span>
              </div>
              <!-- <div class="form-group mb-1 btn-reca" *ngIf="showCaptcha"></div> -->
              <div class="form-group mb-2 btn-reca">
                <div class="g-recaptcha recaptcha-1 col-12 text-center">
                  <re-captcha #captchaRef="reCaptcha" (resolved)="resolved($event)"
                    siteKey="6LdkKNwUAAAAABGyZqGidUfLfPWk73AuG0u_ylMa" class="g-recaptcha" required></re-captcha>
                </div>
              </div>
              <div class="form-group mb-0">
                <div class="col-sm-12 text-center">
                  <button onclick="
          gtag('event', 'Boton enviar formulario desde el ver perfil que estaba en el MENU HAMBURGUESA', {
            'event_category': 'Boton de enviar del formulario en el detalle del inmueble ',
            'event_label': location.pathname
          });" type="submit" class="py-1 px-4 btn-yellow text-bold mb-0 mt-1">Enviar formulario</button>
                </div>
              </div>
            </form>

            <ng-container *ngIf="false; else btnsContact"></ng-container>

          </div>
        </div>
      </div>


      <div class="row mr-md-0 mt-2">
        <div class="col-md-5">
          <div class="bg-magenta">
            <h3 class="bg-white text-blue py-1 py-md-2 px-2 font-weight-bold">${{ property.amount }} {{ property.currency }}</h3>
            <p class="mb-0">
              {{ property.type_property.type_property }} en {{ property.type_offer }}
              <br />
              {{ property.zone + ", " }} {{ property.city }}
              <br />
              <ng-container *ngIf="totalArea">
                Área total: {{ totalArea }}m<sup>2</sup>
              </ng-container>
              Estrato: {{ property.estrato }}
              <span class="action">
                <a href="javascript:void(0);" (click)="collapseChar('sharein')">
                  <img src="//cdn.vivienda.com/images/icons/share.png" class="img-fluid ml-1" width="25" />
                </a>
                <a href="javascript:void(0);" (click)="savedProperty('saved')">
                  <img
                    [src]="relations.saved ? '//cdn.vivienda.com/images/icons/fav-red.png' : '//cdn.vivienda.com/images/icons/fav.png'"
                    class="img-fluid ml-1 white-img" width="25" />
                </a>
                <a href="javascript:void(0);" (click)="reportProperty()">
                  <img src="//cdn.vivienda.com/images/icons/info.png" class="img-fluid ml-1" width="25" />
                </a>
                <a href="./propiedad/pdf/{{slug}}" target="_blank">
                  <h5 class="mb-0 mt-1" style="opacity: .6;">
                    <i class="far fa-file-pdf"></i>
                  </h5>
                </a>
              </span>
            </p>
            <div class="container row justify-content-center mx-0 px-0 mt-2 mt-md-0">
              <div class="collapse" id="sharein">
                <a class="mx-3 text-white" title="Compartir en Facebook" target="_blank"
                  href="https://www.facebook.com/share.php?u={{ generateUri() }}">
                  <i class="fab fa-facebook fa-2x"></i>
                </a>
                <a class="mx-3 text-white" title="Compartir en Twitter" target="_blank"
                  href="https://twitter.com/share?url={{ generateUri() }}&via=vivienda.com">
                  <i class="fab fa-twitter fa-2x"></i>
                </a>
                <a class="mx-3 text-white" title="Compartir en WhatsApp" target="_blank" href="https://wa.me/?text=Hola, encontré este inmueble en la página vivienda.com {{ generateUri() }}{{ property.zone
                      ? property.city
                        ? ', Ubicado en ' + property.zone + ', ' + property.city
                        : ', Ubicado en ' + property.zone
                      : ''
                  }}">
                  <i class="fab fa-whatsapp fa-2x"></i>
                </a>
                <a class="mx-3 text-white" title="Compartir el enlace" target="_blank" (click)="copyLink()">
                  <i class="far fa-copy fa-2x"></i>
                </a>
              </div>
            </div>
          </div>
          <h4 class="mb-4 mt-4 text-magenta" style="padding-left: 1.5em">
            Ubicación
          </h4>
          <div class="row col-12 p-0 m-0">
            <img src="{{ buildGoogleMapsImageUri() }}" style="height: 210px" class="img-fluid pointer"
              (click)="openGoogleMapsUri()" />
          </div>
        </div>
        <div class="col-md-7 m-0 p-0 row info">
          <div class="bg-grey col-12 row m-0 px-0 pt-2 pb-3">
            <div class="col-md-6">
              <h4 class="text-magenta">Características Generales</h4>
              <div class="d-flex justify-content-center">
                <div class="antiguedad pr-3">
                  <h6 *ngIf="property.antiquity">
                    {{ property.antiquity }}
                    <br />
                    <span> Antigüedad </span>
                  </h6>
                </div>
                <div class="pl-3">
                  <h6 *ngIf="totalArea">
                    {{ totalArea }}m<sup>2</sup>

                    <br />
                    <span> Área </span>
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h4 class="text-magenta">Información Adicional</h4>
              <div class="row m-0">
                <div *ngFor="
                    let char of ['interiors', 'exterior', 'common_zones'];
                    let last = last
                  " class="col-4 d-flex" [class]="last ? '' : 'antiguedad'">
                  <img [src]="
                      char == 'interiors'
                        ? 'https://cdn.vivienda.com/images/icons/interiores.png'
                        : char == 'exterior'
                        ? 'https://cdn.vivienda.com/images/icons/exteriores.png'
                        : char == 'common_zones'
                        ? 'https://cdn.vivienda.com/images/icons/comunes.png'
                        : ''
                    " [alt]="char" class="img-fluid mb-md-2 mx-auto" width="45" />
                  <a href="javascript:void(0);" (click)="collapseChar(char)">
                    <img src="//cdn.vivienda.com/images/icons/arrow-down.png" class="pos-abs" />
                  </a>
                </div>
              </div>
              <div class="col-12 p-0" [class]="pCharacteristics[char].length > 0 ? '' : 'd-none'"
                *ngFor="let char of ['interiors', 'exterior', 'common_zones']">
                <div class="row collapse m-0 p-0 container" id="{{ char }}">
                  <div class="col-6 my-0 px-2" *ngFor="let characteristic of pCharacteristics[char]">
                    <div *ngIf="characteristic.type == 'counter'"
                      class="d-flex align-items-center justify-content-start">
                      <img src="{{ characteristic.image }}" class="float-left" width="15px"
                        onerror="this.src = '//cdn.vivienda.com/images/icons/otro.png'" />
                      <p class="text-regular text-overflow mb-0 ml-2 align-items-center" style="width: 80%">
                        {{ characteristic.name }}
                      </p>
                    </div>
                    <div *ngIf="characteristic.type == 'multiple-list'" class="d-flex flex-nowrap" style="width: 165px">
                      <div class="img-multiple-list">
                        <img src="{{ characteristic.image }}" class="float-left" width="15px"
                          onerror="this.src = '//cdn.vivienda.com/images/icons/otro.png'" />
                      </div>
                      <div class="contain-item-multiple-list ml-2 d-inline-flex flex-wrap">
                        <div class="d-flex align-items-center justify-content-start item-multiple-list"
                          *ngFor="let item of characteristic.items; index as i">
                          <p class="mb-0 text-regular text-overflow text-multiple-list" style="width: 80%">
                            {{ item.name }}
                            <span *ngIf="i < characteristic.items.length - 1">, &nbsp;</span>
                            <span *ngIf="i === characteristic.items.length - 1">. &nbsp;</span>
                          </p>
                          <br />
                        </div>
                      </div>
                    </div>

                    <div *ngIf="characteristic.type == 'simple'"
                      class="d-flex align-items-center justify-content-start">
                      <img src="{{ characteristic.image }}" class="float-left" width="15px"
                        onerror="this.src = '//cdn.vivienda.com/images/icons/otro.png'" />
                      <p class="mb-0 text-regular text-overflow ml-2" style="width: 80%">
                        {{ characteristic.name }} 1
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="property.description" class="m-0 pt-2 pl-4 pr-4 less w-100">
              <p id="descriptionContent" class="less text-justify">
                <b>Descripción del inmueble:</b>
                <br>
                {{ property.description }}
              </p>
              <span (click)="verMasContent()" id="moreOrLessButton" class="ver">
                Ver <span class="show">más</span>
                <span class="hide">menos</span>
              </span>
            </div>
            <div class="row pt-1 pl-4 pr-4 m-0">
              <h4 class="mt-1 mb-1 text-magenta">Sitios Cercanos</h4>
            </div>
            <div *ngIf="property.nearby_sites" class="row m-0 pr-4 pl-4 m-0 col-12">
              <div *ngFor="let ns of property.nearby_sites" class="col-4 col-md-3">
                <p class="d-flex">
                  <i class="fa fa-check"></i> {{ ns.name }}
                </p>
              </div>
            </div>

            <!-- planos -->
            <div class="row pt-1 px-2 m-0" *ngIf="blueprints?.length > 0">
              <div class="col-12">
                <div class="p-3 p-lg-0">
                  <h4 class="mt-1 mb-1 text-magenta">Planos</h4>
                </div>
              </div>
              <div
                class="col-6 col-lg-3 mb-3 mb-lg-0"
                *ngFor="let img of blueprints; let key = index"
              >
                <div class="p-3 p-lg-0">
                  <img
                    [src]="img.url"
                    class="plano-img img-fluid w-100 cursor-pointer aspect-ratio-1"
                    width="258"
                    (click)="openBlueprints(key)"
                  />
                </div>
              </div>
              <div class="col-12">
                <ngx-image-gallery
                  #blueprintsGallery
                  id="blueprintsGallery"
                  [images]="blueprints"
                  [conf]="conf"
                ></ngx-image-gallery>
              </div>
            </div>

          </div>
          <div *ngIf="whatsapp.quantity >= 1 && whatsapp.accept" class="row m-0 col-12 p-0">
            <ng-template *ngFor="let contact of property.contacts">
              <ng-container *ngFor="let phone of contact.content.phones">
                <a href="https://wa.me/{{ phone.phone }}?text=Hola quisiera más información sobre el inmueble en la ciudad de {{ property.city }}, {{ property.zone }}"
                  target="_blank" title="Contactar al propietario mediante WhatsApp"
                  class="align-items-xl-center bg-magenta btn-magenta d-block d-flex justify-content-end mt-2 p-1 pb-1 pr-5 text-right text-white w-100">
                  <img src="./assets/img/wpp.png" alt="" class="img-fluid mr-2" />
                  Solicitar Información
                </a>
              </ng-container>
            </ng-template>
          </div>
        </div>
      </div>

      <!-- <div class="form-contact-general form-contact-modal d-none d-md-block transition" id="collapseContacto">
        <div class="d-flex general">
          <div class="button">
            <div class="float-info transition-left" *ngIf="!editableAvailable" id="cont-btn-float"
              onclick="$('#collapseContacto').toggleClass('transition');">
              <span>
                <img src="//cdn.vivienda.com/images/icons/arrow-down.png" alt="arrow" class="hidden">
                <img src="//cdn.vivienda.com/images/icons/close.png" alt="arrow" class="activo">
              </span>
              <a class="text-bold pointer">
                Solicitar información
              </a>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="row float-contact bg-white m-0 d-flex d-md-none">
      <div class="pl-0 pr-1 col">
        <a href="javascript:void()"
          class="d-flex justify-content-center align-items-center w-100 py-2 btn-float-contact analytics-event-tracing"
          data-toggle="modal" data-target="#contactModalMobile"
          attr.data-event="Clic botón acciones móvil inmueble {{ slug }}"
          attr.data-label="Clic botón abrir modal formulario de contacto desde {{ modal ? 'búsqueda de inmuebles' : 'detalle del inmueble' }}"
          data-category="Clic botón abrir modal formulario de contacto">
          <i class="far fa-address-card"></i>
          <h6 class="text-semibold text-white ml-1 mb-0">Contacto</h6>
        </a>
      </div>
      <div class="col pl-0 pr-1" *ngIf="mainEmail">
        <a href="mailto:{{ mainEmail }}"
          class="d-flex justify-content-center align-items-center w-100 py-2 btn-float-contact analytics-event-tracing"
          attr.data-event="Clic botón enviar email {{ slug }}"
          attr.data-label="Clic botón email al propietario desde {{ modal ? 'búsqueda de inmuebles' : 'detalle del inmueble' }}"
          data-category="Clic botón email al propietario" title="Contactar mediante email">
          <i class="far fa-envelope"></i>
          <h6 class="text-semibold text-white ml-1 mb-0">Email</h6>
        </a>
      </div>
      <div class="col pl-0 pr-1" *ngIf="cellphone">
        <a href="tel:{{ cellphone }}"
          class="d-flex justify-content-center align-items-center w-100 py-2 btn-float-cellphone analytics-event-tracing"
          attr.data-event="Clic botón acciones móvil inmueble {{ slug }}"
          attr.data-label="Clic botón llamar al propietario desde {{ modal ? 'búsqueda de inmuebles' : 'detalle del inmueble' }}"
          data-category="Clic botón llamar al propietario" title="Contactar mediante telefono">
          <i class="fa fa-phone-alt"></i>
          <h6 class="text-semibold text-white ml-1 mb-0">Llamar</h6>
        </a>
      </div>
      <div class="col-1 pr-0 pl-1" *ngIf="cellphone">
        <a href="//wa.me/{{ cellphone }}?text=Hola,+estoy+interesado+en+el+inmueble+que+encontré+en+vivienda.com+{{ uri }}"
          target="_blank"
          class="d-flex justify-content-center align-items-center py-2 btn-float-whatsapp analytics-event-tracing"
          attr.data-event="Clic botón acciones móvil inmueble {{ slug }}"
          attr.data-label="Clic botón contactar mediante WhatsApp desde {{ modal ? 'búsqueda de inmuebles' : 'detalle del inmueble' }}"
          data-category="Clic botón contactar mediante WhatsApp" title="Contactar mediante whatsapp">
          <i class="fab fa-whatsapp"></i>
        </a>
      </div>
    </div>
  </div>

  <ngx-json-ld [json]="schema" *ngIf="!modal"></ngx-json-ld>
</div>

<div class="modal fade" id="contactModalMobile" tabindex="-1" role="dialog" data-backdrop="false"
  aria-labelledby="contactModalMobileLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="contactModalMobileLabel">Estoy interesado</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body h-100" style="overflow: hidden;">
        <form class="form-horizontal" #fContactMobile="ngForm" (ngSubmit)="contact(fContactMobile)" autocomplete="off">
          <div class="form-group">
            <div class="col-sm-12 mt-4 text-center">
              <input type="text" class="form-control text-dark w-100" name="name" id="web-name" ngModel
                placeholder="Nombre(s) {{ fContactMobile.submitted && fContactMobile.controls['name'].errors ? 'es requerido' : '' }}"
                [class]="fContactMobile.submitted && fContactMobile.controls['name'].errors ? 'is-invalid' : ''"
                autocomplete="new-name" style="height: 40px;" required>
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-12 text-center">
              <input type="text" class="form-control text-dark w-100" name="surname" id="web-surname" ngModel
                placeholder="Apellido(s) {{ fContactMobile.submitted && fContactMobile.controls['surname'].errors ? 'es requerido' : '' }}"
                [class]="fContactMobile.submitted && fContactMobile.controls['surname'].errors ? 'is-invalid' : ''"
                autocomplete="new-surname" style="height: 40px;" required>
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-12 text-center">
              <input type="number" inputmode="numeric" class="form-control text-dark w-100" name="cellphone" id="web-cellphone"
                ngModel
                placeholder="Teléfono {{ fContactMobile.submitted && fContactMobile.controls['cellphone'].errors ? 'es requerido' : '' }}"
                [class]="fContactMobile.submitted && fContactMobile.controls['cellphone'].errors ? 'is-invalid' : ''"
                required>
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-12 mb-2 text-center">
              <input type="email" class="form-control text-dark w-100" name="email" id="web-email" ngModel
                placeholder="Correo {{ fContactMobile.submitted && fContactMobile.controls['email'].errors ? 'es requerido' : '' }}"
                [class]="fContactMobile.submitted && fContactMobile.controls['email'].errors ? 'is-invalid' : ''"
                autocomplete="new-email" required>
            </div>
          </div>
          <div class="form-group mb-0">
            <div class="col-sm-12 text-center">
              <textarea type="text" name="message" id="web-message" ngModel
                placeholder=""
                [(ngModel)]='textareaDefault'
                class="px-4 py-1 ml-auto mt-1 text-gray form-control text-dark"></textarea>
            </div>
          </div>
          <div class="checkbox mb-0 text-center mt-2">
            <label class="checkbox mb-0">
              <input type="checkbox" checked="checked" id="web-terms" name="terms" ngModel required>
              <span class="check"></span>
            </label>
            <p class="mb-0 text-gray text-thin text-left ml-4" style="font-size: 10px;">Acepto los términos y
              condiciones, politica de privacidad y el tratamiento de mis datos personales</p>
            <span class="text-danger" style="font-size: 10px;"
              *ngIf="fContactMobile.submitted && fContactMobile.controls['terms'].errors">Debes aceptar los términos y
              condiciones</span>
          </div>
          <div class="form-group mb-2 btn-reca">
            <div class="g-recaptcha recaptcha-1 col-12 text-center">
              <re-captcha #captchaRef="reCaptcha" (resolved)="resolved($event)"
                siteKey="6LdkKNwUAAAAABGyZqGidUfLfPWk73AuG0u_ylMa" class="g-recaptcha" required></re-captcha>
            </div>
          </div>
          <div class="form-group mb-0">
            <div class="col-sm-12 text-center">
              <button type="submit" class="py-1 px-4 btn-yellow text-bold mb-0 mt-1">Enviar formulario</button>
            </div>
          </div>
        </form>

        <ng-container *ngIf="false; else btnsContact"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #btnsContact>
  <div id="btnsContact" class="mb-1 mt-2 text-center px-3 formulario-contact-img">
    <div class="row justify-content-center mt-2">
      <div class="col-auto">
        <a [href]="'https://www.google.com/maps/dir//'+ lat +','+ lng +'/@'+ lat +','+ lng +',17z?entry=ttu'" class="text-blue" target="_blank">
          <i class="fas fa-map-marked-alt fa-2x"></i>
        </a>
      </div>
      <div class="col-auto">
        <a [href]="'https://www.waze.com/es/live-map/directions?navigate=yes&to=ll.'+ lat +'%2C'+ lng" class="text-blue" target="_blank">
          <i class="fab fa-waze fa-2x"></i>
        </a>
      </div>
    </div>
    <div class="dropup mb-0">
      <button type="button"
        class="btn btn-red btn-rounded text-white text-center pointer w-100 dropdown-toggle mt-2"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-offset="0,5">
        Ver número de contactos
        <img src="https://cdn.vivienda.com/images/icons/phone.png" class="ml-2">
      </button>
      <div class="dropdown-menu shadow w-100 border-0 rounded-3">
        <div class="px-3 " *ngIf="property.contacts.length > 0; else sinContact">
          <div [class]="last ? '' : ' pb-2 mb-2 border-bottom'"
            *ngFor="let contact of property.contacts; last as last">
            <h6 class="mb-0 lh-1">{{ contact.content.name }}</h6>
            <div class="d-flex justify-content-between align-items-center lh-1" [class]="last ? '' : ' pb-1 mb-1'" *ngFor="let p of contact.content.phones; last as last">
              <div>
              <p class="mb-0">{{ p.phone }}</p>
              </div>
              <div *ngIf="p.phone">
                <a class="btn text-blue py-0" 
                    href="tel:{{ p.phone }}"
                    target="_blank"
                    >
                    <h5 class="mb-0">
                    <span class="small mr-1"><small>Llamar</small></span>
                    <i class="fas fa-phone-square align-middle"></i>
                    </h5>
                </a>
                <a class="btn text-success py-0 pr-0" 
                    href="//wa.me/{{ p.phone }}?text=Hola,+estoy+interesado+en+el+inmueble+que+encontré+en+vivienda.com+{{ uri }}"
                    target="_blank"
                    >
                    <h5 class="mb-0">
                    <i class="fab fa-whatsapp"></i>
                    </h5>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dropup mb-0">
      <button type="button"
        class="btn btn-red btn-rounded text-white text-center pointer w-100 dropdown-toggle mt-2"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-offset="0,5">
        Contactar vía Email
        <img src="https://cdn.vivienda.com/images/icons/mail.png" class="ml-2">
      </button>
      <div class="dropdown-menu shadow w-100 border-0 rounded-3">
        <div class="px-2 " *ngIf="property.contacts.length > 0; else sinContact">
          <div [class]="last ? '' : ' pb-2 mb-2 border-bottom'"
            *ngFor="let contact of property.contacts; last as last">
            <ng-container *ngIf="contact.content.email; else sinContact">
            <a href="mailto: {{ contact.content.email }}" class="btn text-dark p-0 w-100">
              <h6 class="mb-0 p-2 lh-1 small font-weight-bold d-flex justify-content-between">
                {{ contact.content.email }}
                <i class="far fa-paper-plane"></i>
              </h6>
            </a>
            </ng-container>
          </div>
        </div>
        <ng-template #sinContact>
          <div class="px-2 text-center lh-1 text-secondary">
            En este momento no hay información de contacto
          </div>
        </ng-template>
      </div>
    </div>

    <div class="dropup mb-2">
      <button type="button" class="btn btn-red-outline btn-rounded text-center pointer w-100 dropdown-toggle mt-2"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-offset="50,5">
        Compartir <i class="fas fa-share-alt"></i>
      </button>
      <div class="dropdown-menu  shadow border-0 rounded-3 px-3">
        <div class="row text-center text-blue">
          <div class="col-3 px-2">
            <a class="d-block" title="Compartir en Facebook" target="_blank"
              href="https://www.facebook.com/share.php?u={{ generateUri() }}">
              <h4 class="mb-0">
              <i class="fab fa-facebook"></i>
              </h4>
            </a>
          </div>
          <div class="col-3 px-2">
            <a class="d-block" title="Compartir en Twitter" target="_blank"
              href="https://twitter.com/share?url={{ generateUri() }}&via=vivienda.com">
              <h4 class="mb-0">
              <i class="fab fa-twitter"></i>
              </h4>
            </a>
          </div>
          <div class="col-3 px-2">
            <a class="d-block" title="Compartir en WhatsApp" target="_blank" href="https://wa.me/?text=Hola, encontré este inmueble en la página vivienda.com {{ generateUri() }}{{ property.zone
              ? property.city
                ? ', Ubicado en ' + property.zone + ', ' + property.city
                : ', Ubicado en ' + property.zone
              : ''
          }}">
          <h4 class="mb-0">
              <i class="fab fa-whatsapp"></i>
              </h4>
            </a>
          </div>
          <div class="col-3 px-2">
            <a class="d-block" title="Compartir el enlace" (click)="copyLink()" href="javascript:void(0)">
              <h4 class="mb-0">
              <i class="far fa-copy"></i>
              </h4>
            </a>
          </div>
        </div>



      </div>
    </div>

    <div class="position-relative mb-2">
      <a type="button" data-toggle="modal" data-target="#report" data-backdrop="static" data-keyboard="false"
        class="text-bold text-gray pointer img-hover">
        <img src="https://cdn.vivienda.com/images/icons/log.png">
      </a>
      <p class="hide-text">Reportar inmueble</p>
    </div>
  </div>
  </ng-template>

<ng-template #Gracias>
  <ng-container>
    <div class="modal-content pl-0 pr-0">
      <div class="container text-center justify-content-center p-5">
        <h1 style="font-size:10rem;color: #0096a3;"><i class="far fa-check-circle"></i></h1>
        <h2>¡Gracias por contactarte con el propietario del inmueble {{property.type_property.type_property}} en
          {{property.type_offer}} {{property.zone}}, {{property.city}}!</h2>
        <a class="d-block text-bold btn-pink text-white text-center m-auto" href="javascript:void(0);"
          (click)="graciasResponse = false">Regresar</a>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #loading>
  <div class="emptyBlock p-5 text-center">
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</ng-template>