import {
  Component,
  OnInit,
  PLATFORM_ID,
  Inject,
  NgZone,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { ApiConnectionService } from 'src/app/services/api-connection.service';
import { NavbarComponent } from '../shared/navbar/navbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from 'src/app/services/home/home.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { ContactComponent } from '../contact/contact.component';

declare const $: any;
declare const gtag: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  protected geoCoder;

  lat = 4.6799359;
  lng = -74.1603453;
  zoom: number = 13;
  distance = 10;
  radius = 10000;
  captcha = null;
  browser = false;
  properties: any = [];
  services: any = [];
  profiles: any = [];

  @ViewChild('search') public searchElementRef: ElementRef;
  @ViewChild('captchaRef') public captchaRef: RecaptchaComponent;

  location = [
    { lat: 0, lng: 0 },
    { lat: 0, lng: 0 }
  ];

  constructor(
    protected mapsAPILoader: MapsAPILoader,
    protected ngZone: NgZone,
    protected apiConnection: ApiConnectionService,
    protected http: HttpClient,
    public navbarComponent: NavbarComponent,
    public contactComponent: ContactComponent,
    private meta: Meta,
    private title: Title,
    protected activateRoute: ActivatedRoute,
    public homeService: HomeService,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.browser = isPlatformBrowser(platformId);
    this.title.setTitle('Vivienda.com');
    this.meta.addTags([
      { name: 'title', content: this.title.getTitle() },
      { name: 'description', content: 'Encuentra apartamentos, casas, oficinas, bodegas, fincas, lotes y más inmuebles en venta o arriendo. Vivienda.com el sitio web para comprar, vender y arrendar finca raíz totalmente gratis.' },
      { property: 'og:title', content: this.title.getTitle() },
      { property: 'og:description', content: 'Encuentra apartamentos, casas, oficinas, bodegas, fincas, lotes y más inmuebles en venta o arriendo. Vivienda.com el sitio web para comprar, vender y arrendar finca raíz totalmente gratis.' },
      { property: 'og:url', content: 'https://vivienda.com' },
      { property: 'og:image', content: 'https://cdn.vivienda.com/images/home/banner-1.jpg' }
    ]);
  }

  ngOnInit(): void {
    this.getAll();
    if (this.browser) {
      this.setCurrentPosition();
      this.mapsAPILoader.load().then(() => {
        // tslint:disable-next-line:new-parens
        this.geoCoder = new google.maps.Geocoder();
        const autocomplete = new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement
        );
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            const place: google.maps.places.PlaceResult =
              autocomplete.getPlace();

            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            this.lat = place.geometry.location.lat();
            this.lng = place.geometry.location.lng();
          });
        });
        const autocompleteSeeker = new google.maps.places.Autocomplete(
          $('#cityHome')[0]
        );
        autocompleteSeeker.addListener('place_changed', () => {
          this.ngZone.run(() => {
            const place: google.maps.places.PlaceResult =
              autocompleteSeeker.getPlace();
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            place.address_components.forEach((v) => {
              v.types.forEach((value) => {
                if (value == 'locality')
                  this.navbarComponent.city = v.long_name;
              });
            });
            this.navbarComponent.lat = place.geometry.location.lat();
            this.navbarComponent.lng = place.geometry.location.lng();
          });
        });
      });
      $(document).ready(function () {
        let ck = document.cookie.split(';');        
        let modalSg = true;

        for( var i = 0; i < ck.length; i++) {
          let subel = ck[i].split('=');
          if (subel[1] == 'modalSuggestActive') {
            modalSg = false;
            break;
          } else {

          }
        }
            
        if (modalSg) {
          setTimeout(() => {
              $('#suggestModalLong').modal('show');

              var date = new Date();
              date.setTime(date.getTime()+(24*60*60*1000));
              document.cookie = 'modalSuggest=modalSuggestActive;expires=' + date.toUTCString()  + '; path=/';
          }, 60000);
        }

        $('.carousel-inner').owlCarousel({
          nav: true,
          dots: true,
          loop: true,
          margin: 10,
          items: 1,
        });
 
        $('#serviciosRotador').owlCarousel({
          nav: true,
          dots: false,
          loop: true,
          margin: 10,
          responsive: {
            0: {
              items: 1,
              nav: true,
            },
            600: {
              items: 3,
              nav: true,
            },
            1000: {
              items: 4,
              nav: true,
            },
          },
        });

        $('#somos').owlCarousel({
          nav: true,
          dots: true,
          loop: true,
          margin: 10,
          responsive: {
            0: {
              items: 1,
              nav: true,
            },
            600: {
              items: 3,
              nav: true,
            },
            1000: {
              items: 4,
              nav: true,
            },
          },
        });

        setTimeout(() => {
          if (
            !document
              .getElementById('collapseContacto')
              .classList.contains('transition')
          )
            document
              .getElementById('collapseContacto')
              .classList.add('transition');
        }, 8000);

        $(window).scroll(function () {
          var scroll = $(window).scrollTop();

          if (scroll >= 100) {
            if (
              !document
                .getElementById('collapseContacto')
                .classList.contains('transition')
            )
              document
                .getElementById('collapseContacto')
                .classList.add('transition');
          }
        });
      });
    }
  }

  getAll() {
    this.apiConnection
      .postData(
        'all',
        {
          properties: {
            type: 'latest',
            limit: 10
          },
          services: {
            type: 'ranked',
            limit: 10,
          },
          profiles: {
            type: ['agente'],
            limit: 4,
          },
        },
        {
          auth: false,
        }
      )
      .subscribe(
        (response: any) => {
          for (var i in response) this[i] = response[i];
        },
        (error) => {
          console.log(error);
        }
      );
  }

  setCurrentPosition(): void {
    let loc = JSON.parse(localStorage.getItem('recent_locations'));
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          this.zoom = 13;
          loc = {
            lat: this.lat,
            lng: this.lng,
            zoom: this.zoom,
          };
          localStorage.setItem('recent_locations', JSON.stringify(loc));
          var date = new Date();
          date.setDate(date.getDate() + 365);
          document.cookie =
            'recent_locations = ' + JSON.stringify(loc) + '; expire = ' + date;
        },
        (error) => {
          this.lat = 4.6799359;
          this.lng = -74.1603453;
          this.zoom = 13;
        }
      );
    } else {
      this.lat = 4.6799359;
      this.lng = -74.1603453;
      this.zoom = 13;
    }
  }

  focused(element) {
    var a = document.getElementsByClassName('card-img-top');
    for (let i = 0; i < a.length; i++) {
      if (i == element) a[i].classList.add('focus');
      else a[i].classList.remove('focus');
    }
  }

  clickEvent(event = '', category = '', label = '', value = 'true'): void {
    gtag('event', event, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }

  getArea(property) {
    let index = property.areas.findIndex(
      (val) =>
        val.description == 'Área total' ||
        val.description == 'area_total' ||
        val.area_total !== undefined
    );
    if (index >= 0) {
      return `Área total: ${property.areas[index].area_total}`;
    }
    return '';
  }

  setProperties(event: any) {
    let properties = [];
    let count = 1;
    const w = window.innerWidth;
    event.forEach((e, i) => {
      if (w > 1200 && count > 10) return;
      if (w < 1200 && count > 9) return;
      if (e.images.length > 0) {
        let image = e.images.find(e => e.type == 'image');
        properties.push({
          amount: e.amount,
          city: e.city,
          currency: e.currency,
          image: image.path,
          slug: e.slug,
          type_offer: e.type_offer,
          type_property: e.type_property.type_property,
          zone: e.zone,
        });
        count++;
      }
    });
    //this.properties = properties;
  }
}
