<div class="color-white">
  <!-- <div
    class="detail d-none pl-0"
    id="details"
    (window:scroll)="doSomethingOnWindowScroll($event)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-12 m-auto pt-2 pt-lg-3 pt-lg-0">
          <div class="row">
            <div
              class="col-lg-2 col-3 pr-0 pt-2 pt-lg-0 px-0 px-md-3 img-person"
            >
              <img
                src="{{
                  profile.image
                    ? profile.image
                    : 'https://cdn.vivienda.com/images/icons/persona.png'
                }}"
                onerror="this.src  = 'https://cdn.vivienda.com/images/icons/persona.png'"
                class="h-100 mx-auto"
                alt="{{ profile.name }} | Vivienda.com"
                style="border: 2px solid #e2e2e2"
              />
            </div>
            <div class="col-lg-10 col-9 mt-lg-0 pl-sm-3 pr-0">
              <div class="row">
                <div class="col-12 pl-0 text-profile">
                  <h5 class="mb-0 title" style="color: #4c4b4b">
                    {{ profile.name }}
                  </h5>
                  <span
                    >{{
                      profile.description
                        ? profile.description.slice(0, 100).trim()
                        : ""
                    }}...</span
                  >
                </div>
                <div class="col-12 d-flex pl-1 calification">
                  <div class="row px-2 mb-0">
                    <div
                      class="col-12 d-flex flex-row px-1 mb-1"
                      style="font-size: 16px"
                      *ngIf="profile.reviews"
                    >
                      {{
                        profile.reviews.qualification
                          ? profile.reviews.qualification
                          : "0"
                      }}
                      <div class="ml-1" *ngFor="let num of [1, 2, 3, 4, 5]">
                        <i
                          *ngIf="
                            num <= profile.reviews.qualification;
                            else nostar
                          "
                          class="fa fa-star text-yellow fa-sm mt-n1 pointer"
                          onclick="$('#rate-modal').modal({backdrop: 'static', keyboard: false})"
                          (click)="selectRate(num)"
                        ></i>
                        <ng-template #nostar>
                          <i
                            class="far fa-star text-yellow fa-sm mt-n1 pointer"
                            onclick="$('#rate-modal').modal({backdrop: 'static', keyboard: false})"
                            (click)="selectRate(num)"
                          ></i>
                        </ng-template>
                      </div>
                      <span
                        class="text-gray1 text-regular ml-1"
                        style="font-size: 1rem"
                      >
                        |
                        {{
                          profile.reviews.count ? profile.reviews.count : "0"
                        }}
                        reseñas</span
                      >
                    </div>
                    <div class="col-12 d-flex flex-row pl-0 mb-1">
                      <h6
                        class="d-flex flex-row px-1 mb-1 text-gray1 text-regular"
                      >
                        <span
                          *ngFor="let c of profile.locations; index as i"
                          [class]="i == 0 ? 'd-block' : 'd-none d-md-block'"
                          >{{ c.city
                          }}{{
                            i + 1 < profile.locations.length ? "," : ""
                          }}</span
                        >
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="row">
            <div
              class="col-12 mb-lg-3 mt-lg-3 mb-2 mt-1 ml-4 ml-lg-0 pr-0 pl-4 pl-lg-0"
            >
              <div class="row justify-content-center">
                <div class="col-3 col-md-2">
                  <a
                    href="https://api.whatsapp.com/send/?phone=575791637383&text&app_absent=0"
                  >
                    <img
                      src="https://cdn.vivienda.com/images/icons/wp.png"
                      class="whatsapp-img"
                    />
                  </a>
                </div>
                <div class="col-6">
                  <a
                    class="d-block side-btn text-white bg-yellow text-center text-bold py-2 px-2 llamar"
                    style="height: fit-content; font-size: 14px; width: 100%"
                    href="tel:5791637383"
                  >
                    <img
                      src="https://cdn.vivienda.com/images/icons/call-white.png"
                      class="phone-img"
                    />
                    Llamar
                  </a>
                </div>
              </div>
            </div>
            <div
              class="col-md-12 d-flex icons-desktop pb-3"
              *ngIf="profile.social"
            >
              <span class="mx-auto" *ngFor="let s of profile.social">
                <a href="{{ s.value }}">
                  <img
                    src="{{ s.image }}"
                    alt="{{ s.name }} {{ profile.name }} | Vivienda.com"
                    style="max-width: 15px"
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="container mt-5">
    <div class="row">
      <div class="col-lg-6 px-0">
        <div class="left-side-profile p-3">
          <div
            class="d-flex justify-content-center align-items-center profile-img-cont mt-0 container-fluid mb-2"
            style="
              background: #fff;
              border-radius: 50%;
              overflow: hidden;
              border: 3px solid #e2e2e2;
            "
          >
            <img
              src="{{
                profile.image
                  ? profile.image
                  : 'https://cdn.vivienda.com/images/icons/persona.png'
              }}"
              onerror="this.src  = 'https://cdn.vivienda.com/images/icons/persona.png'"
              class="h-100 mx-auto"
              alt="{{ profile.name }} | Vivienda.com"
            />
          </div>
          <h5 class="text-bold text-white text-center">
            {{ profile.name }} <br class="d-block d-md-none h-0" />
            <!--<span style="font-size: 1rem;" class="text-bold text-gray1 mb-0">{{ profile.type }}</span>-->
          </h5>
          <h6
            class="text-white d-flex align-items-center justify-content-center gap-3 my-2"
            *ngIf="profile.reviews"
          >
            <div class="d-flex">
              <div class="ml-2" *ngFor="let num of [1, 2, 3, 4, 5]">
                <i
                  *ngIf="num <= profile.reviews.qualification; else nostar"
                  class="fa fa-star fa-sm mt-n1 cursor-pointer"
                  onclick="$('#rate-modal').modal({backdrop: 'static', keyboard: false})"
                  (click)="selectRate(num)"
                ></i>
                <ng-template #nostar>
                  <i
                    class="far fa-star fa-sm mt-n1 cursor-pointer"
                    onclick="$('#rate-modal').modal({backdrop: 'static', keyboard: false})"
                    (click)="selectRate(num)"
                  ></i>
                </ng-template>
              </div>
            </div>

            <span class="font-weight-normal font-italic">
              {{
                profile.reviews && profile.reviews.qualification
                  ? profile.reviews.qualification
                  : "0"
              }}
            </span>

            <span class="font-italic">
              {{ profile.reviews.count }} reseñas</span
            >
          </h6>

          <div
            class="d-flex justify-content-center align-items-center text-white mb-3"
          >
            <i class="fas fa-map-marker-alt mr-2"></i>
            <div class="mb-0 font-italic">
              <span *ngFor="let c of profile.locations; index as i"
                >{{ c.city }}{{ i + 1 < profile.locations.length ? ", " : ""
                }}{{ i + 1 < profile.locations.length ? "" : "." }}</span
              >
            </div>
          </div>

          <div class="text-center mb-3">
            <a
              onclick="$('#d-profile-info-request').toggleClass('d-none');$('#d-profile-info-form').toggleClass('d-none');"
              class="side-btn text-white btn-red btn-rounded text-center text-bold py-2 px-5 pointer"
              href="perfil/{{ slug }}#form-profile"
              >Solicitar información</a
            >
          </div>

          <div class="col-lg-10 px-0 ml-auto">
            <div class="list-group list-group-flush">
              <a
                href="/perfil/{{ slug }}#sobre-profile"
                class="pl-0 list-group-item bg-transparent border-white text-white"
                ><i class="fas fa-user iconleft"></i> Sobre
                {{ profile.name }}</a
              >
              <a
                href="/perfil/{{ slug }}#servicios-profile"
                class="pl-0 list-group-item bg-transparent border-white text-white"
                ><i class="fas fa-suitcase iconleft"></i> Servicios que presta
                {{ profile.name }}
              </a>
              <a
                href="/perfil/{{ slug }}#resenas-profile"
                class="pl-0 list-group-item bg-transparent border-white text-white"
                ><i class="fas fa-comment-alt iconleft"></i> Reseñas y
                comentarios</a
              >
              <a
                href="/perfil/{{ slug }}#imagenes-profile"
                class="pl-0 list-group-item bg-transparent border-white text-white"
                ><i class="fas fa-images iconleft"></i> Imágenes de
                {{ profile.name }}</a
              >
              <a
                href="/perfil/{{ slug }}#medios-profile"
                class="pl-0 list-group-item bg-transparent border-white text-white"
                ><i class="fas fa-phone iconleft"></i> Medios de contacto</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="mb-3 d-none mt-3 mt-lg-0" id="d-profile-info-form">
          <div class="ancla" id="form-profile"></div>
          <h6 class="border-bottom border-red py-2">
            <i class="fas fa-newspaper text-red mr-1"></i>
            Solicitar información a {{ profile.name }}
          </h6>
          <form
            #contactForm="ngForm"
            (ngSubmit)="onSubmit(contactForm)"
            class="text-center py-2"
            autocomplete="off"
            style="box-shadow: none"
          >
            <div class="pb-0 px-2 pt-0">
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Nombre(s)"
                class="px-3 w-100"
                [(ngModel)]="model.name"
                autocomplete="new-name"
                required
              />
              <input
                type="text"
                name="surname"
                id="surname"
                placeholder="Apellido(s)"
                class="px-3 w-100"
                [(ngModel)]="model.surname"
                autocomplete="new-surname"
                required
              />
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Correo electrónico"
                class="px-3 w-100"
                [(ngModel)]="model.email"
                autocomplete="new-email"
                required
              />
              <input
                type="text"
                inputmode="numeric"
                name="cellphone"
                id="cellphone"
                placeholder="Teléfono"
                class="px-3 w-100"
                [(ngModel)]="model.cellphone"
                required
              />
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="2"
                placeholder="Escríbele un mensaje al agente de tu solicitud"
                [(ngModel)]="model.message"
                required
              ></textarea>
              <div class="d-flex flex-row align-items-start">
                <input
                  type="checkbox"
                  name="terminos-perfil"
                  id="terminos-perfil"
                />
                <label
                  for="terminos-perfil"
                  style="width: 20px; height: 15px; display: block"
                  class="m-0 mt-1"
                ></label>
                <p
                  style="font-size: 0.5rem"
                  class="text-color-black text-medium mb-0 ml-2 text-left"
                >
                  Acepto los términos y condiciones, política de privacidad y el
                  tratamiento de mis datos personales
                </p>
              </div>
              <div class="form-group mb-1 text-center">
                <div class="g-recaptcha recaptcha-1 my-0">
                  <re-captcha
                    #captchaRef="reCaptcha"
                    siteKey="6LdkKNwUAAAAABGyZqGidUfLfPWk73AuG0u_ylMa"
                    class="g-recaptcha"
                    (resolved)="resolved($event)"
                    required
                  ></re-captcha>
                </div>
              </div>
              <button
                type="submit"
                class="text-bold text-white btn-red mx-auto py-2 mt-1"
                style="width: 100px; border-radius: 10px; border: 0"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
        <div class="mb-3 mt-3 mt-lg-0" *ngIf="!profile.isCompany" id="servicios">
          <div class="ancla" id="sobre-profile"></div>
          <h6 class="border-bottom border-red py-2">
            <i class="fas fa-user text-red mr-1"></i>
            Sobre {{ profile.name }}
          </h6>
          <p
            class="text-thin text-dark text-desc col-12 px-0 mb-2"
            *ngIf="profile.description"
            id="description-container"
          >
            {{ profile.description.slice(0, 300)
            }}{{ profile.description.length > 300 ? "..." : "" }}
            <a
              class="text-yellow text-bold pointer"
              *ngIf="profile.description && profile.description.length > 300"
              (click)="loadDescription()"
              id="load-description"
            >
              Ver más <i class="fa fa-chevron-right ml-2"></i>
            </a>
          </p>
          <ul>
            <li
              *ngFor="let w of profile.websites"
              [class]="w.value ? 'd-none' : ''"
            >
              <a
                href="{{
                  w
                    ? includes(w, 'http') || includes(w, 'https')
                      ? w
                      : 'http://' + w
                    : '#'
                }}"
                target="_blank"
                class="d-block text-red text-bold ml-0 mb-2 small lh-1"
                *ngIf="!w.value"
                >{{ w }}</a
              >
            </li>
          </ul>

          <div class="text-center" *ngIf="profile.social.length > 0">
            <p
              class="text-semibold text-color-black mb-0 text-right"
              *ngIf="profile.social !== undefined"
            ></p>
            <div
              class="d-flex flex-row justify-content-center align-items-center"
            >
              <a
                href="{{ s.value }}"
                target="_blank"
                class="d-block mx-1"
                *ngFor="let s of profile.social"
                title="{{ s.name }}"
              >
                <img
                  src="{{ s.image }}"
                  class="img-fluid"
                  style="max-width: 18px"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="ancla" id="servicios-profile"></div>
          <h6 class="border-bottom border-red py-2">
            <i class="fas fa-suitcase text-red mr-1"></i>
            Servicios que presta {{ profile.name }}
          </h6>

          <div class="row">
            <div class="col-lg-6 results-n" *ngFor="let s of profile.items">
              <div class="card border overflow-hidden rounded-3 mb-4">
                <a href="/servicio/{{ slug }}/{{ s.slug }}">
                  <img
                    class="card-img-top aspect-ratio-2"
                    src="{{
                      s.image
                        ? !includes(s.image, '?no-include')
                          ? s.image
                          : getFormatImage(
                              s.image.replace('?no-include', ''),
                              '1x1-300x170'
                            )
                        : 'https://cdn.vivienda.com/images/services/no-disponible.jpg'
                    }}"
                    onerror="this.src = 'https://cdn.vivienda.com/images/services/no-disponible.jpg'"
                    alt="{{ profile.name }} | Vivienda.com"
                  />
                </a>

                <div class="card-body p-3">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-auto"></div>
                    <div class="col-auto">
                      <div class="d-flex">
                        <ng-container
                          *ngFor="let serv of s.subservices; index as i"
                        >
                          <div
                            *ngIf="i < 2"
                            class="btn btn-sm btn-blue btn-rounded ml-2 text-capitalize text-truncate"
                          >
                            {{ serv.name.slice(0, 20).trim()
                            }}{{ serv.name.length > 20 ? "..." : "" }}
                          </div>
                        </ng-container>
                        <div
                          *ngIf="s.subservices == 0"
                          class="btn btn-sm btn-blue btn-rounded ml-2 text-capitalize text-truncate"
                        >
                          {{ s.name }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <h5 class="text-red mt-1 mt-lg-2" title="{{ s.name }}">
                    <a
                      href="/servicio/{{ slug }}/{{ s.slug }}"
                      style="color: inherit"
                      >Servicio de
                      <span class="text-lewer">
                        {{ s.name.slice(0, 20).trim()
                        }}{{ s.name.length > 20 ? "..." : "" }}</span
                      >
                    </a>
                  </h5>
                  <p
                    class="text-description-service text-thin mb-2 small text-truncate-3 mh-5"
                  >
                    {{ s.description.slice(0, 105).trim()
                    }}<a
                      href="/servicio/{{ slug }}/{{ s.slug }}"
                      *ngIf="s.description.length > 100"
                      >...</a
                    >
                  </p>

                  <h6
                    class="text-semibold text-thin text-truncate font-italic"
                    style="font-size: 15px"
                  >
                    <i class="fas fa-map-marker-alt mr-2"></i>

                    <span *ngFor="let l of s.locations; index as j"
                      >{{ l.city
                      }}{{ j + 1 < s.locations.length ? ", " : "" }}</span
                    >
                  </h6>

                  <a
                    class="btn btn-red w-100 btn-rounded mt-3 font-italic"
                    href="/servicio/{{ slug }}/{{ s.slug }}"
                  >
                    Ver servicio
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3" id="reseñas">
          <div class="ancla" id="resenas-profile"></div>
          <h6 class="border-bottom border-red py-2">
            <i class="fas fa-comment-alt text-red mr-1"></i>
            Reseñas y comentarios
          </h6>

          <div
            class="row px-3 py-2 d-flex align-items-center"
            *ngIf="profile.reviews.ratings.length == 0"
          >
            <div class="col-12">
              <h4
                class="text-bold text-color-black text-center"
                *ngIf="!profile.canEdit"
              >
                Aún no hay reseñas de este perfil. Sé el primero en escribir una
              </h4>
              <h4
                class="text-bold text-color-black text-center"
                *ngIf="profile.canEdit"
              >
                Aún no hay reseñas de este perfil. Invita a otros a calificarte
              </h4>
              <button
                data-toggle="modal"
                data-target="#rate-modal"
                class="califcation mx-auto d-block text-white btn-red text-center text-bold py-2 px-4 ml-2 border-0"
                style="height: fit-content; border-radius: 10px"
                *ngIf="!profile.canEdit"
              >
                <i class="fa fa-star mr-2"></i>Califícame
              </button>
            </div>
          </div>

          <h6 *ngIf="profile.reviews.ratings.length > 0" class="text-dark">
            Los clientes calificaron a este Aliado por su valor y puntualidad
          </h6>
          <div
            class="card p-3 bg-gray1 border-0 shadow-none rounded-3 mb-2"
            *ngFor="let res of profile.reviews.ratings; index as i"
          >
            <div class="row justify-content-between">
              <div class="col-lg-auto">
                <div class="text-gray1 mb-0">Comentario por</div>
                <div
                  class="img-cont d-flex flex-row align-items-center"
                >
                  <img
                    src="{{
                      res.user.image
                        ? res.user.image
                        : 'https://cdn.vivienda.com/images/icons/persona.png'
                    }}"
                    onerror="this.src = 'https://cdn.vivienda.com/images/icons/persona.png'"
                    class="review-profile rounded-circle mr-3"
                  />
                  <p class="text-dark mb-0 font-weight-bold">
                    {{ res.user.name }} {{ res.user.surname }}
                  </p>
                </div>
              </div>
              <div class="col-lg-auto">
                <div class="mb-0 text-gray1">
                  {{ res.created_at | date : "EEE d MMM, yyyy" }}
                </div>

                <div class="my-1 d-flex flex-row">
                    <div class="ml-1" *ngFor="let num of [1, 2, 3, 4, 5]">
                      <i
                        *ngIf="num <= res.score; else nostar"
                        class="fa fa-star text-yellow mt-n1"
                      ></i>
                      <ng-template #nostar
                        ><i class="far fa-star text-yellow mt-n1"></i>
                      </ng-template>
                    </div>
                  </div>
              </div>
            </div>
            <div
              class="text-dark pt-2"
              *ngIf="res.commentary && res.commentary.length > 120"
            >
              <p
                class="mb-0 p-review p-review-{{
                  i
                }}-limited"
              >
                {{ res.commentary.slice(0, 117) }}...
              </p>
              <p class="mb-0 p-review-{{ i }} d-none">
                {{ res.commentary }}
              </p>
              <p
                class="text-bold text-yellow mb-0 pointer"
                id="p-review-{{ i }}"
                onclick="
                                      $(this).hide();
                                      $('.' + $(this).attr('id')).removeClass('d-none');
                                      $('.' + $(this).attr('id') + '-limited').addClass('d-none');
                                      "
              >
                Ver más<i class="fa fa-chevron-right ml-2"></i>
              </p>
            </div>
            <div
              class="text-dark pt-2"
              *ngIf="res.commentary && res.commentary.length <= 120"
            >
              <p
                class="mb-0 p-review p-review-{{ i }}"
              >
                {{ res.commentary }}
              </p>
            </div>
          </div>
        </div>

        <div class="mb-3" id="images">
          <div class="ancla" id="imagenes-profile"></div>
          <h6 class="border-bottom border-red py-2">
            <i class="fas fa-images text-red mr-1"></i>
            Imágenes
          </h6>

          <carousel *ngIf="profile.images && profile.images.length > 1">
            <div
              class="carousel-cell"
              *ngFor="let image of profile.images; index as i"
            >
              <img src="{{ image.thumb }}" class="carousel-cell-image" />
              <div class="carousel-cell-overlay">
                <div class="carousel-cell-text">
                  <span>{{ image.name }}</span>
                  <a
                    class="carousel-cell-button side-btn text-white bg-yellow text-center text-bold py-1 px-2 mt-2 pointer"
                    (click)="openGalleryViewer(image)"
                  >
                    Ver fotos
                  </a>
                </div>
              </div>
            </div>
          </carousel>
          <div
            class="row mx-0"
            *ngIf="profile.images && profile.images.length == 1"
          >
            <div
              class="carousel-cell cursor col-4 col-md-4 pb-1 px-1"
              *ngFor="let image of profile.images[0].images; index as i"
              (click)="openImageViewer(profile.images[0], i)"
            >
              <img
                src="{{ image.thumb }}"
                class="carousel-cell-image pointer"
              />
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="ancla" id="medios-profile"></div>
          <h6 class="border-bottom border-red py-2">
            <i class="fas fa-phone text-red mr-1"></i>
            Medios de contacto
          </h6>
          <div class="text-center pt-3">
            <a
              onclick="$('#d-cellphones-links').toggleClass('d-none');$(this).toggleClass('d-none');"
              class="side-btn text-white btn-red text-center text-bold py-2 px-2 pointer"
            >
              Ver metodos de contacto
            </a>

            <h4 class="d-none" id="d-cellphones-links">
              <ul
                class="list-unstyled ml-3 d-flex gap-3 justify-content-center"
              >
                <li *ngFor="let phone of profile.cellphones" class="mb-2">
                  <i class="fas fa-phone fa-xs"></i>
                  <a class="text-red" href="tel:{{ phone }}">
                    {{ phone }}
                  </a>
                </li>
              </ul>
            </h4>
          </div>
        </div>

        <div class="mb-3" id="inmuebles" *ngIf="profile.isCompany">
          <div class="ancla" id="inmuebles-profile"></div>
          <h6 class="border-bottom border-red py-2">
            <i class="fas fa-building text-red mr-1"></i>
            Inmuebles
          </h6>

          <div class="pb-2" id="ventas">
            <div class="row">
              <div class="col-12 pt-2 pr-0">
                <div class="row" style="padding-right: 1.1rem">
                  <div
                    class="col-md-4 mt-2"
                    *ngFor="let p of profile.items.published"
                  >
                    <img
                      src="{{
                        p.image
                          ? !includes(p.image, '?no-include')
                            ? p.image
                            : getFormatImage(
                                p.image.replace('?no-include', ''),
                                '16x9-300x170'
                              )
                          : 'https://cdn.vivienda.com/images/properties/no-disponible.jpg'
                      }}"
                      onerror="this.src = 'https://cdn.vivienda.com/images/properties/no-disponible.jpg'"
                      class="w-100"
                      alt="{{ profile.name }} | Vivienda.com"
                    />
                    <p
                      class="text-regular text-gray text-left mb-1 p-subimg d-flex align-items-center"
                    >
                      <img
                        src="https://cdn.vivienda.com/images/icons/clock.png"
                        width="12px"
                      />{{ p.created }}
                    </p>
                    <p class="text-medium text-gray text-left mb-2">
                      {{ p.zone }}, {{ p.city }}
                    </p>
                    <p class="text-medium text-gray text-left p-90 mb-0">
                      {{ p.type_offer | titlecase }}
                    </p>
                    <h5 class="text-medium text-gray text-left mb-2">
                      ${{ p.amount }}
                    </h5>
                    <div class="row px-2 mt-2">
                      <div class="col-12 px-2">
                        <a
                          href="/propiedad/{{ p.slug }}"
                          class="d-block bg-yellow text-white w-100 py-1 text-bold text-center p-90 mt-2"
                        >
                          <img
                            src="https://cdn.vivienda.com/images/icons/eye-white.png"
                            class="mr-2"
                            width="16px"
                          />Vista previa
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 px-0 mt-3">
                    <a
                      (click)="more = !more"
                      *ngIf="profile.propertiescount > 4"
                      class="d-block text-white bg-yellow text-center mb-3 py-2 text-bold ml-3 mt-2"
                      style="width: 150px"
                      >Ver {{ more ? "menos" : "más" }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3" id="ventas" *ngIf="profile.isCompany">
          <div class="ancla" id="inmuebles-profile"></div>
          <h6 class="border-bottom border-red py-2">
            <i class="fas fa-building text-red mr-1"></i>
            Ventas cerradas
          </h6>

          <div class="row">
            <div class="col-12 pt-3">
              <div class="row">
                <div class="col-md-4" *ngFor="let p of profile.items.closed">
                  <div class="w-100 position-relative">
                    <img
                      src="https://cdn.vivienda.com/images/icons/sold.png"
                      width="90px"
                      style="position: absolute; top: 0; left: 0"
                    />
                    <img
                      src="{{
                        p.image
                          ? !includes(p.image, '?no-include')
                            ? p.image
                            : getFormatImage(
                                p.image.replace('?no-include', ''),
                                '16x9-300x170'
                              )
                          : 'https://cdn.vivienda.com/images/properties/no-disponible.jpg'
                      }}"
                      onerror="this.src = 'https://cdn.vivienda.com/images/properties/no-disponible.jpg'"
                      class="w-100"
                      alt="{{ profile.name }} | Vivienda.com"
                    />
                  </div>
                  <p
                    class="text-regular text-gray text-left mb-1 p-subimg d-flex align-items-center"
                  >
                    <img
                      src="https://cdn.vivienda.com/images/icons/clock.png"
                      width="12px"
                    />{{ p.created }}
                  </p>
                  <p class="text-medium text-gray text-left mb-2">
                    {{ p.zone }}, {{ p.city }}
                  </p>
                  <p class="text-medium text-gray text-left p-90 mb-0">
                    {{ p.offer }}
                  </p>
                  <h5 class="text-medium text-gray text-left mb-2">
                    ${{ p.amount }}
                  </h5>
                  <div class="row px-2 mt-2">
                    <div class="col-12 px-2">
                      <a
                        href="/propiedad/{{ p.slug }}"
                        class="d-block bg-yellow text-white w-100 py-1 text-bold text-center p-90 mt-4"
                      >
                        <img
                          src="https://cdn.vivienda.com/images/icons/eye-white.png"
                          class="mr-2"
                          width="16px"
                        />Vista previa
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="my-5 pb-5 position-relative">
          <a class="d-block w-100 mx-auto">
            <img
              class="w-100"
              src="https://cdn.vivienda.com/pauta/home-desktop.jpg"
            />
          </a>
        </div>
      </div>
    </div>

    <ngx-image-gallery [images]="images" [conf]="conf"></ngx-image-gallery>
  </div>

  <div
    class="carousel-cell-container d-none"
    onclick="$(this).toggleClass('d-none')"
  >
    <div class="carousel-cell-galleria px-4 px-md-4 mt-2 mt-md-5">
      <div class="row justify-content-center">
        <div
          class="col-6 col-md-3 col-xl-2 justify-content-center text-center px-1 my-1"
          *ngFor="let image of imageGroup.images; index as i"
        >
          <img
            src="{{ image.thumb }}"
            class="img-fluid"
            (click)="openImageViewer(imageGroup, i)"
          />
        </div>
      </div>
    </div>
  </div>

  <!--Modals-->
  <div
    class="modal fade"
    id="rate-modal"
    data-backdrop="static"
    tabindex="-1"
    aria-hidden="true"
    *ngIf="!profile.canEdit"
  >
    <div class="modal-dialog" style="margin-top: 5px">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                (click)="resetModal()"
              >
                <i class="fa fa-times fa-lg text-yellow"></i>
              </button>
            </div>
          </div>
          <h3 class="text-center text-color-black text-black">
            Escribe una reseña
          </h3>
          <p class="text-center text-medium text-gray text-left mb-1">
            ¿Qué probabilidad hay de que recomiendes a {{ profile.name }}?
          </p>
          <form class="row" #formRate="ngForm">
            <div
              class="col-12 d-flex flex-row mb-0 justify-content-center"
              style="font-size: 1.4rem"
            >
              <button
                type="button"
                (click)="selectRate(i + 1)"
                class="mx-1 bg-transparent border-0 d-block text-yellow rate-link rate-{{
                  i + 1
                }}"
                (mouseover)="rateOver(i)"
                (mouseout)="removeRateOver()"
                *ngFor="let i of [0, 1, 2, 3, 4]"
              >
                <i class="far fa-star"></i>
              </button>
            </div>
            <div class="col-12 d-flex flex-column">
              <textarea
                class="mt-3 w-75 mx-auto text-gray text-thin px-3 py-2"
                ngModel
                name="rate-comment"
                id="rate-comment"
                placeholder="Escribe tu reseña"
                rows="4"
              ></textarea>
              <div class="g-recaptcha recaptcha-1 mt-3 mb-0 mx-auto">
                <re-captcha
                  #captchaRef="reCaptcha"
                  siteKey="6LdkKNwUAAAAABGyZqGidUfLfPWk73AuG0u_ylMa"
                  class="g-recaptcha"
                  (resolved)="resolved($event)"
                  required
                ></re-captcha>
              </div>
              <button
                type="button"
                class="text-bold text-white bg-yellow mx-auto py-2 mt-1"
                style="width: 100px; border-radius: 10px; border: 0"
                (click)="saveRate(formRate)"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="edit-profile-modal"
    data-backdrop="static"
    tabindex="-1"
    aria-hidden="true"
    *ngIf="profile.canEdit"
  >
    <div class="modal-dialog modal-lg" style="margin-top: 5px">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                (click)="resetModal()"
              >
                <i class="fa fa-times fa-lg text-yellow"></i>
              </button>
            </div>
          </div>
          <div class="col-12 px-3">
            <app-user-admin-info
              [profile]="profile"
              [isProfile]="true"
              [slug]="slug"
              [social]="social"
              [action]="'reload'"
            ></app-user-admin-info>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
